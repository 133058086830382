import useAxios from 'axios-hooks';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { configRequest } from '../services/config.service';
import ConfigForm from './Config/ConfigForm';
import { Config as IConfig } from '../models/config.model';
import { Loading } from 'src/common';

const Config: FunctionComponent = () => {
  const { code } = useParams<{ code: string }>();

  const [{ data, loading }] = useAxios<IConfig>(configRequest(code), {
    useCache: false,
  });

  if (loading) return <Loading />;

  if (!data) throw new Error('');

  return <ConfigForm code={code} initial={data} />;
};

export default Config;
