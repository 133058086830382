export function babyCareNames(key: 'X' | 'I' | 'Y' | 'N' | undefined): string {
  if (key === undefined) return '';
  else
    return {
      X: 'Exclude if possible',
      I: 'Include if possible',
      Y: 'Include',
      N: 'Exclude',
    }[key];
}
