export function traumaReinstatementNames(
  key: 'X' | 'Y' | 'N' | undefined
): string {
  if (key === undefined) return '';
  else
    return {
      X: 'Exclude if possible',
      Y: 'Include',
      N: 'Exclude',
    }[key];
}
