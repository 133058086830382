import { v4 as uuid } from 'uuid';

export interface Application {
  pages: Page[];
}
export const createApplication = (): Application => ({
  pages: [createPage()],
});

export interface Page {
  id: string;
  name: string;
  tags: Tag[];
}
export const createPage = (): Page => ({
  id: uuid(),
  name: '',
  tags: [],
});

export interface Tag {
  type: TagType;
  id: string;
  label: string;
  value: string;
  options: string[];
  visibilityId?: string;
  visibilityValue?: string;
  linkedFieldName?: LinkedFieldName | '';
}
export const createTag = (): Tag => ({
  type: 'TextInput',
  id: uuid(),
  label: '',
  value: '',
  options: [],
});

export type TagType = 'Text' | 'TextInput' | 'Select' | 'Number' | 'Date';
export const tagTypes = ['Text', 'TextInput', 'Select', 'Number', 'Date'];

export type LinkedFieldName =
  | 'salutation'
  | 'firstName'
  | 'lastName'
  | 'dateOfBirth'
  | 'gender'
  | 'smoker'
  | 'occupationName'
  | 'email'
  | 'phone'
  | 'state'
  | 'insurer';
export const linkedFieldNames: LinkedFieldName[] = [
  'salutation',
  'firstName',
  'lastName',
  'dateOfBirth',
  'gender',
  'smoker',
  'occupationName',
  'email',
  'phone',
  'state',
  'insurer',
];
