import { BUS } from './needs/bus.model';
import { CHT } from './needs/cht.model';
import { INC } from './needs/inc.model';
import { NES } from './needs/nes.model';
import { TPD } from './needs/tpd.model';
import { TRM } from './needs/trm.model';
import { TRS } from './needs/trs.model';

export interface QuoteResponse {
  requestId: string;
  clients: Client[];
}

export interface Client {
  quoteIdentifiers: string[];
  portfolios: Portfolio[];
  clientId: string;
}

export interface Portfolio {
  code: string;
  name: string;
  supplier: Supplier;
  products: Product[];
  occupation: Occupation;
  policyFeeTotal: PolicyFeeTotal;
  premiumTotal: PremiumTotal;
  score: Score;
  errors: string[];
  supportsFrequency: SupportsFrequency;
  allNeedsMet: boolean;
  links: Links;
  underwriting: Underwriting;
  topFeatures: Feature[];
  bottomFeatures: Feature[];
  premiumDiscount: Period;
  loadings: Loadings;
  campaign?: string;
}

export interface Feature {
  headingName: string;
  summaryText: string;
}

export interface Links {
  pds: string;
  tmd: string;
  omniPortal: boolean;
  applicationForm: boolean;
  validation: boolean;
  report: boolean;
  manualPortfolio: string;
  omniPortalMessage?: string;
  validationMessage?: string;
  reportMessage?: string;
  applicationFormMessage?: string;
}

export interface Loadings {
  percentage?: DollarsPerThousand;
  dollarsPerThousand?: DollarsPerThousand;
}

export interface DollarsPerThousand {
  TRM: number;
  TPD: number;
  TRA: number;
  INC: number;
  BUS: number;
}

export interface Occupation {
  supplierCode: string;
  id: string;
  description: string;
  classTRM: string;
  classTPDAny: string;
  classTPDOwn: string;
  classTPDADL: string;
  classTRA: string;
  classINC: string;
  classBUS: string;
  supportsTRM: boolean;
  supportsTPDAny: boolean;
  supportsTPDOwn: boolean;
  supportsTPDADL: boolean;
  supportsTRA: boolean;
  supportsINC: boolean;
  supportsBUS: boolean;
  supportsNES: boolean;
  incomeMin: string;
  incomeMax: string;
}

export interface OccupationFilter {
  id: string;
  description: string;
}

export interface PolicyFeeTotal {
  premium: Period;
  premiumOutsideSuper: Period;
  premiumInsideSuper: Period;
  stampDuty: Period;
  stampDutyInsideSuper: Period;
  stampDutyOutsideSuper: Period;
  commissionUpfront: Period;
  commissionOngoing: Period;
  rolloverRebate: Period;
  description: string;
  code?: string;
  breakdown?: PolicyFeeTotal[];
}

export interface Period {
  Y: number;
  H: number;
  Q: number;
  M: number;
  F: number;
  W: number;
}

export interface PremiumTotal {
  premium: Period;
  minimum: Period;
  premiumOutsideSuper: Period;
  premiumInsideSuper: Period;
  stampDuty: Period;
  stampDutyInsideSuper: Period;
  stampDutyOutsideSuper: Period;
  commissionUpfront: Period;
  commissionOngoing: Period;
  commissionUpfrontPercent: Period;
  commissionOngoingPercent: Period;
  rolloverRebate: Period;
  description: string;
  breakdown: PolicyFeeTotal[];
}

export interface Product {
  code: string;
  name: string;
  pdsName: string;
  score: Score;
  coveredNeeds: string[];
  resolvedNeeds: ResolvedNeed[];
}

export interface ResolvedNeed {
  TRM?: TRM;
  TPS?: TPD;
  TRS?: TRS;
  INC?: INC;
  BUS?: BUS;
  NES?: NES;
  CHT?: CHT;
}

export interface Score {
  feature: Combined;
  combined: Combined;
  featurePerNeed: PerNeed;
  combinedPerNeed: PerNeed;
}

export interface Combined {
  '5': string;
  '15': string;
  raw: number;
  star: string;
}

export interface PerNeed {
  TRM?: Combined;
}

export interface Supplier {
  code: string;
  name: string;
  logo: string;
}

export interface SupportsFrequency {
  Y: boolean;
  H: boolean;
  Q: boolean;
  M: boolean;
  F: boolean;
  W: boolean;
}

export interface Underwriting {
  limits: Limits;
  requirements: string[];
  supportsUnderwriting: boolean;
}

export interface Limits {
  TRM?: number;
}

export type Frequency = 'W' | 'F' | 'M' | 'Q' | 'H' | 'Y';

export const FrequencyName: { [Key in Frequency]: string } = {
  W: 'Weekly',
  F: 'Fortnightly',
  M: 'Monthly',
  Q: 'Quarterly',
  H: 'Half Yearly',
  Y: 'Yearly',
};

export const frequencies: Frequency[] = ['W', 'F', 'M', 'Q', 'H', 'Y'];
