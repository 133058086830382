import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { atom, useRecoilState } from 'recoil';
import { CustomSelect } from 'src/common/CustomSelect';
import InfoTooltip from 'src/common/InfoTooltip';
import { useConfigContext } from 'src/configs/config.context';
import {
  frequencies,
  Frequency,
  FrequencyName,
} from 'src/models/quote-response.model';

const frequencyState = atom<Frequency>({
  key: 'frequenciesState',
  default: 'M',
});

export const useFrequencyState = () => useRecoilState(frequencyState);

export const FrequencySelector: FunctionComponent<{}> = () => {
  const config = useConfigContext();
  const [freq, setFreq] = useFrequencyState();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
    >
      <CustomSelect
        value={freq}
        onChange={setFreq}
        options={frequencies
          .filter((f) => config.display.frequencies?.includes(f))
          .map((f) => ({
            value: f,
            label: FrequencyName[f] + ' Premium',
          }))}
      />

      <InfoTooltip title="Score">
        The features score is based upon independent research conducted by
        Omnium across the Australian life insurance market. This research covers
        both essential benefits as well as additional extra benefits and
        options.
        <br /> <br />
        The combined score averages the feature score and price where the
        cheapest insurer is given the best price score.
      </InfoTooltip>
    </Box>
  );
};
