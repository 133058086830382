import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoint = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const sm = useMediaQuery(theme.breakpoints.down('md'));
  const md = useMediaQuery(theme.breakpoints.up('sm'));

  return { xs, sm, md };
};
