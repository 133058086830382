import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { createApplication } from '../types/application.type';
import { axiosAdmin } from 'src/configs/axios.config';
import { getApplication } from '../api/getApplication';
import { useRecoilState } from 'recoil';
import { adminCredsState } from 'src/models/admin/credentials.model';
import { AxiosError } from 'axios';
import { PageList } from './PageList';
import { updateApplication } from '../api/updateApplication';
import { toast } from 'react-hot-toast';
import { Loading } from 'src/common';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

export const ApplicationEdit: FunctionComponent<{}> = () => {
  const [creds] = useRecoilState(adminCredsState);
  const { code } = useParams<{ code: string }>();
  const history = useHistory();

  const [application, setApplication] = useState(createApplication());
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const fetchApplication = useCallback(() => {
    setLoading(true);
    axiosAdmin(getApplication(creds, code))
      .then((d) => {
        setApplication(d.data);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) setApplication(createApplication());
        setLoading(false);
      });
  }, [creds, code]);

  useEffect(() => {
    fetchApplication();
  }, [fetchApplication]);

  const saveApplication = () => {
    setSaving(true);
    axiosAdmin(updateApplication(creds, code, application)).then(() => {
      setSaving(false);
      toast.success('Apply Form Changes Saved');
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Breadcrumbs>
          <Link
            underline="hover"
            color="inherit"
            onClick={() => history.push('/configs')}
          >
            Configs
          </Link>
          <Link
            underline="hover"
            color="inherit"
            onClick={() => history.push('/config/' + code)}
          >
            {code}
          </Link>
          <Typography color="text.pirmary">Apply Form</Typography>
        </Breadcrumbs>

        <LoadingButton
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          size="small"
          onClick={saveApplication}
          loading={saving}
          loadingPosition="start"
        >
          Save
        </LoadingButton>
      </Box>

      <PageList application={application} setApplication={setApplication} />
    </>
  );
};
