import _ from 'lodash';
import { TRMConfig, TPEConfig, TREConfig, Config } from './../config.model';
import { TPD } from './tpd.model';
import { TRS } from './trs.model';

// Life
export interface TRM {
  sumInsured: number;
  owner: 'O' | 'M' | 'S' | undefined;
  rollover: 'N' | 'I' | undefined;
  structure: 'S' | 'L' | '70' | undefined;
  premiumWaiver: 'N' | 'I' | 'Y' | undefined;
  linkedNeeds: ({ TPE: TPE } | { TRE: TRE })[];
  TPE?: TPE;
  TRE?: TRE;
}

// TPD Extension
export interface TPE extends TPD {
  lifeBuyBack: 'L' | 'F' | 'N' | '0' | '1' | undefined;
  doubleTPD: 'X' | 'Y' | 'N';
}

/** Trauma Extension */
export interface TRE extends Omit<TRS, 'linkedNeeds'> {
  lifeBuyBack: 'L' | 'F' | 'N' | '1' | '3';
  doubleTrauma: 'X' | 'Y' | 'N';
}

export function getTRM(config: Config): TRM {
  return {
    sumInsured: config.quotes.TRM.sumInsured.default,
    owner: config.quotes.TRM.owner.default,
    rollover: config.quotes.TRM.rollover.default,
    structure: config.quotes.TRM.structure.default,
    premiumWaiver: config.quotes.TRM.premiumWaiver.default,
    linkedNeeds: [],
    TPE: config.covers.complexMode
      ? getTPEFromConfig(config.quotes.TPE)
      : undefined,
    TRE: config.covers.complexMode
      ? getTREFromConfig(config.quotes.TRE)
      : undefined,
  };
}

export function getTPE(TPD: TPD, config: TPEConfig): TPE {
  return {
    ...TPD,
    lifeBuyBack: config.lifeBuyBack.default,
    doubleTPD: config.doubleTPD.default,
  };
}
function getTPEFromConfig(config: TPEConfig): TPE {
  return {
    sumInsured: config.sumInsured.default,
    owner: config.owner.default,
    rollover: config.rollover.default,
    structure: config.structure.default,
    occupationType: config.occupationType.default,
    premiumWaiver: config.premiumWaiver.default,
    lifeBuyBack: config.lifeBuyBack.default,
    doubleTPD: config.doubleTPD.default,
  };
}

export function getTRE(TRS: TRS, config: TREConfig): TRE {
  return {
    ..._.omit(TRS, 'linkedNeeds'),
    lifeBuyBack: config.lifeBuyBack.default,
    doubleTrauma: config.doubleTrauma.default,
  };
}
function getTREFromConfig(config: TREConfig): TRE {
  return {
    sumInsured: config.sumInsured.default,
    structure: config.structure.default,
    traumaReinstatement: config.traumaReinstatement.default,
    premiumWaiver: config.premiumWaiver.default,
    babyCare: config.babyCare.default,
    priority: config.priority.default,
    lifeBuyBack: config.lifeBuyBack.default,
    doubleTrauma: config.doubleTrauma.default,
  };
}

export function TRMValid(trm: TRM, config: TRMConfig) {
  return (
    trm.sumInsured > 0 &&
    _.toPairs(trm)
      .filter(([k]) => k !== 'TPE' && k !== 'TRE')
      .every(
        ([k, v]) =>
          _.get(config, k + '.visibility') === 'hidden' || v !== undefined
      )
  );
}
