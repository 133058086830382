import { FunctionComponent } from 'react';
import { CustomCard } from 'src/common';
import AmountConfigInput from '../../../common/AmountConfigInput';
import ChoiceConfigInput from '../../../common/ChoiceConfigInput';
import FormRow from '../../../common/FormRow';

const NESConfigForm: FunctionComponent = () => (
  <CustomCard title="NES">
    <FormRow
      label={<label>Amount:</label>}
      input={<AmountConfigInput name="quotes.NES.sumInsured" required={true} />}
    />

    <FormRow
      label={<label>Structure:</label>}
      input={
        <ChoiceConfigInput name="quotes.NES.structure" choices={['S', 'L']} />
      }
    />
  </CustomCard>
);

export default NESConfigForm;
