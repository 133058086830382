import { AxiosRequestConfig } from 'axios';
import { QuoteRequest } from '../models/quote-request.model';

export interface Occupation {
  id: string;
  description: string;
}

export function occupationsRequest(
  searchText: string,
  code: string | null
): AxiosRequestConfig {
  return {
    url: '/omnilife/occupations',
    params: { searchText: searchText, code: code },
  };
}

export function quotesRequest(
  quoteRequest: QuoteRequest,
  code: string | null
): AxiosRequestConfig {
  return {
    method: 'POST',
    url: '/omnilife/quotes',
    params: { code: code },
    data: quoteRequest,
  };
}

export function suppliersRequest(code: string | null): AxiosRequestConfig {
  return {
    method: 'POST',
    url: '/omnilife/suppliers',
    params: { code: code },
  };
}
