import YourCover from './YourCover/YourCover';
import { useState } from 'react';
import {
  SelectedCoverTypes,
  getSelectedCoverTypes,
  anySelected,
} from '../models/selected-cover-types.model';
import YourOptions from './YourOptions/YourOptions';
import YourDetails from './YourDetails/YourDetails';
import { getNeedsDict, NeedDict, valid } from '../models/needs/need.model';
import {
  ClientInput,
  clientInputValid,
  getClient,
} from '../models/client-input.model';
import { v4 as uuid } from 'uuid';
import { useConfigContext } from '../configs/config.context';
import useStepState from './stepState';
import { FormProvider, useForm } from 'react-hook-form';
import { LiveQuotes } from './LiveQuotes/LiveQuotes';
import {
  Box,
  Divider,
  Grid,
  Step,
  StepButton,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const Steps: React.FunctionComponent = () => {
  // ui
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const [step, setStep] = useStepState();

  // data
  const config = useConfigContext();
  const coverTypesForm = useForm<SelectedCoverTypes>({
    defaultValues: getSelectedCoverTypes(),
    mode: 'onChange',
    resolver: (values) => {
      if (!anySelected(values))
        return {
          values: {},
          errors: {
            TRM: {
              type: 'whole',
              message: 'at least one must be selected',
            },
          },
        };
      else return { values: values, errors: {} };
    },
  });

  const needsForm = useForm<NeedDict>({
    defaultValues: getNeedsDict(config),
    mode: 'onChange',
  });

  const [client, setClient] = useState(getClient(config.yourDetails));
  function setClientUpdateId(callback: (_: ClientInput) => ClientInput) {
    setClient((ov) => ({ ...callback(ov), clientId: uuid() }));
  }

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={11}
        sm={10}
        md={9}
        lg={8}
        xl={7}
        display="flex"
        justifyContent="center"
      >
        <Box maxWidth={850} minWidth={!xs ? 830 : undefined} width="100%">
          <Stepper
            nonLinear
            activeStep={step}
            orientation={xs ? 'vertical' : 'horizontal'}
            sx={{
              width: xs ? '120px' : undefined,
              mx: xs ? 'auto' : undefined,
              mt: xs ? -1 : undefined,
            }}
            connector={
              xs ? (
                <Box
                  sx={{
                    marginLeft: '12px',
                    height: '10px',
                    borderLeft: '1px solid #bdbdbd',
                  }}
                />
              ) : undefined
            }
          >
            <Step completed={step > 0}>
              <StepButton color="inherit" onClick={() => setStep(0)}>
                Your Cover
              </StepButton>
            </Step>

            <Step completed={step > 1}>
              <StepButton
                color="inherit"
                onClick={() => setStep(1)}
                disabled={!coverTypesForm.formState.isValid}
              >
                Your Options
              </StepButton>
            </Step>

            <Step completed={step > 2}>
              <StepButton
                color="inherit"
                onClick={() => setStep(2)}
                disabled={
                  !coverTypesForm.formState.isValid ||
                  !valid(
                    coverTypesForm.watch(),
                    needsForm.watch() as NeedDict,
                    config
                  )
                }
              >
                Your Details
              </StepButton>
            </Step>

            <Step>
              <StepButton
                color="inherit"
                onClick={() => setStep(3)}
                disabled={
                  !coverTypesForm.formState.isValid ||
                  !valid(
                    coverTypesForm.watch(),
                    needsForm.watch() as NeedDict,
                    config
                  ) ||
                  !clientInputValid(client, config)
                }
              >
                Your Results
              </StepButton>
            </Step>
          </Stepper>

          <Divider sx={{ mt: { xs: 1, sm: 3 }, mb: 3 }} />

          {/* Content */}
          {step === 0 && <YourCover coverTypesForm={coverTypesForm} />}

          {step === 1 && (
            <FormProvider {...needsForm}>
              <YourOptions
                client={client}
                setClient={setClientUpdateId}
                coverTypes={coverTypesForm.getValues()}
                incomeProtectionMonthlyCover={
                  needsForm.getValues().INC.monthlyBenefit
                }
              />
            </FormProvider>
          )}

          {step === 2 && (
            <YourDetails
              client={client}
              setClient={setClientUpdateId}
              incomeProtectionSelected={coverTypesForm.getValues().INC}
              incomeProtectionMonthlyCover={
                needsForm.getValues().INC.monthlyBenefit
              }
            />
          )}

          {step === 3 && (
            <LiveQuotes
              {...{
                coverTypes: coverTypesForm.getValues(),
                needs: needsForm.getValues() as NeedDict,
                client,
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Steps;
