import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import BUSConfigForm from './BUSConfigForm';
import CHTConfigForm from './CHTConfigForm';
import INCConfigForm from './INCConfigForm';
import NESConfigForm from './NESConfigForm';
import TPDConfigForm from './TPDConfigForm';
import TRMConfigForm from './TRMConfigForm';
import TRSConfigForm from './TRSConfigForm';

const CoverOptions: FunctionComponent = () => {
  const [tab, setTab] = useState('0');

  return (
    <TabContext value={tab}>
      <Box display="flex">
        <TabList onChange={(_e, v) => setTab(v)} orientation="vertical">
          <Tab label="TRM" value="0" />
          <Tab label="TPS" value="1" />
          <Tab label="TRS" value="2" />
          <Tab label="INC" value="3" />
          <Tab label="BUS" value="4" />
          <Tab label="NES" value="5" />
          <Tab label="CHT" value="6" />
        </TabList>

        <Box>
          <TabPanel value="0">
            <TRMConfigForm />
          </TabPanel>
          <TabPanel value="1">
            <TPDConfigForm />
          </TabPanel>
          <TabPanel value="2">
            <TRSConfigForm />
          </TabPanel>
          <TabPanel value="3">
            <INCConfigForm />
          </TabPanel>
          <TabPanel value="4">
            <BUSConfigForm />
          </TabPanel>
          <TabPanel value="5">
            <NESConfigForm />
          </TabPanel>
          <TabPanel value="6">
            <CHTConfigForm />
          </TabPanel>
        </Box>
      </Box>
    </TabContext>
  );
};

export default CoverOptions;
