import {
  validEmail,
  validName,
  validPhoneNumber,
  valid,
} from './../utils/validators';
import { Salutation } from './dictionaries/salutation.model';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Moment } from 'moment';
import { Config, YourDetailsConfig } from './config.model';

export interface ClientInput {
  clientId: string;
  salutation?: Salutation;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Moment;
  gender?: 'M' | 'F';
  smoker?: boolean;
  employmentStatus?: string;
  occupationId?: string;
  annualIncome?: number;
  occupationName?: string;
  email?: string;
  phone?: string;
  state: 'ACT' | 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA';
  postCode?: string;
  agreedToCustomerConsent: boolean;
  agreedToPDSandTMD: boolean;
  freeTextField?: string;
}

export function getClient(config: YourDetailsConfig | undefined): ClientInput {
  if (config?.useTestData)
    return {
      clientId: uuid(),
      salutation: config?.defaultSalutation,
      firstName: 'John',
      lastName: 'Galt',
      dateOfBirth: moment.utc('1985-11-23'),
      gender: 'M',
      smoker: false,
      occupationId: config?.defaultOccupationId || '231111',
      occupationName: config?.defaultOccupationName || 'Medical Practitioner',
      annualIncome: 0,
      email:
        config?.emailVisible !== 'hidden' ? 'JohnGalt@gmail.com' : undefined,
      phone: config?.phoneVisible !== 'hidden' ? '123123123123' : undefined,
      state: 'NSW',
      agreedToCustomerConsent: true,
      agreedToPDSandTMD: true,
      employmentStatus: config?.defaultEmploymentStatus,
    };
  else
    return {
      clientId: uuid(),
      salutation: config?.defaultSalutation,
      state: 'NSW',
      agreedToCustomerConsent:
        !config || config.customerConsentVisible !== 'visible',
      agreedToPDSandTMD: !config || config.PDSAndTMDVisible !== 'visible',
      occupationId: config?.defaultOccupationId,
      occupationName: config?.defaultOccupationName,
      annualIncome: 0,
      employmentStatus: config?.defaultEmploymentStatus,
    };
}

export function clientInputValid(client: ClientInput, config: Config): boolean {
  return (
    validName(client.firstName) &&
    validName(client.lastName) &&
    (config.yourDetails?.salutationRequired !== 'required' ||
      config.yourDetails?.salutationVisible !== 'visible' ||
      valid(client.salutation)) &&
    (config.yourDetails?.phoneVisible === 'hidden' ||
      validPhoneNumber(client.phone)) &&
    (config.yourDetails?.emailVisible === 'hidden' ||
      validEmail(client.email)) &&
    client.agreedToCustomerConsent &&
    client.agreedToPDSandTMD &&
    // Employment Status
    (config.yourDetails?.employmentStatusVisible !== 'visible' ||
      config.yourDetails?.employmentStatusRequired !== 'required' ||
      (client.employmentStatus || '').length > 0) &&
    // Post Code
    (config.yourDetails?.postCodeVisible !== 'visible' ||
      config.yourDetails?.postCodeRequired !== 'required' ||
      (client.postCode || '').length > 0) &&
    // Free Text Field
    (config.yourDetails?.freeTextFieldVisible !== 'visible' ||
      config.yourDetails?.freeTextFieldRequired !== 'required' ||
      (client.freeTextField || '').length > 0) &&
    // Other Fields
    [
      client.dateOfBirth,
      client.gender,
      client.smoker,
      client.occupationId,
    ].every((v) => v !== undefined)
  );
}
