import { Autocomplete, Box, TextField } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { FunctionComponent, useState } from 'react';
import { CustomSelect } from 'src/common/CustomSelect';
import { ClientInput } from '../../models/client-input.model';

interface CustomDatePickerProps {
  client: ClientInput;
  setClient: (callback: (value: ClientInput) => ClientInput) => void;
}

const CustomDatePicker: FunctionComponent<CustomDatePickerProps> = ({
  client,
  setClient,
}) => {
  const [year, setYear] = useState(
    client.dateOfBirth?.year()?.toString() || ''
  );

  const [month, setMonth] = useState(client.dateOfBirth?.month() || 0);
  const [day, setDay] = useState(client.dateOfBirth?.date()?.toString() || '');

  const years = _.range(moment().year(), 1920).map(String);
  const days = _.range(
    1,
    moment({
      year: +year || undefined,
      month: month || undefined,
    }).daysInMonth() + 1
  ).map(String);

  const setBirthDate = (y: string, m: number | null, d: string) => {
    if (y && m !== null && d)
      setClient((c) => ({
        ...c,
        dateOfBirth: moment.utc({ year: +y, month: m, day: +d }),
      }));
    else
      setClient((c) => ({
        ...c,
        dateOfBirth: undefined,
      }));
  };

  return (
    <Box display="flex" gap={1}>
      <Autocomplete
        value={year}
        onChange={(_, y) => {
          setYear(y);
          setDay('');
          setBirthDate(y, month, '');
        }}
        options={years}
        autoHighlight
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: 100 }}
            error={!year}
            label="Year"
          />
        )}
        disableClearable
        getOptionLabel={(v) => v?.toString() || ''}
        isOptionEqualToValue={(o, v) => o === v}
      />

      <CustomSelect
        disabled={year === null}
        error={month === null}
        label="Month"
        value={month}
        onChange={(v) => {
          const m = +(v || 0);
          setMonth(m);
          setDay('');
          setBirthDate(year, m, '');
        }}
        options={_.range(0, 12).map((m) => ({
          value: m,
          label: moment().month(m).format('MMMM'),
        }))}
        width={130}
      />

      <Autocomplete
        disabled={year === null || month === null}
        value={day || ''}
        onChange={(_, v) => {
          setDay(v);
          setBirthDate(year, month, v);
        }}
        options={days}
        autoHighlight
        disableClearable
        renderInput={(params) => (
          <TextField {...params} sx={{ width: 80 }} error={!day} label="Day" />
        )}
        getOptionLabel={(v) => v.toString()}
        isOptionEqualToValue={(o, v) => o === v}
      />
    </Box>
  );
};

export default CustomDatePicker;
