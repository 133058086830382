import { FunctionComponent } from 'react';
import { CustomCard } from 'src/common';
import AmountConfigInput from '../../../common/AmountConfigInput';
import ChoiceConfigInput from '../../../common/ChoiceConfigInput';
import FormRow from '../../../common/FormRow';

const BUSConfigForm: FunctionComponent = () => {
  return (
    <>
      <CustomCard title="BUS">
        <FormRow
          label={<label>Amount:</label>}
          input={
            <AmountConfigInput
              name="quotes.BUS.monthlyBenefit"
              required={true}
            />
          }
        />

        <FormRow
          label={<label>Structure:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.BUS.structure"
              choices={['S', 'L']}
            />
          }
        />

        <FormRow
          label={<label>Waiting Period:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.BUS.waitingPeriod"
              choices={[14, 30, 60, 90]}
            />
          }
        />

        <FormRow
          label={<label>Benefit Period:</label>}
          input={
            <ChoiceConfigInput name="quotes.BUS.benefitPeriod" choices={[1]} />
          }
        />
      </CustomCard>
    </>
  );
};

export default BUSConfigForm;
