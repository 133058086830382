import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { CustomCard } from 'src/common';
import FormRow from '../../common/FormRow';
import { validEmail } from '../../utils/validators';

const ReportConfigForm: FunctionComponent = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <CustomCard>
      <FormRow
        label={<label>Adviser Email:</label>}
        input={
          <>
            <input
              {...register('report.adviserEmail', {
                validate: (v) => !v || validEmail(v),
              })}
              style={{ width: 300 }}
            />
            {errors?.report?.adviserEmail && (
              <span style={{ marginLeft: 10 }}>
                Valid or Empty Email Required!
              </span>
            )}
          </>
        }
      />

      <FormRow
        label={<label>Sub Report Type:</label>}
        input={
          <>
            <input
              {...register('report.subReportType')}
              style={{ width: 300 }}
            />
          </>
        }
      />

      <FormRow
        label={
          <label>
            On <b>Your Results</b> page view, send Report to <b>Client</b>:
          </label>
        }
        input={
          <input type="checkbox" {...register('report.sendReportToClient')} />
        }
      />

      <FormRow
        label={
          <label>
            On <b>Your Results</b> page view, send Client Data with attached
            Report to <b>Adviser</b>:
          </label>
        }
        input={
          <input type="checkbox" {...register('report.sendReportToAdviser')} />
        }
      />

      <FormRow
        label={
          <label>
            Add <b>OmniLife Link</b> to Email:
          </label>
        }
        input={
          <input type="checkbox" {...register('report.addOmniLifeLink')} />
        }
      />

      <FormRow
        label={<label>Client Email Template:</label>}
        input={
          <Controller
            control={control}
            name="report.clientEmailTemplate"
            render={({ field: { onChange, value } }) => (
              <ReactQuill
                onChange={onChange}
                value={value || ''}
                style={{
                  width: 500,
                  height: 220,
                  paddingBottom: 20,
                }}
                theme="snow"
              />
            )}
          />
        }
      />
    </CustomCard>
  );
};

export default ReportConfigForm;
