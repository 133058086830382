import axios from 'axios';
import { debounce } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import FormRow from '../../common/FormRow';
import Label from '../../common/Label';
import {
  Occupation,
  occupationsRequest,
} from '../../services/omnilife.service';
import {
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const OccupationSelectorMui: FunctionComponent<{
  value: Occupation | null;
  onChange: (v: Occupation | null) => void;
  forAdminPanel?: boolean;
  occupationFilter?: Occupation[] | null;
}> = ({ value, onChange, forAdminPanel = false, occupationFilter = null }) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const width = xs ? 300 : 400;
  const [options, setOptions] = useState<Occupation[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const code = new URLSearchParams(window.location.search).get('code');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((filter: string) => {
      axios(occupationsRequest(filter, code))
        // getOmnilifeOccupations(filter)
        .then((response) => {
          setOptions(response.data || []);
          setLoading(false);
        });
    }, 500),
    []
  );

  useEffect(() => {
    setLoading(true);
    setOptions([]);
    if (!occupationFilter || occupationFilter.length === 0) {
      loadOptions(inputValue);
    } else {
      setOptions(occupationFilter);
      setLoading(false);
    }
  }, [inputValue, loadOptions, occupationFilter]);

  return (
    <FormRow
      label={
        <Label optional={forAdminPanel}>
          {forAdminPanel ? 'Default Occupation' : 'Occupation'}
        </Label>
      }
      input={
        <Autocomplete
          size="small"
          autoHighlight
          loading={loading}
          inputValue={inputValue}
          onInputChange={(_e, v) => setInputValue(v)}
          value={value}
          onChange={(_e, v) => onChange(v)}
          options={options}
          disableClearable={!forAdminPanel}
          getOptionLabel={(o) => o.description}
          isOptionEqualToValue={(o: Occupation, v: Occupation) =>
            o.id === v?.id
          }
          filterOptions={(x) => x}
          renderInput={(params) => (
            <TextField
              {...params}
              error={value === null && !forAdminPanel}
              sx={{ width }}
            />
          )}
        />
      }
    />
  );
};

export default OccupationSelectorMui;
