import { FunctionComponent } from 'react';
import { Tag } from '..';
import { TagRender } from './TagRender';

export const TagsRender: FunctionComponent<{
  tags: Tag[];
  setTag: (id: string) => (tag: Tag) => void;
}> = ({ tags, setTag }) => {
  return (
    <>
      {tags.map((e) => (
        <TagRender key={e.id} tag={e} setTag={setTag(e.id)} tags={tags} />
      ))}
      {tags.length === 0 && 'No Tags Added'}
    </>
  );
};
