import { FunctionComponent } from 'react';
import { CustomCard } from 'src/common';
import AmountConfigInput from '../../../common/AmountConfigInput';
import ChoiceConfigInput from '../../../common/ChoiceConfigInput';
import FormRow from '../../../common/FormRow';

const CHTConfigForm: FunctionComponent = () => (
  <CustomCard title="CHT">
    <FormRow
      label={<label>Amount:</label>}
      input={<AmountConfigInput name="quotes.CHT.sumInsured" required={true} />}
    />

    <FormRow
      label={<label>Date Of Birth:</label>}
      input={
        <ChoiceConfigInput
          name="quotes.CHT.dateOfBirth"
          choices={[]}
          hideChoices
          hideDefaultValue
        />
      }
    />

    <FormRow
      label={<label>Age:</label>}
      input={<AmountConfigInput name="quotes.CHT.age" required={true} />}
    />

    <FormRow
      label={<label>Gender:</label>}
      input={
        <ChoiceConfigInput name="quotes.CHT.gender" choices={['M', 'F']} />
      }
    />
  </CustomCard>
);

export default CHTConfigForm;
