import produce from 'immer';
import { FunctionComponent } from 'react';
import { createTag, Tag } from '../types/application.type';
import { TagList } from './TagList';
import { TagsRender } from './TagsRender';
import { CustomCard } from 'src/common';
import { Box, Button, Divider, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const PageEdit: FunctionComponent<{
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  exit: () => void;
  page: string;
}> = ({ tags, setTags, exit, page }) => {
  const setTag = (id: string) => (tag: Tag) => {
    setTags(
      produce(tags, (draft) => {
        const index = draft.findIndex((t) => t.id === id);
        draft[index] = tag;
      })
    );
  };

  const addTag = () => {
    setTags([...tags, createTag()]);
  };

  const removeTag = (id: string) => () => {
    setTags(tags.filter((t) => t.id !== id));
  };

  return (
    <CustomCard
      title={
        <>
          Edit Page: <b>{page}</b>
        </>
      }
      extra={
        <Button onClick={exit} startIcon={<ArrowBackIcon />} variant="outlined">
          Back
        </Button>
      }
    >
      <Box display="flex" justifyContent="space-around" mt={-1}>
        <b style={{ fontSize: 16 }}>Edit</b>
        <b style={{ fontSize: 16 }}>Preview</b>
      </Box>
      <Divider sx={{ mt: 1, mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TagList
            tags={tags}
            setTags={setTags}
            setTag={setTag}
            removeTag={removeTag}
          />
          <Button
            variant="contained"
            onClick={addTag}
            sx={{ mt: 4 }}
            size="small"
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={6}>
          <TagsRender tags={tags} setTag={setTag} />
        </Grid>
      </Grid>
    </CustomCard>
  );
};
