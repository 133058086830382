import { useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

interface TPD {
  occupationType: string;
  owner: string;
}

export const useTPDOptions = (
  tpd: TPD,
  formField: string,
  setValue: UseFormSetValue<FieldValues>
) => {
  const [occupationTypeOptions, setOccupationTypeOptions] = useState<string[]>([
    'A',
    'O',
    'H',
    'D',
    'E',
  ]);
  const ownerOptions = ['O', 'M', 'S', 'J'];

  useEffect(() => {
    if (tpd.owner === 'M' || tpd.owner === 'S') {
      setOccupationTypeOptions(['A']);
      if (tpd.occupationType !== 'A' && tpd.occupationType !== 'E') {
        setValue(formField, 'A');
      }
    } else if (tpd.owner === 'J') {
      setOccupationTypeOptions(['O']);
      setValue(formField, 'O');
    } else {
      setOccupationTypeOptions(['A', 'O', 'H', 'D', 'E']);
    }
  }, [tpd.owner, tpd.occupationType, setValue, formField]);

  // useEffect(() => {
  //   if (tpd.occupationType === 'H' || tpd.occupationType === 'D') {
  //     setOwnerOptions(['O', 'J']);
  //   } else if (tpd.occupationType === 'O') {
  //     setOwnerOptions(['J']);
  //   } else {
  //     setOwnerOptions(['O', 'M', 'S', 'J']);
  //   }
  // }, [tpd.occupationType]);

  return { occupationTypeOptions, ownerOptions };
};
