export const employmentStatuses = [
  { key: 'E', title: 'Employee' },
  // { key: 'P???',  title: 'Permanent Employee'},
  // { key: 'C???' , title: 'Casual Employee'},
  { key: 'T', title: 'Self Employed - Sole Trader' },
  { key: 'Q', title: 'Self Employed - Partnership' },
  { key: 'O', title: 'Self Employed - Business Owner' },
  { key: 'H', title: 'Home Duties' },
  { key: 'R', title: 'Retired' },
  { key: 'P', title: 'Pensioner' },
  { key: 'U', title: 'Unemployed' },
  { key: 'S', title: 'Student' },
];

export const isNonEmployed = (key?: string) =>
  ['H', 'R', 'P', 'U', 'S'].includes(key || '');

export const getCorrespondingOccupation = (key?: string) =>
  key
    ? {
        H: '1',
        R: '35',
        P: '34',
        U: '32',
        S: '36',
      }[key]
    : '';
