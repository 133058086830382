import { forwardRef, FunctionComponent } from 'react';
import { TextField } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';

const AmountFormatCustom = forwardRef(function NumberFormatCustom(
  props: any,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <CurrencyInput
      {...other}
      ref={ref}
      allowDecimals={false}
      allowNegativeValue={false}
      onValueChange={(v) => {
        const locCover: number = +(v || '0');
        if (onChange) onChange(locCover);
      }}
    />
  );
});

export const GenericAmountInput: FunctionComponent<{
  value?: number;
  onChange?: (cover: number) => void;
  onBlur?: (amount: number) => void;
  maxLength?: number;
  width?: number;
  label?: string;
  prefix?: string;
  endAdornment?: JSX.Element;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  disableGroupSeparators?: boolean | undefined;
}> = ({
  label,
  onChange = () => {},
  onBlur = () => {},
  value,
  width = 200,
  prefix = '$',
  endAdornment,
  helperText,
  error,
  disabled = false,
  maxLength = 7,
  disableGroupSeparators = false,
}) => (
  <TextField
    style={{ width: width }}
    size="small"
    label={label}
    value={value}
    onChange={(e) => onChange(e as any)}
    onBlur={(e) => onBlur(+e.target.value.replace(/\D/g, ''))}
    onKeyDown={(e: any) => {
      if (e.key === 'Enter') onBlur(+e.target.value.replace(/\D/g, ''));
    }}
    error={error}
    helperText={helperText}
    InputProps={{
      endAdornment: endAdornment,
      inputComponent: AmountFormatCustom,
      inputProps: { prefix, maxLength, disableGroupSeparators },
    }}
    disabled={disabled}
  />
);
