import { Alert, Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomCard } from 'src/common';
import AmountConfigInput from '../../../common/AmountConfigInput';
import ChoiceConfigInput from '../../../common/ChoiceConfigInput';
import FormRow from '../../../common/FormRow';

const TRSConfigForm: FunctionComponent = () => {
  const { watch } = useFormContext();
  const complexModeEnabled: boolean = watch('covers.complexMode');

  return (
    <>
      <CustomCard title="TRS">
        <FormRow
          label={<label>Amount:</label>}
          input={
            <AmountConfigInput name="quotes.TRS.sumInsured" required={true} />
          }
        />

        <FormRow
          label={<label>Structure:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TRS.structure"
              choices={['S', 'L', '70']}
            />
          }
        />

        <FormRow
          label={<label>Reinstatement:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TRS.traumaReinstatement"
              choices={['X', 'Y', 'N']}
            />
          }
        />

        <FormRow
          label={<label>Premium Waiver:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TRS.premiumWaiver"
              choices={['N', 'I', 'Y']}
            />
          }
        />

        <FormRow
          label={<label>Baby Care:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TRS.babyCare"
              choices={['X', 'I', 'Y', 'N']}
            />
          }
        />

        <FormRow
          label={<label>Priority:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TRS.priority"
              choices={['C', 'B', 'I']}
            />
          }
        />
      </CustomCard>

      <CustomCard
        title={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            TPR
            <Alert severity="warning">
              Visibiliy configuration works only with <b>Complex</b> mode
              enabled!
            </Alert>
          </Box>
        }
      >
        <FormRow
          label={<label>Amount:</label>}
          input={
            <AmountConfigInput
              name="quotes.TPR.sumInsured"
              required={complexModeEnabled}
            />
          }
        />

        <FormRow
          label={<label>Ownership:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPR.owner"
              choices={['O', 'M', 'S', 'J']}
            />
          }
        />

        <FormRow
          label={<label>Rollover:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPR.rollover"
              choices={['N', 'I']}
            />
          }
        />

        <FormRow
          label={<label>Structure:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPR.structure"
              choices={['S', 'L', '70']}
            />
          }
        />

        <FormRow
          label={<label>Occupation Type:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPR.occupationType"
              choices={['A', 'O', 'H', 'D', 'E']}
            />
          }
        />

        <FormRow
          label={<label>Premium Waiver:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPR.premiumWaiver"
              choices={['N', 'I', 'Y']}
            />
          }
        />
      </CustomCard>
    </>
  );
};

export default TRSConfigForm;
