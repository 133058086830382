import { FC, ReactNode, useState } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { DialogTitleWithCloseButton } from './DialogTitleWithCloseButton';

const InfoTooltip: FC<{ title: string; children: ReactNode }> = ({
  children,
  title,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Dialog open={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <DialogTitleWithCloseButton closeModal={() => setIsModalVisible(false)}>
          {title}
        </DialogTitleWithCloseButton>

        <DialogContent>{children}</DialogContent>
      </Dialog>

      <IconButton tabIndex={-1} onClick={() => setIsModalVisible(true)}>
        <InfoIcon />
      </IconButton>
    </>
  );
};

export default InfoTooltip;
