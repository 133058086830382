import { Box } from '@mui/material';
import { FC } from 'react';

export const Image: FC<{
  width?: number;
  height?: number;
  src: string;
  alt: string;
}> = ({ width, height, src, alt }) => (
  <Box width={width} height={height}>
    <img src={src} alt={alt} />
  </Box>
);
