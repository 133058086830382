import _ from 'lodash';
import { FunctionComponent, useState } from 'react';
import { Tag } from '../types/application.type';
import { TagId } from './TagId';
import { TagEdit } from './TagEdit';
import { Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const TagViewEdit: FunctionComponent<{
  tags: Tag[];
  tag: Tag;
  setTag: (tag: Tag) => void;
  removeTag: () => void;
}> = ({ tag, setTag, removeTag, tags }) => {
  const [editing, setEditing] = useState(false);

  return (
    <div style={{ padding: 10 }}>
      {editing ? (
        <TagEdit
          tags={tags}
          tag={tag}
          removeTag={removeTag}
          setTag={setTag}
          setEditing={setEditing}
        />
      ) : (
        <TagView tag={tag} setEditing={setEditing} />
      )}
    </div>
  );
};

const TagView: FunctionComponent<{
  tag: Tag;
  setEditing: (editing: boolean) => void;
}> = ({ tag, setEditing }) => (
  <>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <TagId id={tag.id} />

      <b>{_.startCase(tag.type)}</b>

      <Button
        onClick={() => setEditing(true)}
        startIcon={<EditIcon />}
        variant="contained"
        size="small"
      >
        <span style={{ width: 33 }}>Edit</span>
      </Button>
    </Box>

    <div dangerouslySetInnerHTML={{ __html: tag.label }} />
  </>
);
