import { Rating } from '@mui/material';
import { FC } from 'react';
import { useConfigContext } from '../../configs/config.context';
import { Combined } from '../../models/quote-response.model';

export const Score: FC<{ score: Combined }> = ({ score }) => {
  const scoreFormat = useConfigContext().display.scoreFormat;

  return {
    Raw: <>{score.raw.toFixed(0)}</>,
    5: <>{score['5']}</>,
    15: <>{score['15']}</>,
    Star: <Rating precision={0.1} readOnly value={getStarScore(score.star)} />,
  }[scoreFormat];
};

function getStarScore(starScore: string) {
  let fullStar = 0;
  let halfStar = 0;
  for (var i = 0; i < starScore.length; i++) {
    if (starScore[i] === '*') {
      fullStar++;
    } else if (starScore[i] === '+') {
      halfStar++;
    }
  }
  let result = fullStar + (halfStar * 0.5);
  return result;
}
