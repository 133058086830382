import emailValidator from 'email-validator';
import { Visibility } from 'src/models/config.model';

export const validEmail = (email: string | undefined): boolean =>
  emailValidator.validate(email || '');

export const validName = (v: any) => {
  const nameRegex = /[<>%$]/;
  if (typeof v === 'string' && !nameRegex.test(v)) return (v || '').length > 0;
  else return false;
};

export const validPhoneNumber = (v: any) =>
  typeof v === 'string' &&
  v.length > 0 &&
  /^\d{7,}$/.test(v.replace(/[\s()+\-.]|ext/gi, ''));

export const valid = (v: any) => {
  if (typeof v === 'string') return (v || '').length > 0;
  else return v !== undefined;
};

export const annualIncomeValid = (
  annualIncomeVisible: Visibility | undefined,
  incomeProtectionSelected: boolean,
  annualIncome: number | undefined,
  incomeProtectionMonthlyCover: number
) =>
  annualIncomeVisible !== 'visible' ||
  !incomeProtectionSelected ||
  (0.7 * (annualIncome || 0)) / 12 >= incomeProtectionMonthlyCover;
