import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from 'src/common/FormRow';
import { Link } from 'react-router-dom';
import { CustomCard } from 'src/common';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export const ApplyFormForm: FunctionComponent<{ code: string }> = ({
  code,
}) => {
  const { register } = useFormContext();

  return (
    <>
      <CustomCard title="Apply Form">
        <FormRow
          label={<label>Apply Form Enabled:</label>}
          input={<input type="checkbox" {...register('application.enabled')} />}
        />
        <FormRow
          label={<label>Email:</label>}
          input={
            <input {...register('application.email')} style={{ width: 300 }} />
          }
        />
        <Link to={`/config/${code}/application`}>
          <Button variant="contained" startIcon={<EditIcon />} sx={{ mt: 2 }}>
            Edit Apply Form Layout
          </Button>
        </Link>
      </CustomCard>
    </>
  );
};
