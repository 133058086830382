import { FunctionComponent } from 'react';
import { CHT, genderNames } from '../../../models/needs/cht.model';
import { currencyFormat } from '../../../utils/common';
import { InclusionsBreakdownTable } from './InclusionsBreakdownTable';

export const CHTBreakdown: FunctionComponent<{ cht: CHT }> = ({ cht }) => {
  return (
    <div style={{ marginBottom: 30 }}>
      {cht.children.map((c) => (
        <InclusionsBreakdownTable
          key={c.dateOfBirth?.toString()}
          title="Child Trauma"
          data={[
            ['Sum Insured', currencyFormat(c.sumInsured)],
            ['Date of Birth', c.dateOfBirth],
            ['Age', c.age],
            ['Gender', genderNames(c.gender)],
          ]}
        />
      ))}
    </div>
  );
};
