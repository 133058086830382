import _ from 'lodash';
import { FunctionComponent } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { useConfigContext } from '../../configs/config.context';
import { MenuItem, Select } from '@mui/material';

type Option = { key: number | string; name: string };

const CustomSelect: FunctionComponent<{
  name: string;
  options: Option[];
}> = ({ name, options }) => {
  const quotes = useConfigContext().quotes;
  const choices = _.has(quotes, name)
    ? _.get(quotes, name).choices
    : _.get(quotes, name.substring(name.indexOf('.') + 1)).choices;

  return (
    <Controller
      name={name as keyof FieldValues}
      rules={{ required: true }}
      render={({ field: { onChange, value }, fieldState: { invalid } }) => (
        <>
          <Select
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Select Option"
            error={invalid}
            size="small"
            sx={{ width: 200 }}
            MenuProps={{
              autoFocus: false,
              disableAutoFocusItem: true,
              disableEnforceFocus: true,
              disableAutoFocus: true,
            }}
          >
            {options
              .filter(({ key }) => choices.includes(key))
              .map(({ key, name }) => (
                <MenuItem key={key} value={key}>
                  {name}
                </MenuItem>
              ))}
          </Select>
        </>
      )}
    />
  );
};

export const OptionSelectorWithNamer: FunctionComponent<{
  name: string;
  options: (number | string)[];
  namer: (key: any) => string;
}> = ({ name, options, namer }) => (
  <CustomSelect
    name={name}
    options={options.map((o) => ({ key: o, name: namer(o) }))}
  />
);
