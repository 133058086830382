import { FunctionComponent, useState } from 'react';
import { useRecoilState } from 'recoil';
import { adminCredsState } from '../../models/admin/credentials.model';
import { axiosAdmin } from '../../configs/axios.config';
import { deleteConfigRequest } from '../../services/config.service';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  MenuItem,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const DeleteConfigMenuItem: FunctionComponent<{
  code: string;
  fetchConfigs: () => void;
  closeMenu: () => void;
}> = ({ code, fetchConfigs, closeMenu }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creds] = useRecoilState(adminCredsState);

  const deleteConfig = async () => {
    setLoading(true);
    await axiosAdmin(deleteConfigRequest(code, creds));
    fetchConfigs();
    setLoading(false);
    setIsModalVisible(false);
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          setIsModalVisible(true);
          closeMenu();
        }}
        sx={{ color: 'red' }}
      >
        Delete
      </MenuItem>

      <Dialog
        open={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          setLoading(false);
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Alert severity="error">
            <AlertTitle>Do you want to delete the following config?</AlertTitle>
            <Typography fontWeight="bold">{code}</Typography>
          </Alert>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button variant="outlined" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>

            <LoadingButton
              loading={loading}
              variant="contained"
              color="error"
              onClick={deleteConfig}
            >
              Delete
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteConfigMenuItem;
