import { FunctionComponent, useEffect } from 'react';
import useScrollToTop from '../../hooks/useScrollToTop';
import { SelectedCoverTypes } from '../../models/selected-cover-types.model';
import { NeedDict, valid } from '../../models/needs/need.model';
import NavButtons from '../NavButtons';
import INCForm from './INCForm';
import TRMForm from './TRMForm';
import TPDForm from './TPDForm';
import TRSForm from './TRSForm';
import BUSForm from './BUSForm';
import NESForm from './NESForm';
import CHTForm from './CHT/CHTForm';
import { useFormContext } from 'react-hook-form';
import { useConfigContext } from '../../configs/config.context';
import { useGTMEvent } from '../../hooks/gtmHooks';
import TPRForm from './TPRForm';
import TPEForm from './TPEForm';
import TREForm from './TREForm';
import { ClientInput } from 'src/models/client-input.model';

export interface YourOptionsProps {
  coverTypes: SelectedCoverTypes;
  client: ClientInput;
  setClient: (callback: (value: ClientInput) => ClientInput) => void;
  incomeProtectionMonthlyCover: number;
}

const YourOptions: FunctionComponent<YourOptionsProps> = ({
  coverTypes,
  client,
  setClient,
  incomeProtectionMonthlyCover,
}) => {
  useGTMEvent('LISTPage2Options', { page: 'Your Options' });

  useScrollToTop();
  const config = useConfigContext();
  const { watch, trigger } = useFormContext();

  let lifeInsuranceCalculatorUrl =
    'https://moneysmart.gov.au/how-life-insurance-works/life-insurance-calculator';

  if (config.display.lifeInsuranceCalculatorUrl !== undefined) {
    //Double if to avoid typescript warning
    if (config.display.lifeInsuranceCalculatorUrl !== '') {
      //about undefined | string type
      lifeInsuranceCalculatorUrl = config.display.lifeInsuranceCalculatorUrl;
    }
  }

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <>
      {coverTypes.TRM && (
        <TRMForm lifeInsuranceCalculatorUrl={lifeInsuranceCalculatorUrl} />
      )}
      {coverTypes.TRM && coverTypes.TPE && (
        <TPEForm lifeInsuranceCalculatorUrl={lifeInsuranceCalculatorUrl} />
      )}
      {coverTypes.TRM && coverTypes.TRE && (
        <TREForm lifeInsuranceCalculatorUrl={lifeInsuranceCalculatorUrl} />
      )}

      {coverTypes.TPD && (
        <TPDForm lifeInsuranceCalculatorUrl={lifeInsuranceCalculatorUrl} />
      )}

      {coverTypes.TRS && (
        <TRSForm lifeInsuranceCalculatorUrl={lifeInsuranceCalculatorUrl} />
      )}
      {coverTypes.TRS && coverTypes.TPR && (
        <TPRForm lifeInsuranceCalculatorUrl={lifeInsuranceCalculatorUrl} />
      )}

      {coverTypes.INC && (
        <INCForm
          client={client}
          setClient={setClient}
          lifeInsuranceCalculatorUrl={lifeInsuranceCalculatorUrl}
          incomeProtectionMonthlyCover={incomeProtectionMonthlyCover}
        />
      )}

      {coverTypes.BUS && <BUSForm />}

      {coverTypes.NES && <NESForm />}

      {coverTypes.CHT && <CHTForm />}

      <NavButtons
        nextEnabled={valid(coverTypes, watch() as NeedDict, config)}
      />
    </>
  );
};

export default YourOptions;
