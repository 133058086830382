import axios from 'axios';
import { debounce } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import FormRow from '../../common/FormRow';
import Label from '../../common/Label';
import {
  Occupation,
  occupationsRequest,
} from '../../services/omnilife.service';
import { Autocomplete, TextField } from '@mui/material';

const MultiOccupationSelectorMui: FunctionComponent<{
  value: Occupation[];
  onChange: (v: Occupation[]) => void;
}> = ({ value, onChange }) => {
  const [options, setOptions] = useState<Occupation[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const code = new URLSearchParams(window.location.search).get('code');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((filter: string) => {
      axios(occupationsRequest(filter, code))
        // getOmnilifeOccupations(filter)
        .then((response) => {
          setOptions(response.data || []);
          setLoading(false);
        });
    }, 500),
    []
  );

  useEffect(() => {
    setLoading(true);
    setOptions([]);
    loadOptions(inputValue);
  }, [inputValue, loadOptions]);

  return (
    <FormRow
      label={<Label optional>Occupation Filter</Label>}
      input={
        <Autocomplete
          multiple
          size="small"
          autoHighlight
          loading={loading}
          inputValue={inputValue}
          onInputChange={(_e, v) => setInputValue(v)}
          value={value}
          onChange={(_e, v) => onChange(v)}
          options={options}
          getOptionLabel={(o) => o.description}
          isOptionEqualToValue={(o: Occupation, v: Occupation) =>
            o.id === v?.id
          }
          filterOptions={(x) => x}
          renderInput={(params) => (
            <TextField
              {...params}
              error={value === null}
              sx={{ maxWidth: 600, minWidth: 400 }}
            />
          )}
        />
      }
    />
  );
};

export default MultiOccupationSelectorMui;
