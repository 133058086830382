export function ownerNames(
  key: 'O' | 'M' | 'S' | 'J' | 'K' | undefined
): string {
  if (key === undefined) return '';
  else
    return {
      O: 'Non-Super',
      M: 'SMSF',
      S: 'Super',
      J: 'SuperLink',
      K: 'SMSF SuperLink',
    }[key];
}
