import { CHTConfig } from './../config.model';
import moment from 'moment';
import { Moment } from 'moment';

/** Child Trauma */
export interface CHT {
  children: Child[];
}

export interface Child {
  number: number;
  sumInsured: number;
  dateOfBirth: Moment | null;
  age?: number;
  gender: 'M' | 'F' | undefined;
}

export function genderNames(key: 'M' | 'F' | undefined): string {
  if (key === undefined) return '';
  else
    return {
      M: 'Male',
      F: 'Female',
    }[key];
}

export function getCHT(config: CHTConfig): CHT {
  return {
    children: [getChild(1, config)],
  };
}

export function getChild(number: number, config: CHTConfig): Child {
  return {
    number: number,
    sumInsured: config.sumInsured.default,
    dateOfBirth: moment()
      .subtract(config.age.default, 'years')
      .subtract(1, 'days'),
    age: config.age.default,
    gender: config.gender.default,
  };
}

export function CHTValid(cht: CHT, config: CHTConfig) {
  return cht.children.every(
    (c) =>
      (config.gender.visibility === 'hidden' || c.gender !== undefined) &&
      c.age !== undefined &&
      c.sumInsured > 0
  );
}
