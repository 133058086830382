import { axiosAdmin } from './../../configs/axios.config';
import { OmnilifeApiCredsModel } from './omnilife-api-creds.model';
import { useState } from 'react';
import { testOmnilifeApiCreds } from './omnilife-api-creds.service';
import { useRecoilState } from 'recoil';
import { adminCredsState } from '../../models/admin/credentials.model';
import { toast } from 'react-hot-toast';

export function useTestConnection(): [
  (code: string, creds: OmnilifeApiCredsModel) => void,
  boolean
] {
  const [adminCreds] = useRecoilState(adminCredsState);
  const [testing, setTesting] = useState(false);

  const test = (code: string, creds: OmnilifeApiCredsModel) => {
    setTesting(true);
    axiosAdmin(testOmnilifeApiCreds(adminCreds, code, creds))
      .then(() => {
        toast.success('Connection Successful!');
      })
      .catch(() => {
        toast.error('Connection Failed!');
      })
      .finally(() => setTesting(false));
  };

  return [test, testing];
}
