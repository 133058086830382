import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import CoverAmountInput from '../../common/CoverAmountInput';
import FormRow from '../../common/FormRow';
import InfoTooltip from '../../common/InfoTooltip';
import Label from '../../common/Label';
import { useConfigContext } from '../../configs/config.context';
import { structureNames } from '../../models/dictionaries/structure.model';
import { OptionSelectorWithNamer } from './OptionSelector';

const TRSForm: FunctionComponent = () => {
  const config = useConfigContext().quotes.NES;

  return (
    <>
      <div style={{ marginBottom: 10, marginTop: 25 }}>
        <b>Needle Stick</b>
      </div>

      <FormRow
        visibility={config.sumInsured.visibility}
        label={<Label>Sum Insured</Label>}
        input={
          <Controller
            name="NES.sumInsured"
            rules={{ required: true, min: 1 }}
            render={({ field: { onChange, value } }) => (
              <CoverAmountInput defaultValue={value} onValueChange={onChange} />
            )}
          />
        }
        info={
          <InfoTooltip title="Sum Insured">
            Your preferred amount of cover. Note that some insurers only support
            Needle Stick in combinations with other products, and every insurer
            has restrictions on which client occupations are supported. This
            cover is specifically designed for medical professionals.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.structure.visibility}
        label={<Label>Structure</Label>}
        input={
          <OptionSelectorWithNamer
            name="NES.structure"
            options={['S', 'L']}
            namer={structureNames}
          />
        }
        info={
          <InfoTooltip title="Structure">
            {config.structure.choices.includes('S') && (
              <>
                Stepped - Premiums will increase every year. <br />
              </>
            )}
            {config.structure.choices.includes('L') && (
              <>Level - Premiums will stay the same every year.</>
            )}
          </InfoTooltip>
        }
      />
    </>
  );
};

export default TRSForm;
