import { MenuItem } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useRecoilState } from 'recoil';
import { axiosAdmin } from '../../configs/axios.config';
import { adminCredsState } from '../../models/admin/credentials.model';
import { cloneConfigRequest } from '../../services/config.service';

const CloneConfigMenuItem: FunctionComponent<{
  code: string;
  fetchConfigs: () => void;
  closeMenu: () => void;
}> = ({ code, fetchConfigs, closeMenu }) => {
  const [creds] = useRecoilState(adminCredsState);
  const [loading, setLoading] = useState(false);

  const deleteConfig = (code: string) => {
    setLoading(true);
    axiosAdmin(cloneConfigRequest(code, creds))
      .then(() => fetchConfigs())
      .finally(() => {
        setLoading(false);
        closeMenu();
      });
  };

  return (
    <MenuItem onClick={() => deleteConfig(code)}>
      {loading ? 'Cloning...' : 'Clone'}
    </MenuItem>
  );
};

export default CloneConfigMenuItem;
