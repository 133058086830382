import { FC } from 'react';
import { Box } from '@mui/material';

const Label: FC<{ children: React.ReactNode; optional?: boolean }> = ({
  children,
  optional = false,
}) => (
  <Box display="flex">
    <Box fontSize="1.2rem" width={12} color={optional ? 'transparent' : 'red'}>
      *
    </Box>
    {children}
  </Box>
);

export default Label;
