import { Divider, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomRadio } from 'src/common/CustomRadio';
import AgeInput from '../../../common/AgeInput';
import CoverAmountInput from '../../../common/CoverAmountInput';
import FormRow from '../../../common/FormRow';
import InfoTooltip from '../../../common/InfoTooltip';
import Label from '../../../common/Label';
import { useConfigContext } from '../../../configs/config.context';

const ChildForm: FunctionComponent<{
  index: number;
  childNumber: number;
}> = ({ childNumber, index }) => {
  const config = useConfigContext().quotes.CHT;

  const { watch, setValue } = useFormContext();
  const age = watch(`CHT.children.${index}.age`);
  const dateOfBirth = watch(`CHT.children.${index}.dateOfBirth`);

  return (
    <>
      <Divider textAlign="left">Child {childNumber}</Divider>

      <FormRow
        visibility={config.sumInsured.visibility}
        label={<Label>Sum Insured</Label>}
        input={
          <Controller
            name={`CHT.children.${index}.sumInsured`}
            render={({ field: { onChange, value } }) => (
              <CoverAmountInput defaultValue={value} onValueChange={onChange} />
            )}
          />
        }
        info={
          <InfoTooltip title="Sum Insured">
            The preferred amount of cover for this child.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.dateOfBirth.visibility}
        label={<Label optional>Date of Birth</Label>}
        input={
          <DatePicker
            value={dateOfBirth}
            onChange={(newDate) => {
              const newAge =
                newDate === dateOfBirth ? age : moment().diff(newDate, 'years');

              setValue(`CHT.children.${index}.age`, newAge);
              setValue(`CHT.children.${index}.dateOfBirth`, newDate);
            }}
            inputFormat="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField {...params} sx={{ width: 200 }} size="small" />
            )}
          />
        }
        info={
          <InfoTooltip title="Date of Birth">
            The date this child was born.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.age.visibility}
        label={<Label>Age</Label>}
        input={
          <AgeInput
            value={age}
            onValueChange={(newAge, n) => {
              const newDate =
                newAge === age
                  ? dateOfBirth
                  : moment()
                      .subtract(newAge || 0, 'years')
                      .subtract(1, 'days');

              setValue(`CHT.children.${index}.age`, newAge);
              setValue(`CHT.children.${index}.dateOfBirth`, newDate);
            }}
          />
        }
        info={
          <InfoTooltip title="Age">The current age of this child.</InfoTooltip>
        }
      />

      <FormRow
        visibility={config.gender.visibility}
        label={<Label>Gender</Label>}
        input={
          <Controller
            name={`CHT.children.${index}.gender`}
            render={({ field: { onChange, value } }) => (
              <CustomRadio
                value={value}
                onChange={onChange}
                options={[
                  { label: 'Male', value: 'M' },
                  { label: 'Female', value: 'F' },
                ]}
              />
            )}
          />
        }
        info={
          <InfoTooltip title="Gender">The gender of the child.</InfoTooltip>
        }
      />
    </>
  );
};

export default ChildForm;
