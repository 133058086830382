import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import CoverAmountInput from '../../common/CoverAmountInput';
import FormRow from '../../common/FormRow';
import InfoTooltip from '../../common/InfoTooltip';
import Label from '../../common/Label';
import { useConfigContext } from '../../configs/config.context';
import { babyCareNames } from '../../models/dictionaries/babyCare.model';
import { premiumWaiverNames } from '../../models/dictionaries/premiumWaiver.model';
import { priorityNames } from '../../models/dictionaries/priority.model';
import { structureNames } from '../../models/dictionaries/structure.model';
import { traumaReinstatementNames } from '../../models/dictionaries/traumaReinstatement.model';
import { OptionSelectorWithNamer } from './OptionSelector';

const TRSForm: FunctionComponent<{
  lifeInsuranceCalculatorUrl: string;
}> = ({ lifeInsuranceCalculatorUrl }) => {
  const config = useConfigContext().quotes.TRS;

  return (
    <>
      <div style={{ marginBottom: 10, marginTop: 25 }}>
        <b>Trauma Cover</b>
      </div>

      <FormRow
        visibility={config.sumInsured.visibility}
        label={<Label>Cover</Label>}
        input={
          <Controller
            name="TRS.sumInsured"
            rules={{ required: true, min: 1 }}
            render={({ field: { onChange, value } }) => (
              <CoverAmountInput defaultValue={value} onValueChange={onChange} />
            )}
          />
        }
        info={
          <InfoTooltip title="Cover">
            If you suffer from one of the listed major health events, Trauma
            Cover can provide you with the financial resources to make lifestyle
            or career adjustments to help you recover. Trauma can provide a lump
            sum benefit.
            <br /> <br />
            The amount of Trauma Cover needed will vary upon your individual
            circumstance. If you are unsure of how much Trauma Cover you need,
            this{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={lifeInsuranceCalculatorUrl}
            >
              Life Insurance Calculator
            </a>{' '}
            that may provide a helpful guide to you.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.structure.visibility}
        label={<Label>Structure</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRS.structure"
            options={['S', 'L', '70']}
            namer={structureNames}
          />
        }
        info={
          <InfoTooltip title="Structure">
            {config.structure.choices.includes('S') && (
              <>
                Stepped – Premiums will generally increase every year in line
                with the age of the insured. Premiums may vary due to sum
                insured changes including Consumer Price Index (CPI) movements
                or changes to the underlying premium rates set by the insurer.
                <br /> <br />
              </>
            )}
            {config.structure.choices.includes('L') && (
              <>
                Level to 65 – Premiums will not increase every year due to age
                until age 65. Premiums may vary due to sum insured changes
                including Consumer Price Index (CPI) movements or changes to the
                underlying premium rates set by the insurer. <br /> <br />
              </>
            )}
            {config.structure.choices.includes('70') && (
              <>
                Level to 70 – Premiums will not increase every year due to age
                until age 70. Premiums may vary due to sum insured changes
                including Consumer Price Index (CPI) movements or changes to the
                underlying premium rates set by the insurer.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.traumaReinstatement.visibility}
        label={<Label>Reinstatement</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRS.traumaReinstatement"
            options={['X', 'Y', 'N']}
            namer={traumaReinstatementNames}
          />
        }
        info={
          <InfoTooltip title="Reinstatement">
            Reinstatement is a feature which allows for cover to be reinstated
            to the original sum insured following payment of a claim.
            <br /> <br />
            {config.traumaReinstatement.choices.includes('X') && (
              <>
                Exclude if possible - The option will only be included if it is
                built in to the product. <br /> <br />
              </>
            )}
            {config.traumaReinstatement.choices.includes('Y') && (
              <>
                Include - This will return products with Trauma Reinstatement.
                If Trauma Reinstatement is not available, the product will be
                excluded. <br /> <br />
              </>
            )}
            {config.traumaReinstatement.choices.includes('N') && (
              <>Exclude - Only products without this option can be used.</>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.premiumWaiver.visibility}
        label={<Label>Premium Waiver</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRS.premiumWaiver"
            options={['N', 'I', 'Y']}
            namer={premiumWaiverNames}
          />
        }
        info={
          <InfoTooltip title="Premium Waiver">
            {config.premiumWaiver.choices.includes('N') && (
              <>
                Exclude - All products must not contain premium waiver. Any
                products that have it built-in will not be used. <br />
              </>
            )}
            {config.premiumWaiver.choices.includes('I') && (
              <>
                Include if possible - If premium waiver is an option on the
                product, then take the option. Products that do not support
                premium waiver are also used. <br />
              </>
            )}
            {config.premiumWaiver.choices.includes('Y') && (
              <>
                Include - All products must contain premium waiver. <br />
              </>
            )}
            <br /> Note that for best results, please set all of your Premium
            Waiver settings to the same selection.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.babyCare.visibility}
        label={<Label>Baby Care</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRS.babyCare"
            options={['X', 'I', 'Y', 'N']}
            namer={babyCareNames}
          />
        }
        info={
          <InfoTooltip title="Baby Care">
            {config.babyCare.choices.includes('X') && (
              <>
                Exclude if possible - The option will only be included if it is
                built in to the product. <br /> <br />
              </>
            )}
            {config.babyCare.choices.includes('I') && (
              <>
                Include if possible - If Baby Care is an option on the product
                then the option will be taken. Products that do not support Baby
                Care are also used. <br /> <br />
              </>
            )}
            {config.babyCare.choices.includes('Y') && (
              <>
                Include - This will return products with Baby Care. If Baby Care
                is not available, the product will be excluded. <br /> <br />
              </>
            )}
            {config.babyCare.choices.includes('N') && (
              <>
                Exclude - This will return products without Baby Care. If Baby
                Care is built in, the product will be excluded.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.priority.visibility}
        label={<Label>Trauma Features</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRS.priority"
            options={['C', 'B', 'I']}
            namer={priorityNames}
          />
        }
        info={
          <InfoTooltip title="Priority">
            {config.priority.choices.includes('C') && (
              <>
                Standard - This option will return the basic version of the
                insurer's products. This will be the cheapest option.
                <br /> <br />
              </>
            )}
            {config.priority.choices.includes('B') && (
              <>
                Best available - This option will return the advanced version of
                the insurer's products. This will have the best features
                available to the life insured. For example, with maximiser and
                booster options. <br /> <br />
              </>
            )}
            {config.priority.choices.includes('I') && (
              <>
                Intermediate - This option will try to return a product slightly
                less than the most advanced version of the insurer's products.
                This may be less expensive than the best available.
              </>
            )}
          </InfoTooltip>
        }
      />
    </>
  );
};

export default TRSForm;
