import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { FC, ReactNode } from 'react';

export const CustomCard: FC<{
  title?: ReactNode;
  children: ReactNode;
  extra?: ReactNode;
}> = ({ title, children, extra }) => {
  return (
    <Card variant="outlined" sx={{ mb: 3 }}>
      {title && (
        <>
          <CardHeader
            sx={{ py: 1 }}
            title={<Typography variant="h6">{title}</Typography>}
            action={extra}
          />
          <Divider sx={{ m: 0 }} />
        </>
      )}

      <CardContent>{children}</CardContent>
    </Card>
  );
};
