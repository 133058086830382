import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import CoverAmountInput from '../../common/CoverAmountInput';
import FormRow from '../../common/FormRow';
import InfoTooltip from '../../common/InfoTooltip';
import Label from '../../common/Label';
import { useConfigContext } from '../../configs/config.context';
import { accidentBenefitNames } from '../../models/dictionaries/accidentBenefit.model';
import { agreedValueNames } from '../../models/dictionaries/agreedValue.model';
import { benefitPeriodNames } from '../../models/dictionaries/benefitPeriod.model';
import { increaseClaimBenefitNames } from '../../models/dictionaries/increaseClaimBenefit.model';
import { ownerNames } from '../../models/dictionaries/owner.model';
import { priorityNames } from '../../models/dictionaries/priority.model';
import { rolloverNames } from '../../models/dictionaries/rollover.model';
import { structureNames } from '../../models/dictionaries/structure.model';
import { waitingPeriodNames } from '../../models/dictionaries/waitingPeriod.model';
import { OptionSelectorWithNamer } from './OptionSelector';
import { ClientInput } from '../../models/client-input.model';
import { annualIncomeValid } from 'src/utils/validators';
import { currencyFormat } from 'src/utils/common';

const INCForm: FunctionComponent<{
  lifeInsuranceCalculatorUrl: string;
  client: ClientInput;
  setClient: (callback: (value: ClientInput) => ClientInput) => void;
  incomeProtectionMonthlyCover: number;
}> = ({
  lifeInsuranceCalculatorUrl,
  client,
  setClient,
  incomeProtectionMonthlyCover,
}) => {
  const config = useConfigContext();
  const incConfig = config.quotes.INC;
  const yourDetailsConfig = config.yourDetails;

  const isAnnualIncomeValid = annualIncomeValid(
    yourDetailsConfig?.annualIncomeVisible,
    true,
    client.annualIncome,
    incomeProtectionMonthlyCover
  );

  console.log(incConfig);

  return (
    <>
      <div style={{ marginBottom: 15, marginTop: 25 }}>
        <b>Income Protection Cover</b>
        <div style={{ color: 'gray', fontWeight: 700 }}>
          The maximum monthly cover should not exceed 70% of your monthly income
        </div>
        {incConfig.superContributionOption &&
          incConfig.superContributionOption.visibility === 'visible' && (
            <div style={{ color: 'gray' }}>
              The maximum monthly super contribution should not exceed 11.5% of
              your monthly income
            </div>
          )}
      </div>

      {incConfig.annualIncomeVisible === 'visible' && (
        <FormRow
          label={<Label optional>Annual Income</Label>}
          input={
            <CoverAmountInput
              manualValid={isAnnualIncomeValid}
              dontValidate
              defaultValue={client.annualIncome}
              emptyDefaultValue
              onValueChange={(n) =>
                setClient((c) => ({
                  ...c,
                  annualIncome: n,
                }))
              }
            />
          }
          info={
            <InfoTooltip title="Annual Income">
              Enter your annual income here (exclusive of superannuation
              benefit).
            </InfoTooltip>
          }
        />
      )}

      <>
        <FormRow
          visibility={incConfig.monthlyBenefit.visibility}
          label={<Label>Monthly Cover</Label>}
          sx={{
            marginBottom: !isAnnualIncomeValid && client.annualIncome ? 0 : 2,
          }}
          input={
            <Controller
              name="INC.monthlyBenefit"
              rules={{ required: true, min: 1 }}
              render={({ field: { onChange, value } }) => (
                <CoverAmountInput
                  defaultValue={value}
                  onValueChange={onChange}
                  maxLength={5}
                />
              )}
            />
          }
          info={
            <InfoTooltip title="Monthly Cover">
              When illness or injury prevents you from working in your
              occupation or any occupation, Income Protection Cover supports you
              by paying a monthly benefit.
              <br /> <br />
              The amount of Income Protection Cover needed will vary upon your
              individual circumstance. If you are unsure of how much Income
              Protection Cover you need, this{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={lifeInsuranceCalculatorUrl}
              >
                Life Insurance Calculator
              </a>{' '}
              that may provide a helpful guide to you.
            </InfoTooltip>
          }
        />
        {!isAnnualIncomeValid && client.annualIncome !== 0 && (
          <div style={{ color: 'red', marginLeft: 10, marginBottom: 5 }}>
            Your Annual Income is not sufficient to receive your requested
            Income Protection Monthly Cover. The requested income of{' '}
            {currencyFormat(incomeProtectionMonthlyCover)} exceeds the allowance
            amount of {currencyFormat((0.7 * (client.annualIncome || 0)) / 12)}{' '}
            for an annual income of {currencyFormat(client.annualIncome || 0)}.
            Please decrease the Monthly Cover above.
          </div>
        )}
      </>

      {incConfig.superContributionOption && ( //new field that old models don't have, so check if it exists
        <FormRow
          visibility={incConfig.superContributionOption.visibility}
          label={<Label>Super Contribution</Label>}
          input={
            <Controller
              name="INC.superContributionOption"
              rules={{ required: true, min: 1 }}
              render={({ field: { onChange, value } }) => (
                <CoverAmountInput
                  defaultValue={value}
                  onValueChange={onChange}
                  maxLength={5}
                />
              )}
            />
          }
          info={
            <InfoTooltip title="Super Contribution">
              The amount of your monthly employer super contribution to cover.
            </InfoTooltip>
          }
        />
      )}

      <FormRow
        visibility={incConfig.owner.visibility}
        label={<Label>Ownership</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.owner"
            options={['O', 'M', 'S', 'J', 'K']}
            namer={ownerNames}
          />
        }
        info={
          <InfoTooltip title="Ownership">
            {incConfig.owner.choices.includes('O') && (
              <>
                Non-Super - The policy will be owned by the life insured or a
                company. <br />
              </>
            )}
            {incConfig.owner.choices.includes('M') && (
              <>
                SMSF - The policy will be owned by a Self Managed Super Fund.
                <br />
              </>
            )}
            {incConfig.owner.choices.includes('S') && (
              <>
                Super Fund - The policy will be owned by a Superannuation Fund.
                <br />
              </>
            )}
            {incConfig.owner.choices.includes('J') && (
              <>
                SuperLink - The policy will be a combination of Non-Super and
                Super Fund. <br />
              </>
            )}
            {incConfig.owner.choices.includes('K') && (
              <>
                SMSF SuperLink - The policy will be a combination of Non-Super
                and a SMSF.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={incConfig.rollover.visibility}
        label={<Label>Pay by Rollover</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.rollover"
            options={['N', 'I']}
            namer={rolloverNames}
          />
        }
        info={
          <InfoTooltip title="Pay by Rollover">
            Are you intending to pay your premiums through an eligible
            superannuation fund? <br /> <br />
            {incConfig.rollover.choices.includes('N') && (
              <>
                Exclude - Not paying premiums through a superannuation fund. No
                discount will apply. <br />
              </>
            )}
            {incConfig.rollover.choices.includes('I') && (
              <>
                Include if possible - Apply the pay by rollover discount on all
                products that support it, also include products that don't
                support pay by rollover for a better comparison. <br />
              </>
            )}
            <br /> Note this option is only available on limited frequencies,
            please use Yearly for best results.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={incConfig.structure.visibility}
        label={<Label>Structure</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.structure"
            options={['S', 'L']}
            namer={structureNames}
          />
        }
        info={
          <InfoTooltip title="Structure">
            {incConfig.structure.choices.includes('S') && (
              <>
                Stepped – Premiums will generally increase every year in line
                with the age of the insured. Premiums may vary due to sum
                insured changes including Consumer Price Index (CPI) movements
                or changes to the underlying premium rates set by the insurer.
                <br /> <br />
              </>
            )}
            {incConfig.structure.choices.includes('L') && (
              <>
                Level – Premiums will not increase every year due to age until
                age 65. Premiums may vary due to sum insured changes including
                Consumer Price Index (CPI) movements or changes to the
                underlying premium rates set by the insurer.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={incConfig.agreedValue.visibility}
        label={<Label>Agreed Value</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.agreedValue"
            options={['X', 'N']}
            namer={agreedValueNames}
          />
        }
      />

      <FormRow
        visibility={incConfig.accidentBenefit.visibility}
        label={<Label>Accident Benefit</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.accidentBenefit"
            options={['I', 'X', 'Y', 'N']}
            namer={accidentBenefitNames}
          />
        }
        info={
          <InfoTooltip title="Accident Benefit">
            The Accident Benefit is an option where benefits may be paid during
            the waiting period, provided you are Totally Disabled for a defined
            period as a result of an accident.
            <br /> <br />
            {incConfig.accidentBenefit.choices.includes('I') && (
              <>
                Include if possible - If this option is available on the product
                then the option will be taken. Products that do not support it
                are also used. <br /> <br />
              </>
            )}
            {incConfig.accidentBenefit.choices.includes('X') && (
              <>
                Exclude if possible - The option will only be included if it is
                a mandatory benefit of the product. <br /> <br />
              </>
            )}
            {incConfig.accidentBenefit.choices.includes('Y') && (
              <>
                Include - Only products with this option can be used.
                <br /> <br />
              </>
            )}
            {incConfig.accidentBenefit.choices.includes('N') && (
              <>Exclude - Only products without this option can be used.</>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={incConfig.increaseClaimBenefit.visibility}
        label={<Label>Increase Claim Benefit</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.increaseClaimBenefit"
            options={['I', 'X', 'Y', 'N']}
            namer={increaseClaimBenefitNames}
          />
        }
        info={
          <InfoTooltip title="Increase Claim Benefit">
            The Increase Claim Benefit increases the monthly benefit by CPI
            after 12 months of payment.
            <br /> <br />
            {incConfig.increaseClaimBenefit.choices.includes('I') && (
              <>
                Include if possible - If this option is available on the product
                then the option will be taken. Products that do not support it
                are also used. <br /> <br />
              </>
            )}
            {incConfig.increaseClaimBenefit.choices.includes('X') && (
              <>
                Exclude if possible - The option will only be included if it is
                a mandatory benefit of the product. <br /> <br />
              </>
            )}
            {incConfig.increaseClaimBenefit.choices.includes('Y') && (
              <>
                Include - Only products with this option can be used.
                <br /> <br />
              </>
            )}
            {incConfig.increaseClaimBenefit.choices.includes('N') && (
              <>Exclude - Only products without this option can be used.</>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={incConfig.waitingPeriod.visibility}
        label={<Label>Waiting Period</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.waitingPeriod"
            options={[14, 30, 60, 90, 180, 365, 730]}
            namer={waitingPeriodNames}
          />
        }
        info={
          <InfoTooltip title="Waiting Period">
            {incConfig.waitingPeriod.choices.includes(14) && (
              <>
                14 days - Only products with a waiting period of 14 days or 2
                weeks after disability can be used. <br />
              </>
            )}
            {incConfig.waitingPeriod.choices.includes(30) && (
              <>
                30 days - Only products with a waiting period of 30 days or 4
                weeks after disability can be used. <br />
              </>
            )}
            {incConfig.waitingPeriod.choices.includes(60) && (
              <>
                60 days - Only products with a waiting period of 60 days or 8
                weeks after disability can be used. <br />
              </>
            )}
            {incConfig.waitingPeriod.choices.includes(90) && (
              <>
                90 days - Only products with a waiting period of 90 days or 12
                weeks after disability can be used. <br />
              </>
            )}
            {incConfig.waitingPeriod.choices.includes(180) && (
              <>
                180 days - Only products with a waiting period of 180 days or 26
                weeks after disability can be used. <br />
              </>
            )}
            {incConfig.waitingPeriod.choices.includes(365) && (
              <>
                1 year - Only products with a waiting period of 1 year or 52
                weeks after disability can be used.
              </>
            )}
            {incConfig.waitingPeriod.choices.includes(730) && (
              <>
                <br />2 years - Only products with a waiting period of 2 years
                or 104 weeks after disability can be used.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={incConfig.benefitPeriod.visibility}
        label={<Label>Benefit Period</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.benefitPeriod"
            options={[1, 2, 5, 65, 70]}
            namer={benefitPeriodNames}
          />
        }
        info={
          <InfoTooltip title="Benefit Period">
            {incConfig.benefitPeriod.choices.includes(1) && (
              <>
                1 year - Receive your monthly benefit for up to 1 year while you
                are unable to work. <br />
              </>
            )}
            {incConfig.benefitPeriod.choices.includes(2) && (
              <>
                2 years - Receive your monthly benefit for up to 2 years while
                you are unable to work. <br />
              </>
            )}
            {incConfig.benefitPeriod.choices.includes(5) && (
              <>
                5 years - Receive your monthly benefit for up to 5 or 6 years
                while you are unable to work. <br />
              </>
            )}
            {incConfig.benefitPeriod.choices.includes(65) && (
              <>
                To age 65 - Receive your monthly benefit until your 65th
                birthday while you are unable to work. <br />
              </>
            )}
            {incConfig.benefitPeriod.choices.includes(70) && (
              <>
                To age 70 - Receive your monthly benefit until your 70th
                birthday while you are unable to work.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={incConfig.priority.visibility}
        label={<Label>IP Features</Label>}
        input={
          <OptionSelectorWithNamer
            name="INC.priority"
            options={['C', 'I', 'B']}
            namer={priorityNames}
          />
        }
        info={
          <InfoTooltip title="Priority">
            {incConfig.priority.choices.includes('C') && (
              <>
                Standard - This option will return the basic version of the
                insurer's products. This will be the cheapest option.
                <br /> <br />
              </>
            )}
            {incConfig.priority.choices.includes('I') && (
              <>
                Best available - This option will return the advanced version of
                the insurer's products. This will have the best features
                available to the life insured and higher monthly benefits.
                <br /> <br />
              </>
            )}
            {incConfig.priority.choices.includes('B') && (
              <>
                Intermediate - This option will try to return a product slightly
                less than the most advanced version of the insurer's products.
                This may be less expensive than the best available but have
                lower maximum monthly benefits.
              </>
            )}
          </InfoTooltip>
        }
      />
    </>
  );
};

export default INCForm;
