import { MenuItem } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { Portfolio } from '../../../models/quote-response.model';
import { SelectedCoverTypes } from '../../../models/selected-cover-types.model';
import InclusionsDialog from './InclusionsDialog';

const Inclustions: FunctionComponent<{
  selected: SelectedCoverTypes;
  portfolio: Portfolio;
  closeMenu: () => void;
}> = ({ portfolio, selected, closeMenu }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <MenuItem
        onClick={() => {
          closeMenu();
          !showModal && setShowModal(true);
        }}
      >
        More Details
      </MenuItem>

      <InclusionsDialog
        selected={selected}
        portfolio={portfolio}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default Inclustions;
