import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useCodeContext } from 'src/hooks/useCodeContext';
import { ClientInput } from 'src/models/client-input.model';
import { useConfigContext } from '../../configs/config.context';
import { Frequency, Portfolio } from '../../models/quote-response.model';
import { SelectedCoverTypes } from '../../models/selected-cover-types.model';
import { Actions } from './Actions';
import Premium from './Premium';
import { Score } from './Score';
import { Image } from 'src/common/Image';
import { Loading } from 'src/common';
import SupplierName from './SupplierName';

const QuoteCards: FunctionComponent<{
  portfolios: Portfolio[];
  loading: boolean;
  freq: Frequency;
  selected: SelectedCoverTypes;
  client: ClientInput;
}> = ({ portfolios, loading, freq, selected, client }) => {
  const config = useConfigContext().display;
  const code = useCodeContext();

  if (loading) return <Loading />;

  if (portfolios.length === 0)
    return (
      <div style={{ textAlign: 'center' }}>
        <b>
          No Insurers meet your required benefits. Please alter your
          requirements under Your Options.
        </b>
      </div>
    );

  return (
    <>
      {portfolios.map((p) => (
        <Card variant="outlined" key={p.code} sx={{ mb: 2 }}>
          <CardHeader
            title={
              config.name === 'visible' && (
                <SupplierName portfolio={p} selected={selected} />
              )
            }
            action={
              <Actions
                client={client}
                code={code}
                portfolio={p}
                selected={selected}
              />
            }
          />
          <Divider sx={{ m: 0 }} />
          <CardContent>
            <Grid container alignItems="center">
              {config.logo === 'visible' && (
                <Grid item xs={5.5}>
                  <Image
                    width={100}
                    height={57}
                    src={'https://' + p.supplier.logo}
                    alt={p.supplier.name + ' logo'}
                  />
                </Grid>
              )}
              <Grid item xs={6.5}>
                {config.premium === 'visible' && (
                  <>
                    <Typography variant="body1">Premium Estimate</Typography>
                    <Premium portfolio={p} freq={freq} />
                  </>
                )}
                {config.featureScore === 'visible' && (
                  <>
                    <Typography variant="body1" mt={1}>
                      Feature Score
                    </Typography>
                    <Score score={p.score.feature} />
                  </>
                )}
                {config.combinedScore === 'visible' && (
                  <>
                    <Typography variant="body1" mt={1}>
                      Combined Score
                    </Typography>
                    <Score score={p.score.feature} />
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default QuoteCards;
