import { Box, IconButton, MenuItem, Select } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';

const ChoiceConfigInput: FunctionComponent<{
  name: string;
  choices: (string | number)[];
  hideChoices?: boolean;
  hideVisibility?: boolean;
  hideDefaultValue?: boolean;
  allowClear?: boolean;
}> = ({
  name,
  choices,
  hideChoices = false,
  hideVisibility = false,
  hideDefaultValue = false,
  allowClear = true,
}) => {
  const { register, watch } = useFormContext();

  return (
    <Box display="flex">
      {!hideVisibility && (
        <div style={{ marginTop: 10 }}>
          <select {...register(name + '.visibility')}>
            <option value="visible">Visible</option>
            <option
              disabled={watch(name + '.default') === undefined}
              value="hidden"
            >
              Hidden
            </option>
          </select>
        </div>
      )}

      {!hideDefaultValue && (
        <div style={{ marginLeft: 30, marginBottom: 10 }}>
          <label>Default </label>
          <Controller
            name={name + '.default'}
            render={({ field: { onChange, value } }) => (
              <>
                <Select
                  value={value || ''}
                  onChange={(e) => onChange(e.target.value)}
                  sx={{ width: 80 }}
                  size="small"
                >
                  {choices.map((c) => (
                    <MenuItem key={c} value={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Select>

                <IconButton
                  size="small"
                  disabled={
                    !(allowClear && watch(name + '.visibility') === 'visible')
                  }
                  onClick={() => onChange(undefined)}
                >
                  <ClearIcon />
                </IconButton>
              </>
            )}
          />
        </div>
      )}

      {!hideChoices && (
        <div>
          <label style={{ marginLeft: 30 }}>Choices </label>
          <Controller
            name={name + '.choices'}
            render={({ field: { onChange, value } }) => (
              <Select
                multiple
                value={value || []}
                onChange={(e) => onChange(e.target.value)}
                sx={{ width: 200 }}
                size="small"
              >
                {choices.map((c) => (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </div>
      )}
    </Box>
  );
};

export default ChoiceConfigInput;
