import { CHT } from './needs/cht.model';
import { BUS } from './needs/bus.model';
import { INC } from './needs/inc.model';
import { NES } from './needs/nes.model';
import { SelectedCoverTypes } from './selected-cover-types.model';
import { NeedDict } from './needs/need.model';
import moment, { Moment } from 'moment';
import { ClientInput } from './client-input.model';
import { getTPE, getTRE, TRM } from './needs/trm.model';
import { TPD } from './needs/tpd.model';
import { getTPR, TRS } from './needs/trs.model';
import { Config, OutputSettings } from './config.model';
import _ from 'lodash';
import { RN } from '../configs/app.config';
import { CampaignOptionsDto } from './campaign-options.model';

type QuoteRequestNeeds = (
  | { TRM: TRM }
  | { TPS: TPD }
  | { TRS: TRS }
  | { INC: INC }
  | { BUS: BUS }
  | { NES: NES }
  | { CHT: CHT }
)[];

export interface QuoteRequest {
  clients: [
    {
      clientId: string;
      dateOfBirth?: Moment;
      age: number;
      gender: 'M' | 'F';
      smoker: boolean;
      occupationId: string;
      employmentStatus?: string;
      state: 'ACT' | 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA';
      income?: number;
      needs: QuoteRequestNeeds;
    }
  ];
  settings: OutputSettings;
  tags: { userId: string; groupId: string };
}

//write a code to transform MLC: [id: 'MLC_VIVO', label: 'Vivo Incentive'] to MLC: ['MLC_VIVO']
const transformCampaignOptions = (campaignOptions: any) => {
  const suppliers = Object.keys(campaignOptions);
  const result: CampaignOptionsDto = {};
  suppliers.forEach((supplier) => {
    result[supplier] = campaignOptions[supplier].map(
      (option: { id: any }) => option.id
    );
  });

  return result;
};

export function createQuoteRequest(
  client: ClientInput,
  selected: SelectedCoverTypes,
  needs: NeedDict,
  config: Config
): QuoteRequest {
  let result: QuoteRequest = {
    clients: [
      {
        clientId: client.clientId,
        age: moment().diff(client.dateOfBirth, 'years'),
        dateOfBirth: client.dateOfBirth,
        gender: client.gender || 'M',
        smoker: client.smoker || false,
        occupationId: client.occupationId || '',
        state: client.state,
        income: client.annualIncome,
        employmentStatus: client.employmentStatus,
        needs: [],
      },
    ],
    settings: {
      ...config.outputSettings,
      campaignOptions:
        config.outputSettings.campaignOptions &&
        transformCampaignOptions(config.outputSettings.campaignOptions),
    },
    tags: {
      userId: config.outputSettings.tagsUserId || 'ALQ',
      groupId: config.outputSettings.tagsGroupId || 'OMNILIFE',
    },
  };

  // TRM TRS TPD
  result.clients[0].needs = config.covers.complexMode
    ? getNeedsComplex(selected, needs)
    : getNeedsSimple(selected, needs, config);

  // INC
  if (selected.INC) result.clients[0].needs.push({ INC: { ...needs.INC } });

  // BUS
  if (selected.BUS) result.clients[0].needs.push({ BUS: { ...needs.BUS } });

  // NES
  if (selected.NES) result.clients[0].needs.push({ NES: { ...needs.NES } });

  // CHT
  if (selected.CHT)
    result.clients[0].needs.push({
      CHT: {
        children: [...needs.CHT.children.map((c) => ({ ...c }))],
      },
    });

  // Randomization
  if (config.display.randomize) {
    result.clients[0].needs = result.clients[0].needs.map((n) => {
      if (_.has(n, 'TRM')) {
        let TRM = _.get(n, 'TRM');
        return { TRM: { ...TRM, sumInsured: TRM.sumInsured + 500_000 * RN } };
      }
      if (_.has(n, 'TPS')) {
        let TPS = _.get(n, 'TPS');
        return { TPS: { ...TPS, sumInsured: TPS.sumInsured + 500_000 * RN } };
      }
      if (_.has(n, 'TRS')) {
        let TRS = _.get(n, 'TRS');
        return { TRS: { ...TRS, sumInsured: TRS.sumInsured + 500_000 * RN } };
      }

      if (_.has(n, 'INC')) {
        let INC = _.get(n, 'INC');
        return {
          INC: { ...INC, monthlyBenefit: INC.monthlyBenefit + 4_000 * RN },
        };
      }
      if (_.has(n, 'BUS')) {
        let BUS = _.get(n, 'BUS');
        return {
          BUS: { ...BUS, monthlyBenefit: BUS.monthlyBenefit + 4_000 * RN },
        };
      }

      return n;
    });
  }

  return result;
}

const getNeedsSimple = (
  selected: SelectedCoverTypes,
  needs: NeedDict,
  config: Config
): QuoteRequestNeeds => {
  let result: QuoteRequestNeeds = [];

  // TRM
  if (selected.TRM) {
    let TRM = {
      TRM: {
        ...needs.TRM,
        linkedNeeds: [...needs.TRM.linkedNeeds],
        TPE: undefined,
        TRE: undefined,
      },
    };

    if (selected.TPD) {
      if (needs.TPD.sumInsured <= needs.TRM.sumInsured) {
        TRM.TRM.linkedNeeds.push({
          TPE: { ...getTPE({ ...needs.TPD }, config.quotes.TPE) },
        });
      } else {
        result.push({ TPS: { ...needs.TPD } });
      }
    }

    if (selected.TRS) {
      if (needs.TRS.sumInsured <= needs.TRM.sumInsured) {
        TRM.TRM.linkedNeeds.push({
          TRE: { ...getTRE({ ...needs.TRS }, config.quotes.TRE) },
        });
      } else {
        result.push({
          TRS: { ...needs.TRS, linkedNeeds: [] },
        });
      }
    }

    result.push(TRM);
  }
  // TRS
  else if (selected.TRS) {
    let TRS = {
      TRS: {
        ...needs.TRS,
        linkedNeeds: [...needs.TRS.linkedNeeds],
        TPR: undefined,
      },
    };

    if (selected.TPD) {
      if (needs.TPD.sumInsured <= needs.TRS.sumInsured) {
        TRS.TRS.linkedNeeds.push({ TPR: getTPR({ ...needs.TPD }) });
      } else {
        result.push({ TPS: { ...needs.TPD } });
      }
    }

    result.push(TRS);
  }
  // TPD
  else if (selected.TPD) {
    result.push({ TPS: { ...needs.TPD } });
  }

  return result;
};

const getNeedsComplex = (
  selected: SelectedCoverTypes,
  needs: NeedDict
): QuoteRequestNeeds => {
  let result: QuoteRequestNeeds = [];

  // TRM
  if (selected.TRM) {
    let TRM = {
      TRM: {
        ...needs.TRM,
        linkedNeeds: [...needs.TRM.linkedNeeds],
        TPE: undefined,
        TRE: undefined,
      },
    };

    // TRE
    if (selected.TRE && needs.TRM.TRE)
      TRM.TRM.linkedNeeds.push({ TRE: { ...needs.TRM.TRE } });

    // TPE
    if (selected.TPE && needs.TRM.TPE)
      TRM.TRM.linkedNeeds.push({ TPE: { ...needs.TRM.TPE } });

    result.push(TRM);
  }

  // TRS
  if (selected.TRS) {
    let TRS = {
      TRS: {
        ...needs.TRS,
        linkedNeeds: [...needs.TRS.linkedNeeds],
        TPR: undefined,
      },
    };

    // TPR
    if (selected.TPR && needs.TRS.TPR)
      TRS.TRS.linkedNeeds.push({ TPR: { ...needs.TRS.TPR } });

    result.push(TRS);
  }

  // TPD
  if (selected.TPD) result.push({ TPS: { ...needs.TPD } });

  return result;
};
