import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import FormRow from '../../common/FormRow';
import OccupationSelector from '../../steps/YourDetails/OccupationSelector';
import 'react-quill/dist/quill.snow.css';
import { salutations } from 'src/models/dictionaries/salutation.model';
import { CustomCard } from 'src/common';
import MultiOccupationSelector from 'src/steps/YourDetails/MultiOccupationSelector';
import { employmentStatuses } from 'src/models/dictionaries/employmentStatus.model';
import { MenuItem, Select } from '@mui/material';

const YourDetailsConfigFrom: FunctionComponent = () => {
  const { register, setValue, getValues } = useFormContext();

  return (
    <>
      <CustomCard>
        <FormRow
          label={<label>Default Salutation:</label>}
          input={
            <div>
              <select
                defaultValue="visible"
                {...register('yourDetails.salutationVisible')}
              >
                <option value="hidden">Hidden</option>
                <option value="visible">Visible</option>
              </select>
              <select
                defaultValue="required"
                style={{ marginLeft: 15 }}
                {...register('yourDetails.salutationRequired')}
              >
                <option value="optional">Optional</option>
                <option value="required">Required</option>
              </select>
              <select
                style={{ marginLeft: 15 }}
                {...register('yourDetails.defaultSalutation')}
              >
                {salutations.map((s) => (
                  <option key={s} value={s}>
                    {s}
                  </option>
                ))}
              </select>
            </div>
          }
        />

        <FormRow
          label={<label>Employment Status: </label>}
          input={
            <div>
              <select {...register('yourDetails.employmentStatusVisible')}>
                <option value="hidden">Hidden</option>
                <option value="visible">Visible</option>
              </select>
              <select
                style={{ marginLeft: 15 }}
                {...register('yourDetails.employmentStatusRequired')}
              >
                <option value="optional">Optional</option>
                <option value="required">Required</option>
              </select>
            </div>
          }
        />

        <Controller
          name="yourDetails.defaultEmploymentStatus"
          render={({ field: { onChange, value } }) => (
            <FormRow
              label={<label>Default Employment Status</label>}
              input={
                <Select
                  size="small"
                  sx={{ width: 400 }}
                  defaultValue={getValues(
                    'yourDetails.defaultEmploymentStatus'
                  )}
                  value={value}
                  onChange={(v) => {
                    onChange(v.target.value || undefined);
                    setValue(
                      'yourDetails.defaultEmploymentStatus',
                      v.target.value || undefined
                    );
                  }}
                >
                  {employmentStatuses.map((s) => (
                    <MenuItem key={s.key} value={s.key}>
                      {s.title}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          )}
        />

        <FormRow
          label={<label>Post Code: </label>}
          input={
            <div>
              <select {...register('yourDetails.postCodeVisible')}>
                <option value="hidden">Hidden</option>
                <option value="visible">Visible</option>
              </select>
              <select
                style={{ marginLeft: 15 }}
                {...register('yourDetails.postCodeRequired')}
              >
                <option value="optional">Optional</option>
                <option value="required">Required</option>
              </select>
            </div>
          }
        />

        <Controller
          name="yourDetails.defaultOccupationId"
          render={({ field: { onChange, value } }) => (
            <OccupationSelector
              value={
                value
                  ? {
                      id: value,
                      description: getValues(
                        'yourDetails.defaultOccupationName'
                      ),
                    }
                  : null
              }
              onChange={(v) => {
                onChange(v?.id || undefined);
                setValue(
                  'yourDetails.defaultOccupationName',
                  v?.description || undefined
                );
              }}
              forAdminPanel
            />
          )}
        />

        <Controller
          name="yourDetails.occupationFilter"
          render={({ field: { onChange, value } }) => (
            <MultiOccupationSelector
              value={getValues('outputSettings.occupationFilter') || []}
              onChange={(v) => {
                onChange(v || undefined);
                setValue('outputSettings.occupationFilter', v);
              }}
            />
          )}
        />

        <FormRow
          label={<label>Annual Income: </label>}
          input={
            <select {...register('yourDetails.annualIncomeVisible')}>
              <option value="hidden">Hidden</option>
              <option value="visible">Visible</option>
            </select>
          }
        />

        <FormRow
          label={<label>Free Text Field: </label>}
          input={
            <div>
              <select {...register('yourDetails.freeTextFieldVisible')}>
                <option value="hidden">Hidden</option>
                <option value="visible">Visible</option>
              </select>
              <select
                style={{ marginLeft: 15 }}
                {...register('yourDetails.freeTextFieldRequired')}
              >
                <option value="optional">Optional</option>
                <option value="required">Required</option>
              </select>
              <label style={{ marginLeft: 15, marginRight: 5 }}>Label: </label>
              <input
                style={{ width: 275 }}
                {...register('yourDetails.freeTextFieldLabel')}
              />
            </div>
          }
        />

        <FormRow
          label={<label>Email: </label>}
          input={
            <select {...register('yourDetails.emailVisible')}>
              <option value="visible">Visible</option>
              <option value="hidden">Hidden</option>
            </select>
          }
        />
        <FormRow
          label={<label>Phone: </label>}
          input={
            <select {...register('yourDetails.phoneVisible')}>
              <option value="visible">Visible</option>
              <option value="hidden">Hidden</option>
            </select>
          }
        />

        <FormRow
          label={<label>Use Test Client Values:</label>}
          input={
            <input type="checkbox" {...register('yourDetails.useTestData')} />
          }
        />
      </CustomCard>

      <CustomCard title="Checkbox 1">
        <FormRow
          label={<label>visibility: </label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <select {...register('yourDetails.customerConsentVisible')}>
                <option value="visible">Visible</option>
                <option value="hidden">Hidden</option>
              </select>
            </div>
          }
        />

        <FormRow
          label={<label>label: </label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input
                style={{ width: 500 }}
                {...register('yourDetails.customerConsentLabel')}
              />
            </div>
          }
        />

        <FormRow
          label={<label>text: </label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <Controller
                name="yourDetails.customerConsentText"
                render={({ field: { onChange, value } }) => (
                  <ReactQuill
                    onChange={onChange}
                    value={value}
                    style={{ width: 500, height: 200 }}
                    theme="snow"
                  />
                )}
              />
            </div>
          }
        />
      </CustomCard>

      <CustomCard title="Checkbox 2">
        <FormRow
          label={<label>visibility: </label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <select {...register('yourDetails.PDSAndTMDVisible')}>
                <option value="visible">Visible</option>
                <option value="hidden">Hidden</option>
              </select>
            </div>
          }
        />

        <FormRow
          label={<label>label: </label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input
                style={{ width: 500 }}
                {...register('yourDetails.PDSAndTMDLabel')}
              />
            </div>
          }
        />

        <FormRow
          label={<label>text: </label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <Controller
                name="yourDetails.PDSAndTMDText"
                render={({ field: { onChange, value } }) => (
                  <ReactQuill
                    onChange={onChange}
                    value={value}
                    style={{ width: 500, height: 200 }}
                    theme="snow"
                  />
                )}
              />
            </div>
          }
        />
      </CustomCard>
    </>
  );
};

export default YourDetailsConfigFrom;
