import { startCase } from 'lodash';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormRow from '../../common/FormRow';
import { frequencies } from '../../models/quote-response.model';
import { CustomCard } from 'src/common';
import { MenuItem, Select } from '@mui/material';

const DisplayOptionsForm: FunctionComponent = () => {
  const { register } = useFormContext();
  return (
    <>
      <CustomCard title="Display Options">
        {['logo', 'name', 'premium', 'featureScore', 'combinedScore'].map(
          (c) => (
            <FormRow
              key={c}
              label={<label>{startCase(c)}: </label>}
              input={
                <div style={{ marginBottom: 20 }}>
                  <select {...register('display.' + c)}>
                    <option value="visible">Visible</option>
                    <option value="hidden">Hidden</option>
                  </select>
                </div>
              }
            />
          )
        )}

        <FormRow
          label={<label>Score Format:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <select {...register('display.scoreFormat')}>
                {['Raw', '5', '15', 'Star'].map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>
            </div>
          }
        />

        <FormRow
          label={<label>Randomize:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input type="checkbox" {...register('display.randomize')} />
            </div>
          }
        />

        <FormRow
          label={<label>Main Logo URL:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input
                style={{ width: 500 }}
                {...register('display.mainLogoUrl')}
              />
            </div>
          }
        />

        <FormRow
          label={<label>Application Color:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input
                type="color"
                // style={{ width: 500 }}
                {...register('display.appColor')}
              />
            </div>
          }
        />

        <FormRow
          label={<label>Secondary Application Color:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input type="color" {...register('display.secondaryAppColor')} />
            </div>
          }
        />

        <FormRow
          label={<label>Premium Color:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input type="color" {...register('display.premiumColor')} />
            </div>
          }
        />

        <FormRow
          label={<label>Frequencies:</label>}
          input={
            <div>
              <Controller
                name={'display.frequencies'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    multiple
                    size="small"
                    sx={{ width: 400 }}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  >
                    {frequencies.map((c) => (
                      <MenuItem key={c} value={c}>
                        {c}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
          }
        />

        <FormRow
          label={<label>Life Insurance Calculator URL:</label>}
          input={
            <div style={{ marginBottom: 20, marginTop: 20 }}>
              <input
                style={{ width: 500 }}
                {...register('display.lifeInsuranceCalculatorUrl')}
              />
            </div>
          }
        />
      </CustomCard>

      <CustomCard title="Actions">
        {[
          ['actions', 'Actions Column'],
          ['actionPds', 'PDS'],
          ['actionTMD', 'TMD'],
          ['actionStrengthAndLimitations', 'Strength and Limitations'],
          ['actionInclustions', 'Inclusions'],
        ].map(([field, text]) => (
          <FormRow
            key={field}
            label={<label>{text}: </label>}
            input={
              <div style={{ marginBottom: 20 }}>
                <select {...register('display.' + field)}>
                  <option value="visible">Visible</option>
                  <option value="hidden">Hidden</option>
                </select>
              </div>
            }
          />
        ))}
      </CustomCard>

      <CustomCard title="Banner">
        <FormRow
          label={<label>Show Banner:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input type="checkbox" {...register('display.showBanner')} />
            </div>
          }
        />
        <FormRow
          label={<label>Book Appointment URL:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input
                style={{ width: 500 }}
                {...register('display.bookAppointmentUrl')}
              />
            </div>
          }
        />
        <FormRow
          label={<label>Call Us Phone Nubmer:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <input
                style={{ width: 500 }}
                {...register('display.callUsTel')}
              />
            </div>
          }
        />
      </CustomCard>

      <CustomCard title="Report">
        <FormRow
          label={<label>Show Report Button:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <select
                {...register('display.reportButton')}
                defaultValue="hidden"
              >
                <option value="visible">Visible</option>
                <option value="hidden">Hidden</option>
              </select>
            </div>
          }
        />
      </CustomCard>

      <CustomCard title="Footer">
        <FormRow
          label={<label>Show Footer Note 2:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <select
                {...register('display.footerNote2')}
                defaultValue="hidden"
              >
                <option value="visible">Visible</option>
                <option value="hidden">Hidden</option>
              </select>
            </div>
          }
        />
      </CustomCard>
    </>
  );
};

export default DisplayOptionsForm;
