import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  IconButton,
} from '@mui/material';
import { FunctionComponent, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  CampaignOptionsDropdown,
  SuppliersResponce,
} from 'src/models/campaign-options.model';
import DeleteIcon from '@mui/icons-material/Delete';

const CampaignOptionsSelector: FunctionComponent<{
  includedSuppliers: string[];
  supplierCampaignOptions: SuppliersResponce[];
  removeCampaign: (id: string, supplierCode: string) => void;
  updateSuppliers: (prevSupplierCode: string, supplierCode: string) => void;
  id: string;
  hiddenSuppliers: string[];
  selectedSupplier: string;
  selectedCampaignCodes: CampaignOptionsDropdown[];
}> = ({
  includedSuppliers,
  supplierCampaignOptions,
  removeCampaign,
  id,
  updateSuppliers,
  hiddenSuppliers,
  selectedSupplier,
  selectedCampaignCodes,
}) => {
  const [supplier, setSupplier] = useState<string>(selectedSupplier);
  const { getValues, setValue } = useFormContext();
  const [options, setOptions] = useState<CampaignOptionsDropdown[]>([]);
  const [selectedCodes, setSelectedCodes] = useState<
    { id: string; label: string }[]
  >(selectedCampaignCodes);
  const [hiddenSuppliersState, setHiddenSuppliersState] = useState<string[]>([
    ...hiddenSuppliers,
  ]);

  useEffect(() => {
    let prevHiddenSuppliers = [
      ...hiddenSuppliers.filter((s) => s !== supplier),
    ];
    setHiddenSuppliersState([...prevHiddenSuppliers]);
  }, [supplier, hiddenSuppliers]);

  const deleteSupplierFromConfig = () => {
    const values = getValues().outputSettings.campaignOptions;
    delete values[supplier];
    setValue('outputSettings.campaignOptions', values);
  };

  const handleSupplierChange = (e: any) => {
    if (supplier === e.target.value) return;
    supplier && deleteSupplierFromConfig();
    setSelectedCodes([]);
    setSupplier(e.target.value);
    updateSuppliers(supplier, e.target.value);
  };

  const handleCampaignChange = (e: any, v: CampaignOptionsDropdown[]) => {
    setSelectedCodes(v);
    setValue(`outputSettings.campaignOptions[${supplier}]`, v);
  };

  useEffect(() => {
    setOptions(
      [...supplierCampaignOptions]
        .filter((c) => c.code === supplier)
        .flatMap((c) => c.campaignOptions)
        .map((c) => ({ id: c.code, label: c.name }))
    );
  }, [supplier, supplierCampaignOptions]);

  return (
    <Grid container>
      <Grid item>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="supplier-label">Supplier</InputLabel>
          <Select
            labelId="supplier-label"
            id="supplier"
            value={supplier}
            label="Supplier"
            onChange={handleSupplierChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {includedSuppliers
              .filter((s) => !hiddenSuppliersState.includes(s))
              .map((s) => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl sx={{ m: 1, minWidth: 350 }}>
          <Autocomplete
            multiple
            options={options}
            fullWidth
            onChange={(_e, v) => handleCampaignChange(_e, v)}
            //defaultValue={selectedCodes}
            value={selectedCodes}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} placeholder="Campaigns" fullWidth />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item style={{ alignSelf: 'center' }}>
        <IconButton
          onClick={() => {
            removeCampaign(id, supplier);
            deleteSupplierFromConfig();
          }}
          color="primary"
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CampaignOptionsSelector;
