import { Box, DialogTitle, IconButton } from '@mui/material';
import { FC, ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';

export const DialogTitleWithCloseButton: FC<{
  closeModal: () => void;
  children: ReactNode;
}> = ({ closeModal, children }) => (
  <DialogTitle>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>{children}</Box>

      <IconButton onClick={closeModal}>
        <CloseIcon />
      </IconButton>
    </Box>
  </DialogTitle>
);
