import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { FunctionComponent, useState } from 'react';
import { useFrequencyState } from 'src/steps/LiveQuotes/FrequencySelector';
import { useConfigContext } from '../../../configs/config.context';
import { useAsyncError } from '../../../hooks/useAsyncError';
import { ClientInput } from '../../../models/client-input.model';
import { QuoteRequest } from '../../../models/quote-request.model';
import { Portfolio } from '../../../models/quote-response.model';
import usePorfolioCodesState from '../../../steps/LiveQuotes/stores/portfolioCodesState';
import { reportRequest } from '../api/report.request';

function getFileExtension(mimeType: string) {
  switch (mimeType) {
    case 'application/pdf':
      return 'pdf';
    default:
      return 'pdf';
  }
}

export const ReportButton: FunctionComponent<{
  quoteRequest?: QuoteRequest;
  portfolios: Portfolio[];
  client: ClientInput;
}> = ({ quoteRequest, portfolios, client }) => {
  const [loading, setLoading] = useState(false);
  const [portfolioCodes] = usePorfolioCodesState();
  const [freq] = useFrequencyState();

  // Configs
  const visibility = useConfigContext()?.display?.reportButton;
  const subReportType = useConfigContext()?.report?.subReportType || '';

  // Code
  const code = new URLSearchParams(window.location.search).get('code');
  const throwError = useAsyncError();

  function download() {
    if (!quoteRequest) return;

    setLoading(true);
    const request = reportRequest(
      quoteRequest,
      portfolioCodes || portfolios.map((p) => p.code),
      client,
      code,
      freq,
      subReportType
    );
    axios(request)
      .then((response) => {
        const contentType = response.headers['content-type'];
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        const extension = getFileExtension(contentType);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Report.' + extension;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';

        document.body.appendChild(link);
        link.click();
      })
      .catch(throwError)
      .finally(() => setLoading(false));
  }

  return visibility === 'visible' ? (
    <LoadingButton
      variant="contained"
      startIcon={<DownloadIcon />}
      onClick={download}
      loadingPosition="start"
      loading={loading}
      disabled={portfolios.length === 0}
    >
      Download Report
    </LoadingButton>
  ) : (
    <div />
  );
};
