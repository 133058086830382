import produce from 'immer';
import { ClientInput } from 'src/models/client-input.model';
import { Application } from '..';

export const prepopulateApplication = (
  application: Application,
  client: ClientInput,
  insurer: string
): Application => {
  return produce(application, (draft) => {
    draft.pages.forEach((p) =>
      p.tags.forEach((tag) => {
        if (tag.linkedFieldName)
          if (tag.linkedFieldName === 'insurer') tag.value = insurer;
          else if (tag.linkedFieldName === 'smoker')
            tag.value = //bug happens because of wrong type in client model (smoker is boolean but is saved as string)
              client.smoker && client.smoker.toString() === true.toString() //Fixed with toString()
                ? 'Yes' //This bug is also edge case of typescript not being able to infer types
                : 'No';
          else
            tag.value =
              client[tag.linkedFieldName as keyof ClientInput]?.toString() ||
              '';
      })
    );
  });
};
