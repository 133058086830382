import { Box, Breadcrumbs, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { axiosAdmin } from '../configs/axios.config';
import { adminCredsState } from '../models/admin/credentials.model';
import { configsRequest } from '../services/config.service';
import AddConfigButton from './Config/AddConfigButton';
import ConfigsTable from './ConfigsTable';

const Configs: FunctionComponent = () => {
  const [creds] = useRecoilState(adminCredsState);
  const [configs, setConfigs] = useState<{ code: string }[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchConfigs = useCallback(() => {
    setLoading(true);
    axiosAdmin(configsRequest(creds))
      .then((d) => setConfigs(d.data.map((c: string) => ({ code: c }))))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [creds]);

  useEffect(() => {
    fetchConfigs();
  }, [fetchConfigs]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Breadcrumbs>
          <Typography color="text.primary">Configs</Typography>
        </Breadcrumbs>

        <AddConfigButton fetchConfigs={fetchConfigs} />
      </Box>

      <ConfigsTable
        configs={configs}
        loading={loading}
        fetchConfigs={fetchConfigs}
      />
    </>
  );
};

export default Configs;
