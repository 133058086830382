import produce from 'immer';
import { FunctionComponent, useState } from 'react';
import { Application, Page, Tag } from '..';
import { TagsRender } from './TagsRender';
import useAxios from 'axios-hooks';
import { submitApplication } from '../api/submitApplication';
import { useBreakpoint } from 'src/hooks';
import { Box, Button, List, ListItemButton } from '@mui/material';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UploadIcon from '@mui/icons-material/Upload';

export const ApplicationView: FunctionComponent<{
  code: string;
  data: Application;
}> = ({ code, data }) => {
  const [application, setApplication] = useState(data);
  const [currentPage, setCurrentPage] = useState(0);
  const { md } = useBreakpoint();

  const setTag = (pageIndex: number) => (id: string) => (tag: Tag) => {
    setApplication(
      produce(application, (draft) => {
        const tagIndex = draft.pages[pageIndex].tags.findIndex(
          (t) => t.id === id
        );
        draft.pages[pageIndex].tags[tagIndex] = tag;
      })
    );
  };

  return (
    <Box display="flex">
      {md && (
        <Box style={{ borderRight: 'solid 1px lightGray' }}>
          <PageSelector
            pages={application.pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
      )}
      <Box flexGrow={1} style={{ padding: 10 }}>
        <PageRender
          code={code}
          setTag={setTag}
          application={application}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
    </Box>
  );
};

const PageSelector: FunctionComponent<{
  pages: Page[];
  currentPage: number;
  setCurrentPage: (index: number) => void;
}> = ({ pages, setCurrentPage, currentPage }) => (
  <List sx={{ minWidth: 150, mt: -1 }}>
    {pages.map((p) => (
      <ListItemButton
        key={p.id}
        selected={p.id === pages[currentPage].id}
        onClick={() => {
          const index = pages.findIndex((pp) => p.id === pp.id);
          setCurrentPage(index);
        }}
        sx={{ borderBottom: 'solid 1px lightGray' }}
      >
        {p.name}
      </ListItemButton>
    ))}
  </List>
);

const PageRender: FunctionComponent<{
  setTag: (pageIndex: number) => (id: string) => (tag: Tag) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  application: Application;
  code: string;
}> = ({ setTag, currentPage, setCurrentPage, application, code }) => {
  const [{ loading }, execute] = useAxios<any>({});
  const handleSubmit = () => {
    execute(submitApplication(undefined, code, application))
      .then(() => toast.success('Your Application Has Been Submitted!'))
      .catch(() =>
        toast.error(
          'Your Application Has Not Been Submitted! Please contact website administrator.'
        )
      );
  };

  return (
    <>
      <div style={{ minHeight: 500 }}>
        <TagsRender
          tags={application.pages[currentPage].tags}
          setTag={setTag(currentPage)}
        />
      </div>
      <Box display="flex" justifyContent="space-between">
        {currentPage === 0 ? (
          <div />
        ) : (
          <Button
            size="small"
            startIcon={<ArrowBackIcon />}
            onClick={() => setCurrentPage((c) => c - 1)}
            variant="outlined"
          >
            Previous
          </Button>
        )}
        {currentPage === application.pages.length - 1 ? (
          <LoadingButton
            size="small"
            onClick={handleSubmit}
            loading={loading}
            endIcon={<UploadIcon />}
            variant="contained"
            loadingPosition="end"
          >
            Submit
          </LoadingButton>
        ) : (
          <Button
            size="small"
            onClick={() => setCurrentPage((c) => c + 1)}
            endIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        )}
      </Box>
    </>
  );
};
