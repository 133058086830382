import { useCodeContext } from 'src/hooks/useCodeContext';
import axios from 'axios';
import { useEffect } from 'react';
import { ClientInput } from 'src/models/client-input.model';
import { NeedDict } from 'src/models/needs/need.model';
import { SelectedCoverTypes } from 'src/models/selected-cover-types.model';
import { yourResultsVisitedNotificationRequest } from '../api/yourResultsVisitedNotification.request';
import { useConfigContext } from 'src/configs/config.context';
import { useFrequencyState } from 'src/steps/LiveQuotes/FrequencySelector';

export const useYourResultsVisitedNotification = (
  client: ClientInput,
  selected: SelectedCoverTypes,
  needs: NeedDict
) => {
  const code = useCodeContext();
  const config = useConfigContext();
  const [freq] = useFrequencyState();

  useEffect(() => {
    axios(
      yourResultsVisitedNotificationRequest(
        code,
        client,
        selected,
        needs,
        config,
        freq
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
