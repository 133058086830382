import { ClientInput } from 'src/models/client-input.model';
import { SelectedCoverTypes } from 'src/models/selected-cover-types.model';
import { genderNames } from 'src/models/needs/cht.model';
import { ownerNames } from 'src/models/dictionaries/owner.model';
import { rolloverNames } from 'src/models/dictionaries/rollover.model';
import { structureNames } from 'src/models/dictionaries/structure.model';
import { babyCareNames } from 'src/models/dictionaries/babyCare.model';
import { occupationTypeNames } from 'src/models/dictionaries/occupationType.model';
import { premiumWaiverNames } from 'src/models/dictionaries/premiumWaiver.model';
import { NeedDict } from 'src/models/needs/need.model';
import { traumaReinstatementNames } from 'src/models/dictionaries/traumaReinstatement.model';
import { priorityNames } from 'src/models/dictionaries/priority.model';
import { agreedValueNames } from 'src/models/dictionaries/agreedValue.model';
import { accidentBenefitNames } from 'src/models/dictionaries/accidentBenefit.model';
import { increaseClaimBenefitNames } from 'src/models/dictionaries/increaseClaimBenefit.model';
import { waitingPeriodNames } from 'src/models/dictionaries/waitingPeriod.model';
import { benefitPeriodNames } from 'src/models/dictionaries/benefitPeriod.model';
import { Config } from 'src/models/config.model';
import { doubleTPDNames } from 'src/models/dictionaries/doubleTPD.model';
import { lifeBuyBackNames } from 'src/models/dictionaries/lifeBuyBack.model';
import { doubleTraumaNames } from 'src/models/dictionaries/doubleTrauma.model';

export const getClientData = (
  client: ClientInput,
  selected: SelectedCoverTypes,
  needs: NeedDict,
  config: Config
): any => {
  const data: any = {
    client: {
      ...client,
      smoker:
        client.smoker !== undefined && //Had to add this check as radio button returns string 'false'
        client.smoker.toString().toLowerCase() === 'true' //instead of boolean false
          ? 'Yes'
          : 'No',
      gender: genderNames(client.gender),
      freeTextFieldLabel:
        config.yourDetails?.freeTextFieldVisible === 'visible'
          ? config.yourDetails?.freeTextFieldLabel || 'Free Text Field'
          : '',
    },
  };
  if (selected.TRM)
    data['TRM'] = {
      cover: needs.TRM.sumInsured,
      ownership: ownerNames(needs.TRM.owner),
      payByRollover: rolloverNames(needs.TRM.rollover),
      structure: structureNames(needs.TRM.structure),
      premiumWaiver: premiumWaiverNames(needs.TRM.premiumWaiver),
    };
  if (selected.TRM && selected.TRE && needs.TRM.TRE)
    //Trauma Extension
    data['TRE'] = {
      cover: needs.TRM.TRE.sumInsured,
      structure: structureNames(needs.TRM.TRE.structure),
      premiumWaiver: premiumWaiverNames(needs.TRM.TRE.premiumWaiver),
      lifeBuyBack: lifeBuyBackNames(needs.TRM.TRE.lifeBuyBack),
      doubleTrauma: doubleTraumaNames(needs.TRM.TRE.doubleTrauma),
      babyCare: babyCareNames(needs.TRM.TRE.babyCare),
      priority: priorityNames(needs.TRM.TRE.priority),
      traumaReinstatement: traumaReinstatementNames(
        needs.TRM.TRE.traumaReinstatement
      ),
    };
  if (selected.TRM && selected.TPE && needs.TRM.TPE)
    //TPD Extension
    data['TPE'] = {
      cover: needs.TRM.TPE.sumInsured,
      ownership: ownerNames(needs.TRM.owner),
      payByRollover: rolloverNames(needs.TRM.rollover),
      structure: structureNames(needs.TRM.structure),
      occupationType: occupationTypeNames(needs.TRM.TPE.occupationType),
      premiumWaiver: premiumWaiverNames(needs.TRM.premiumWaiver),
      lifeBuyBack: lifeBuyBackNames(needs.TRM.TPE.lifeBuyBack),
      doubleTPD: doubleTPDNames(needs.TRM.TPE.doubleTPD),
    };
  if (selected.TPD)
    data['TPD'] = {
      cover: needs.TPD.sumInsured,
      ownership: ownerNames(needs.TPD.owner),
      payByRollover: rolloverNames(needs.TPD.rollover),
      structure: structureNames(needs.TPD.structure),
      occupationType: occupationTypeNames(needs.TPD.occupationType),
      premiumWaiver: premiumWaiverNames(needs.TPD.premiumWaiver),
    };
  if (selected.TRS)
    data['TRA'] = {
      cover: needs.TRS.sumInsured,
      structure: structureNames(needs.TRS.structure),
      reinstatement: traumaReinstatementNames(needs.TRS.traumaReinstatement),
      premiumWaiver: premiumWaiverNames(needs.TRS.premiumWaiver),
      babyCare: babyCareNames(needs.TRS.babyCare),
      priority: priorityNames(needs.TRS.priority),
    };
  if (selected.TRS && selected.TPR && needs.TRS.TPR)
    //Trauma TPD Extension
    data['TPR'] = {
      cover: needs.TRS.TPR.sumInsured,
      ownership: ownerNames(needs.TRS.TPR.owner),
      payByRollover: rolloverNames(needs.TRS.TPR.rollover),
      structure: structureNames(needs.TRS.TPR.structure),
      occupationType: occupationTypeNames(needs.TRS.TPR.occupationType),
      premiumWaiver: premiumWaiverNames(needs.TRS.TPR.premiumWaiver),
    };
  if (selected.INC)
    data['INC'] = {
      monthlyCover: needs.INC.monthlyBenefit,
      ownership: ownerNames(needs.INC.owner),
      payByRollover: rolloverNames(needs.INC.rollover),
      structure: structureNames(needs.INC.structure),
      agreedValue: agreedValueNames(needs.INC.agreedValue),
      accidentBenefit: accidentBenefitNames(needs.INC.accidentBenefit),
      increaseClaimBenefit: increaseClaimBenefitNames(
        needs.INC.increaseClaimBenefit
      ),
      waitingPeriod: waitingPeriodNames(needs.INC.waitingPeriod),
      benefitPeriod: benefitPeriodNames(needs.INC.benefitPeriod),
      priority: priorityNames(needs.INC.priority),
    };
  if (selected.BUS)
    data['BUS'] = {
      monthlyCover: needs.BUS.monthlyBenefit,
      structure: structureNames(needs.BUS.structure),
      waitingPeriod: waitingPeriodNames(needs.BUS.waitingPeriod),
      benefitPeriod: benefitPeriodNames(needs.BUS.benefitPeriod),
    };
  if (selected.NES)
    data['NES'] = {
      sumInsured: needs.NES.sumInsured,
      structure: structureNames(needs.NES.structure),
    };
  if (selected.CHT)
    data['CHTs'] = needs.CHT.children.map((c) => ({
      sumInsured: c.sumInsured,
      dateOfBirth: c.dateOfBirth,
      age: c.age,
      gender: genderNames(c.gender),
    }));
  return data;
};
