import _ from 'lodash';
import { FunctionComponent } from 'react';
import { babyCareNames } from '../../../models/dictionaries/babyCare.model';
import { doubleTPDNames } from '../../../models/dictionaries/doubleTPD.model';
import { doubleTraumaNames } from '../../../models/dictionaries/doubleTrauma.model';
import { lifeBuyBackNames } from '../../../models/dictionaries/lifeBuyBack.model';
import { occupationTypeNames } from '../../../models/dictionaries/occupationType.model';
import { ownerNames } from '../../../models/dictionaries/owner.model';
import { premiumWaiverNames } from '../../../models/dictionaries/premiumWaiver.model';
import { priorityNames } from '../../../models/dictionaries/priority.model';
import { rolloverNames } from '../../../models/dictionaries/rollover.model';
import { structureNames } from '../../../models/dictionaries/structure.model';
import { traumaReinstatementNames } from '../../../models/dictionaries/traumaReinstatement.model';
import { treLifeBuyBackNames } from '../../../models/dictionaries/treLifeBuyBack.model';
import { TPE, TRE, TRM } from '../../../models/needs/trm.model';
import { currencyFormat } from '../../../utils/common';
import { InclusionsBreakdownTable } from './InclusionsBreakdownTable';

export const TRMBreakdown: FunctionComponent<{ trm: TRM }> = ({ trm }) => {
  const tpe: TPE | undefined = (
    trm.linkedNeeds?.find((n) => _.has(n, 'TPE')) as any
  )?.TPE;
  const tre: TRE | undefined = (
    trm.linkedNeeds?.find((n) => _.has(n, 'TRE')) as any
  )?.TRE;

  return (
    <div style={{ marginBottom: 30 }}>
      <InclusionsBreakdownTable
        title="Life"
        data={[
          ['Sum Insured', currencyFormat(trm.sumInsured)],
          ['Structure', structureNames(trm.structure)],
          ['Ownership', ownerNames(trm.owner)],
          ['Pay by Rollover', rolloverNames(trm.rollover)],
          ['Premium Waiver', premiumWaiverNames(trm.premiumWaiver)],
        ]}
      />

      {tpe && (
        <InclusionsBreakdownTable
          title="TPD Extension"
          data={[
            ['Sum Insured', currencyFormat(tpe.sumInsured)],
            ['Structure', structureNames(tpe.structure)],
            ['Occupation type', occupationTypeNames(tpe.occupationType)],
            ['Ownership', ownerNames(tpe.owner)],
            ['Pay by Rollover', rolloverNames(tpe.rollover)],
            ['Life Buy Back', lifeBuyBackNames(tpe.lifeBuyBack)],
            ['Double TPD', doubleTPDNames(tpe.doubleTPD)],
            ['Premium Waiver', premiumWaiverNames(tpe.premiumWaiver)],
          ]}
        />
      )}

      {tre && (
        <InclusionsBreakdownTable
          title="Trauma Extension"
          data={[
            ['Sum Insured', currencyFormat(tre.sumInsured)],
            ['Structure', structureNames(tre.structure)],
            ['Life Buy Back', treLifeBuyBackNames(tre.lifeBuyBack)],
            ['Double Trauma', doubleTraumaNames(tre.doubleTrauma)],
            [
              'Reinstatement',
              traumaReinstatementNames(tre.traumaReinstatement),
            ],
            ['Premium Waiver', premiumWaiverNames(tre.premiumWaiver)],
            ['Baby Care', babyCareNames(tre.babyCare)],
            ['Trauma Features', priorityNames(tre.priority)],
          ]}
        />
      )}
    </div>
  );
};
