import { Alert, AlertTitle } from '@mui/material';
import { FunctionComponent } from 'react';

const getErrorMessage = (error: Error | undefined) => {
  if (error?.message === 'Network Error') return 'Website API Error!';
  if (error?.message.includes('555')) return 'Omnilife API Error!';
  if (error?.message.includes('556')) return 'Database Error!';
  else return 'Website Error!';
};

export const ErrorFallback: FunctionComponent<{ error: Error }> = ({
  error,
}) => {
  // console.log(JSON.stringify(error));

  return (
    <Alert severity="error">
      <AlertTitle>{getErrorMessage(error)}</AlertTitle>
      Please contact website administrator!
    </Alert>
  );
};
