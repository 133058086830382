import { FunctionComponent } from 'react';
import { useGTMEvent } from '../../hooks/gtmHooks';
import useScrollToTop from '../../hooks/useScrollToTop';
import { ClientInput } from '../../models/client-input.model';
import { NeedDict } from '../../models/needs/need.model';
import { Portfolio } from '../../models/quote-response.model';
import { SelectedCoverTypes } from '../../models/selected-cover-types.model';
import NavButtons from '../NavButtons';
import QuoteCards from './QuoteCards';
import QuoteTable from './QuoteTable';
import { ReportButton } from 'src/features/report';
import { useYourResultsVisitedNotification } from 'src/features/notifications';
import { useQuoteRequest } from './hooks/useQuoteRequest.hook';
import { FrequencySelector, useFrequencyState } from './FrequencySelector';
import { useMediaQuery, useTheme } from '@mui/material';

export const LiveQuotes: FunctionComponent<{
  coverTypes: SelectedCoverTypes;
  needs: NeedDict;
  client: ClientInput;
}> = ({ coverTypes, needs, client }) => {
  useGTMEvent('LISTPage4LiveQuotes', { page: 'Live Quotes' });

  useScrollToTop();

  const { loading, portfolios, quoteRequest } = useQuoteRequest(
    coverTypes,
    needs,
    client
  );

  useYourResultsVisitedNotification(client, coverTypes, needs);

  return (
    <>
      <FrequencySelector />

      <Quotes
        selected={coverTypes}
        portfolios={portfolios}
        loading={loading}
        client={client}
      />

      <NavButtons
        button={
          <ReportButton
            quoteRequest={quoteRequest}
            portfolios={portfolios}
            client={client}
          />
        }
      />
    </>
  );
};

const Quotes: FunctionComponent<{
  portfolios: Portfolio[];
  loading: boolean;
  selected: SelectedCoverTypes;
  client: ClientInput;
}> = ({ portfolios, loading, selected, client }) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const [freq] = useFrequencyState();

  return xs ? (
    <QuoteCards
      selected={selected}
      portfolios={portfolios}
      loading={loading}
      freq={freq}
      client={client}
    />
  ) : (
    <QuoteTable
      selected={selected}
      portfolios={portfolios}
      loading={loading}
      freq={freq}
      client={client}
    />
  );
};
