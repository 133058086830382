import { Dialog, Divider, DialogContent, Typography } from '@mui/material';
import { DialogTitleWithCloseButton } from 'src/common';
import InclusionsBreakdown from './InclusionsBreakdown';
import { Occupation } from './Occupation';
import { FunctionComponent } from 'react';
import { Portfolio } from 'src/models/quote-response.model';
import { SelectedCoverTypes } from 'src/models/selected-cover-types.model';
import { useBreakpoint } from 'src/hooks';

const InclusionsDialog: FunctionComponent<{
  selected: SelectedCoverTypes;
  portfolio: Portfolio;
  open: boolean;
  onClose: () => void;
}> = ({ portfolio, selected, open, onClose }) => {
  const { xs } = useBreakpoint();
  return (
    <Dialog
      fullWidth
      fullScreen={xs}
      open={open}
      maxWidth="md"
      onClose={onClose}
    >
      <DialogTitleWithCloseButton closeModal={onClose}>
        {portfolio.supplier.name} Details
      </DialogTitleWithCloseButton>

      <Divider sx={{ m: 0 }} />

      <DialogContent>
        <Occupation portfolio={portfolio} selected={selected} />

        {portfolio.campaign && (
          <>
            <Typography variant="h6" fontWeight="bold">
              Campaign
            </Typography>
            <Typography variant="body2" mt={1} mb={2}>
              {portfolio.campaign}
            </Typography>
          </>
        )}

        <InclusionsBreakdown products={portfolio.products} />
      </DialogContent>
    </Dialog>
  );
};

export default InclusionsDialog;
