import { FC } from 'react';
import { GenericAmountInput } from './GenericAmountInput';

export const AmountInput: FC<{
  value?: string | number | undefined;
  onChange?: (value: string | number | undefined) => void;
  prefix?: string;
  disableGroupSeparators?: boolean;
  maxLength?: number;
}> = ({
  value,
  onChange,
  prefix,
  maxLength,
  disableGroupSeparators = false,
}) => (
  <GenericAmountInput
    prefix={prefix}
    value={typeof value === 'string' ? Number(value) : value}
    onChange={(v) => onChange && onChange(v)}
    disableGroupSeparators={disableGroupSeparators}
    maxLength={maxLength}
  />
);
