import { FunctionComponent } from 'react';
import { useConfigContext } from '../configs/config.context';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Box, Button, Card, useMediaQuery, useTheme } from '@mui/material';

const Banner: FunctionComponent = () => {
  const config = useConfigContext();
  const theme = useTheme(); //Mui bug, need to call this to colors work
  useMediaQuery(theme.breakpoints.down('sm')); //only happens on mobile view.

  if (!config.display.showBanner) return null;

  return (
    <Box display="flex" justifyContent="center" m={4} mb={1}>
      <Card
        variant="outlined"
        sx={{
          p: 2,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          rowGap: 1,
          columnGap: 2,
        }}
      >
        <Box textAlign="center">
          Get in touch with our consultants if you have any questions{' '}
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          rowGap={1}
          columnGap={2}
        >
          <Button
            variant="outlined"
            startIcon={<PhoneEnabledIcon />}
            href={'tel:' + config.display.callUsTel}
            sx={{ textTransform: 'none' }}
          >
            Call Us - {config.display.callUsTel}
          </Button>

          <Button
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
            href={config.display.bookAppointmentUrl}
            component="a"
            sx={{ ':hover': { color: 'white' } }}
          >
            Book Appointment
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default Banner;
