import { Chip } from '@mui/material';
import { FunctionComponent } from 'react';

export const TagId: FunctionComponent<{ id: string }> = ({ id }) => (
  <Chip
    size="small"
    variant="filled"
    label={<b>{id.split('-')[0].toUpperCase()}</b>}
    sx={{ px: 1 }}
  />
);
