import { useState } from 'react';
import { FC } from 'react';
import { GenericAmountInput } from './GenericAmountInput';

const CoverAmountInput: FC<{
  defaultValue?: number;
  onValueChange: (cover: number, valid: boolean) => void;
  maxLength?: number;
  dontValidate?: boolean;
  manualValid?: boolean;
  emptyDefaultValue?: boolean;
}> = ({
  onValueChange,
  dontValidate,
  manualValid = true,
  defaultValue,
  maxLength = 7,
  emptyDefaultValue = false,
}) => {
  const [valid, setValid] = useState<boolean>(
    dontValidate || (defaultValue !== undefined && defaultValue > 0)
  );
  const value =
    defaultValue === 0 && emptyDefaultValue ? undefined : defaultValue;

  return (
    <GenericAmountInput
      error={!manualValid || !valid}
      maxLength={maxLength}
      value={value}
      onChange={(v) => {
        const locValid: boolean = dontValidate || v > 0;
        setValid(locValid);
        onValueChange(v, locValid);
      }}
    />
  );
};

export default CoverAmountInput;
