import { FunctionComponent, useState } from 'react';
import { useConfigContext } from 'src/configs/config.context';
import { ClientInput } from 'src/models/client-input.model';
import { Portfolio } from 'src/models/quote-response.model';
import { SelectedCoverTypes } from 'src/models/selected-cover-types.model';
import StrengthsAndLimitations from './StrengthsAndLimitations';
import Inclustions from './Inclusions/Inclusions';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ApplyButton } from './ApplyButton';
import { useBreakpoint } from 'src/hooks';

export const Actions: FunctionComponent<{
  code: string;
  client: ClientInput;
  portfolio: Portfolio;
  selected: SelectedCoverTypes;
}> = ({ code, client, portfolio, selected }) => {
  // Config
  const config = useConfigContext();

  // Layout
  const { xs } = useBreakpoint();

  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const closeMenu = () => setAnchorEl(null);

  return (
    <ButtonGroup variant="contained">
      {config.application?.enabled && (
        <ApplyButton
          code={code}
          client={client}
          insurer={portfolio.supplier.name}
          closeMenu={closeMenu}
        />
      )}

      {config.display.actions === 'visible' && (
        <>
          <Button onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
            <MoreHorizIcon />
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
          >
            {config.display.actionPds === 'visible' && (
              <MenuItem
                onClick={() => {
                  closeMenu();
                  window.open(portfolio.links.pds, '_blank')?.focus();
                }}
              >
                {xs ? 'Download' : 'View'} PDS
              </MenuItem>
            )}

            {config.display.actionTMD === 'visible' && (
              <MenuItem
                onClick={() => {
                  closeMenu();
                  window.open(portfolio.links.tmd, '_blank')?.focus();
                }}
              >
                {xs ? 'Download' : 'View'} TMD
              </MenuItem>
            )}

            {config.display.actionStrengthAndLimitations === 'visible' && (
              <StrengthsAndLimitations
                portfolio={portfolio}
                closeMenu={closeMenu}
              />
            )}

            {config.display.actionInclustions === 'visible' && (
              <Inclustions
                selected={selected}
                portfolio={portfolio}
                closeMenu={closeMenu}
              />
            )}
          </Menu>
        </>
      )}
    </ButtonGroup>
  );
};
