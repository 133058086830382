import { FC } from 'react';
import { useCodeContext } from 'src/hooks/useCodeContext';
import { ClientInput } from 'src/models/client-input.model';
import { useConfigContext } from '../../configs/config.context';
import { Frequency, Portfolio } from '../../models/quote-response.model';
import { SelectedCoverTypes } from '../../models/selected-cover-types.model';
import usePorfolioCodesState from './stores/portfolioCodesState';
import Premium from './Premium';
import { Score } from './Score';
import { Actions } from './Actions';
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRowsProp,
} from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { Image } from 'src/common/Image';
import SupplierName from './SupplierName';
import './styles.css';

const QuoteTable: FC<{
  portfolios: Portfolio[];
  loading: boolean;
  freq: Frequency;
  selected: SelectedCoverTypes;
  client: ClientInput;
}> = ({ portfolios, loading, freq, selected, client }) => {
  const config = useConfigContext();
  const code = useCodeContext();

  const [, setPortfiolioCodes] = usePorfolioCodesState();

  const rows: GridRowsProp = portfolios.map((p) => ({
    id: p.code,
    logo: 'https://' + p.supplier?.logo,
    name: p,
    premium: p,
    featureScore: p.score.feature,
    combinedScore: p.score.combined,
    actions: p,
  }));

  // Column Definitions
  let columns: GridColDef[] = [];

  if (config.display.logo === 'visible')
    columns.push({
      field: 'logo',
      headerName: 'Logo',
      headerClassName: 'whitesmoke-background',
      renderHeader: HeaderRenderer,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 140,
      flex: 1,
      renderCell: (params) => (
        <Image src={params.value} alt={params.row.name} />
      ),
    });

  if (config.display.name === 'visible')
    columns.push({
      field: 'name',
      headerName: 'Name',
      headerClassName: 'whitesmoke-background',
      renderHeader: HeaderRenderer,
      maxWidth: 140,
      flex: 1,
      sortComparator: (a, b) =>
        a.supplier.name
          .toLowerCase()
          .localeCompare(b.supplier.name.toLowerCase()),
      renderCell: ({ value }) => (
        <SupplierName selected={selected} portfolio={value} />
      ),
    });

  if (config.display.premium === 'visible')
    columns.push({
      field: 'premium',
      headerClassName: 'whitesmoke-background',
      renderHeader: () => (
        <Typography variant="body2" fontWeight="bold">
          Premium
          <br />
          Estimate
        </Typography>
      ),
      align: 'right',
      headerAlign: 'center',
      minWidth: 110,
      flex: 0,
      sortComparator: (a, b) =>
        a.premiumTotal.premium[freq] - b.premiumTotal.premium[freq],
      cellClassName: 'whitesmoke-background',
      renderCell: ({ value }) => (
        <Box mr={2}>
          <Premium portfolio={value} freq={freq} />
        </Box>
      ),
    });

  if (config.display.featureScore === 'visible')
    columns.push({
      field: 'featureScore',
      headerName: 'Feature Score',
      headerClassName: 'whitesmoke-background',
      renderHeader: HeaderRenderer,
      headerAlign: 'center',
      align: 'center',
      width: 160,
      flex: 1,
      sortComparator: (a, b) => a.raw - b.raw,
      renderCell: ({ value }) => <Score score={value} />,
    });

  if (config.display.combinedScore === 'visible')
    columns.push({
      field: 'combinedScore',
      headerName: 'Combined Score',
      headerClassName: 'whitesmoke-background',
      renderHeader: HeaderRenderer,
      headerAlign: 'center',
      align: 'center',
      width: 170,
      flex: 1,
      sortComparator: (a, b) => a.raw - b.raw,
      renderCell: ({ value }) => <Score score={value} />,
    });

  if (config.application?.enabled || config.display.actions === 'visible')
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      headerClassName: 'whitesmoke-background',
      renderHeader: HeaderRenderer,
      headerAlign: 'center',
      align: 'center',
      flex: 0,
      minWidth: 120,
      sortable: false,
      renderCell: ({ value }) => (
        <Actions
          selected={selected}
          portfolio={value}
          client={client}
          code={code}
        />
      ),
    });

  return (
    <Box
      sx={{
        '& .whitesmoke-background': {
          backgroundColor: 'whitesmoke',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        rowHeight={70}
        components={{
          NoRowsOverlay: () => (
            <Typography textAlign="center" fontWeight="bold" mt={4}>
              No Insurers meet your required benefits. Please alter your
              requirements under Your Options.
            </Typography>
          ),
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'premium', sort: 'asc' }],
          },
        }}
        disableColumnMenu
        isCellEditable={() => false}
        isRowSelectable={() => false}
        disableSelectionOnClick
        disableColumnSelector
        hideFooter
        autoHeight
        sortingOrder={['asc', 'desc']}
        onSortModelChange={(model) => {
          const sortedRows = rows.map((row) => ({
            code: row.id,
            name: row.name,
            premium: row.premium,
            featureScore: row.featureScore,
            combinedScore: row.combinedScore,
          }));

          sortedRows.sort((a, b) => {
            const sortModel = model[0];
            const field = sortModel.field;
            const sort = sortModel.sort;

            if (field === 'name') {
              return sort === 'asc'
                ? a[field].supplier.name.localeCompare(b[field].supplier.name)
                : b[field].supplier.name.localeCompare(a[field].supplier.name);
            } else if (field === 'premium') {
              return sort === 'asc'
                ? a[field].premiumTotal.premium[freq] -
                    b[field].premiumTotal.premium[freq]
                : b[field].premiumTotal.premium[freq] -
                    a[field].premiumTotal.premium[freq];
            } else if (field === 'featureScore' || field === 'combinedScore') {
              return sort === 'asc'
                ? a[field].raw - b[field].raw
                : b[field].raw - a[field].raw;
            }

            return 0;
          });

          setPortfiolioCodes(sortedRows.map((r) => r.code));
        }}
      />
    </Box>
  );
};

export default QuoteTable;

const HeaderRenderer: FC<GridColumnHeaderParams<any, any, any>> = ({
  colDef: { headerName },
}) => <b>{headerName}</b>;
