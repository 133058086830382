import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormRow from '../../common/FormRow';
import { OmnilifeApiCredsModel } from './omnilife-api-creds.model';
import { useRecoilState } from 'recoil';
import { adminCredsState } from '../../models/admin/credentials.model';
import { axiosAdmin } from '../../configs/axios.config';
import {
  restoreOmnilifeApiCreds,
  setOmnilifeApiCreds,
} from './omnilife-api-creds.service';
import { useTestConnection } from './useTestConnection';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  Link,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SyncIcon from '@mui/icons-material/Sync';
import { LoadingButton } from '@mui/lab';
import { CustomCard } from 'src/common';

export const OmnilifeApiCredsForm: FunctionComponent<{
  defaultValues: OmnilifeApiCredsModel;
  fetchAppSettings: () => void;
  code: string;
}> = ({ defaultValues, fetchAppSettings, code }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<OmnilifeApiCredsModel>({ defaultValues: defaultValues });

  const [submitting, setSubmitting] = useState(false);
  const [creds] = useRecoilState(adminCredsState);

  const history = useHistory();

  const onSubmit = (data: OmnilifeApiCredsModel) => {
    setSubmitting(true);
    axiosAdmin(setOmnilifeApiCreds(creds, code, data)).finally(() => {
      setSubmitting(false);
      toast.success('App Settings Changed Successfully');
    });
  };

  const [testConnection, testingConnection] = useTestConnection();

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        alignItems="center"
      >
        <Breadcrumbs>
          <Link
            underline="hover"
            color="inherit"
            onClick={() => history.push('/configs')}
          >
            Configs
          </Link>
          <Link
            underline="hover"
            color="inherit"
            onClick={() => history.push('/config/' + code)}
          >
            {code}
          </Link>
          <Typography color="text.primary">Api Credentials</Typography>
        </Breadcrumbs>

        <Box display="flex" gap={1}>
          <Button
            color="error"
            variant="contained"
            onClick={() => setDialogOpen(true)}
          >
            Restore Default
          </Button>

          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            fullWidth
            maxWidth="xs"
          >
            <DialogContent>
              <Alert severity="error">
                Do you want to restore default App Settings?
              </Alert>

              <Box display="flex" justifyContent="space-between" mt={3}>
                <Button variant="outlined" onClick={() => setDialogOpen(false)}>
                  No
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  onClick={() =>
                    axiosAdmin(restoreOmnilifeApiCreds(creds, code))
                      .then(() => fetchAppSettings())
                      .finally(() => {
                        setDialogOpen(false);
                        toast.success('App Settings Changed Successfully');
                      })
                  }
                >
                  Yes
                </Button>
              </Box>
            </DialogContent>
          </Dialog>

          <LoadingButton
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
            loading={submitting}
            loadingPosition="start"
            size="small"
          >
            Save
          </LoadingButton>
        </Box>
      </Box>

      <CustomCard
        title="Omnilife Api Credentials"
        extra={
          <LoadingButton
            startIcon={<SyncIcon />}
            onClick={() => testConnection(code, getValues())}
            loading={testingConnection}
            loadingPosition="start"
            variant="outlined"
            sx={{ mt: 0.25 }}
          >
            Test Connection
          </LoadingButton>
        }
      >
        <FormRow
          label={<label>URL:</label>}
          input={
            <div>
              <input
                {...register('url', { required: true })}
                style={{ width: 300 }}
              />
              <div style={{ height: 20 }}>
                {errors.url && <p style={{ color: 'red' }}>Required</p>}
              </div>
            </div>
          }
        />

        <FormRow
          label={<label>Username:</label>}
          input={
            <div>
              <input
                {...register('username', { required: true })}
                style={{ width: 300 }}
              />
              <div style={{ height: 20 }}>
                {errors.username && <p style={{ color: 'red' }}>Required</p>}
              </div>
            </div>
          }
        />

        <FormRow
          label={<label>Password:</label>}
          input={
            <div>
              <input
                {...register('password', { required: true })}
                style={{ width: 300 }}
              />
              <div style={{ height: 20 }}>
                {errors.password && <p style={{ color: 'red' }}>Required</p>}
              </div>
            </div>
          }
        />
      </CustomCard>
    </form>
  );
};
