export function waitingPeriodNames(
  key: 14 | 30 | 60 | 90 | 180 | 365 | 730 | undefined
): string {
  if (key === undefined) return '';
  else
    return {
      14: '14 days',
      30: '30 days',
      60: '60 days',
      90: '90 days',
      180: '180 days',
      365: '1 year',
      730: '2 years',
    }[key];
}
