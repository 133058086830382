import { Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { Portfolio } from '../../models/quote-response.model';
import InclusionsDialog from './Inclusions/InclusionsDialog';
import { SelectedCoverTypes } from 'src/models/selected-cover-types.model';
import { useBreakpoint } from 'src/hooks';

const SupplierName: FunctionComponent<{
  portfolio: Portfolio;
  selected: SelectedCoverTypes;
}> = ({ portfolio, selected }) => {
  const [showModal, setShowModal] = useState(false);
  const { xs } = useBreakpoint();

  return (
    <>
      <div onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
        {!xs ? (
          <>
            <Typography>{portfolio.supplier.name}</Typography>
            <Typography variant="caption">
              {portfolio.campaign && 'Campaign applied'}
            </Typography>
          </>
        ) : (
          <>
            <Typography display="inline">{portfolio.supplier.name}</Typography>
            <Typography display="inline" variant="caption">
              {portfolio.campaign ? ' (Campaign applied)' : ''}
            </Typography>
          </>
        )}
      </div>

      <InclusionsDialog
        selected={selected}
        portfolio={portfolio}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default SupplierName;
