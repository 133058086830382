import { AxiosRequestConfig } from 'axios';
import { getReportRequest } from 'src/features/report';
import { ClientInput } from 'src/models/client-input.model';
import { Config } from 'src/models/config.model';
import { NeedDict } from 'src/models/needs/need.model';
import { createQuoteRequest } from 'src/models/quote-request.model';
import { Frequency } from 'src/models/quote-response.model';
import { SelectedCoverTypes } from 'src/models/selected-cover-types.model';
import { getClientData } from '../utils/getClientData';
import { sanitizeUrl } from '@braintree/sanitize-url';

export const yourResultsVisitedNotificationRequest = (
  code: string,
  client: ClientInput,
  selected: SelectedCoverTypes,
  needs: NeedDict,
  config: Config,
  freq: Frequency
): AxiosRequestConfig => {
  client.email = sanitizeUrl(client.email || '');
  client.phone = sanitizeUrl(client.phone || '');
  client.firstName = sanitizeUrl(client.firstName || '');
  client.lastName = sanitizeUrl(client.lastName || '');

  const clientData = getClientData(client, selected, needs, config);

  const quoteRequest = createQuoteRequest(client, selected, needs, config);

  const reportRequest = getReportRequest(
    quoteRequest,
    client,
    freq,
    config.report?.subReportType || ''
  );

  const data = {
    clientData,
    quoteRequest,
    reportRequest,
  };

  return {
    method: 'POST',
    url: `/notifications/${code}/YourResultsVisited`,
    data: data,
  };
};
