import { Frequency } from '../../../models/quote-response.model';
import { ClientInput } from '../../../models/client-input.model';
import { Salutation } from '../../../models/dictionaries/salutation.model';
import { QuoteRequest } from '../../../models/quote-request.model';

export interface ReportRequest extends QuoteRequest {
  reportOptions: {
    fileFormat: 'PDF';
    reportType: string;
    subReportType: string;
    ScoringType: number;
    featureScores: boolean;
    combinedScores: boolean;
    superNonSuper: boolean;
    supplierLogo: boolean;
    featureDifferenceOnly: boolean;
    runFeatureSimilaritiesDifferences: boolean;
  };
  reportDistribution?: {
    clientEmail: string;
    adviserEmail: string;
  };
  supplementaryClientDetails: {
    phoneNumber?: string;
    email?: string;
    salutation?: Salutation;
    clientFirstName?: string;
    clientLastName?: string;
    postcode?: string;
  };
}

export function getReportRequest(
  quoteRequest: QuoteRequest,
  client: ClientInput,
  freq: Frequency,
  subReportType: string
): ReportRequest {
  let request: ReportRequest = {
    ...quoteRequest,
    clients: [
      { ...quoteRequest.clients[0], employmentStatus: client.employmentStatus },
    ],
    reportOptions: {
      fileFormat: 'PDF',
      reportType: 'LiveQuotesReport',
      subReportType: subReportType,
      ScoringType: 100,
      featureScores: true,
      combinedScores: true,
      superNonSuper: true,
      supplierLogo: true,
      featureDifferenceOnly: true,
      runFeatureSimilaritiesDifferences: true,
    },
    supplementaryClientDetails: {
      phoneNumber: client.phone,
      email: client.email,
      salutation: client.salutation,
      clientFirstName: client.firstName,
      clientLastName: client.lastName,
      postcode: client.postCode,
    },
  };

  request.clients[0].dateOfBirth = client.dateOfBirth;
  request.settings.frequency = freq;

  return request;
}
