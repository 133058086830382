import { Box, Checkbox, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import CoverAmountInput from 'src/common/CoverAmountInput';
import { CustomRadio } from 'src/common/CustomRadio';
import { CustomSelect } from 'src/common/CustomSelect';
import { useBreakpoint } from 'src/hooks';
import {
  employmentStatuses,
  getCorrespondingOccupation,
  isNonEmployed,
} from 'src/models/dictionaries/employmentStatus.model';
import { salutations } from 'src/models/dictionaries/salutation.model';
import { states } from 'src/models/dictionaries/states.model';
import FormRow from '../../common/FormRow';
import Label from '../../common/Label';
import { useConfigContext } from '../../configs/config.context';
import { useGTMEvent } from '../../hooks/gtmHooks';
import useScrollToTop from '../../hooks/useScrollToTop';
import { ClientInput, clientInputValid } from '../../models/client-input.model';
import {
  validEmail,
  validPhoneNumber,
  validName,
  valid,
  annualIncomeValid,
} from '../../utils/validators';
import NavButtons from '../NavButtons';
import CustomDatePicker from './CustomDatePicker';
import OccupationSelector from './OccupationSelector';

export interface YourDetailsProps {
  client: ClientInput;
  setClient: (callback: (value: ClientInput) => ClientInput) => void;
  incomeProtectionSelected: boolean;
  incomeProtectionMonthlyCover: number;
}

const YourDetails: FunctionComponent<YourDetailsProps> = ({
  client,
  setClient,
  incomeProtectionSelected,
  incomeProtectionMonthlyCover,
}) => {
  useGTMEvent('LISTPage3Details', { page: 'Your Details' });

  useScrollToTop();
  const { md } = useBreakpoint();
  const consentWidth = md ? 380 : 300;
  const mainConfig = useConfigContext();
  const config = mainConfig.yourDetails;

  const isAnnualIncomeValid = annualIncomeValid(
    config?.annualIncomeVisible,
    incomeProtectionSelected,
    client.annualIncome,
    incomeProtectionMonthlyCover
  );

  return (
    <>
      <FormRow
        label={<Label>Full Name</Label>}
        input={
          <Box display="flex" gap={1} flexWrap="wrap">
            {(config?.salutationVisible === 'visible' ||
              config?.salutationVisible === undefined) && (
              <CustomSelect
                value={client.salutation}
                onChange={(v) => setClient((c) => ({ ...c, salutation: v }))}
                options={salutations}
                error={
                  (config?.salutationRequired === 'required' ||
                    config?.salutationRequired === undefined) &&
                  !valid(client.salutation)
                }
                width={80}
              />
            )}

            <Box display="flex" gap={1} flexWrap="wrap">
              <TextField
                error={!validName(client.firstName)}
                value={client.firstName || ''}
                onChange={(v) =>
                  setClient((c) => ({
                    ...c,
                    firstName: v.target.value,
                  }))
                }
                placeholder="First Name"
                sx={{ width: 170 }}
              />

              <TextField
                error={!validName(client.lastName)}
                value={client.lastName || ''}
                onChange={(v) =>
                  setClient((c) => ({
                    ...c,
                    lastName: v.target.value,
                  }))
                }
                placeholder="Last Name"
                sx={{ width: 170 }}
              />
            </Box>
          </Box>
        }
      />

      {config?.phoneVisible !== 'hidden' && (
        <FormRow
          label={<Label>Phone</Label>}
          input={
            <TextField
              error={!validPhoneNumber(client.phone)}
              sx={{ width: 300 }}
              value={client.phone || ''}
              onChange={(v) =>
                setClient((c) => ({ ...c, phone: v.target.value }))
              }
            />
          }
        />
      )}

      {config?.emailVisible !== 'hidden' && (
        <FormRow
          label={<Label>Email</Label>}
          input={
            <TextField
              error={!validEmail(client.email)}
              sx={{ width: 300 }}
              value={client.email || ''}
              onChange={(v) =>
                setClient((c) => ({ ...c, email: v.target.value }))
              }
            />
          }
        />
      )}

      <FormRow
        label={<Label>Date of Birth</Label>}
        input={<CustomDatePicker client={client} setClient={setClient} />}
      />

      <FormRow
        label={<Label>Gender</Label>}
        input={
          <CustomRadio
            error={!valid(client.gender)}
            value={client.gender}
            onChange={(v) => setClient((c) => ({ ...c, gender: v }))}
            options={[
              { label: 'Male', value: 'M' },
              { label: 'Female', value: 'F' },
            ]}
          />
        }
      />

      <FormRow
        label={<Label>Smoker</Label>}
        input={
          <CustomRadio
            error={!valid(client.smoker)}
            value={client.smoker}
            onChange={(v) => setClient((c) => ({ ...c, smoker: v }))}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        }
      />

      <FormRow
        label={<Label>State</Label>}
        input={
          <Box minWidth={200}>
            <CustomSelect
              value={client.state}
              onChange={(v) => setClient((c) => ({ ...c, state: v }))}
              options={states}
              width={100}
            />
          </Box>
        }
      />

      {config?.postCodeVisible === 'visible' && (
        <FormRow
          label={
            <Label optional={config?.postCodeRequired !== 'required'}>
              Post Code
            </Label>
          }
          input={
            <TextField
              error={
                config?.postCodeRequired === 'required' &&
                !valid(client.postCode)
              }
              value={client.postCode || ''}
              onChange={(e) =>
                setClient((c) => ({
                  ...c,
                  postCode: e.target.value
                    .replace(/\D/g, '')
                    .substring(0, 4)
                    .toString(),
                }))
              }
            />
          }
        />
      )}

      {config?.employmentStatusVisible === 'visible' && (
        <FormRow
          label={
            <Label optional={config?.employmentStatusRequired !== 'required'}>
              Employment Status
            </Label>
          }
          input={
            <CustomSelect
              error={
                config?.employmentStatusRequired === 'required' &&
                !valid(client.employmentStatus)
              }
              width={300}
              value={client.employmentStatus}
              onChange={(v) =>
                setClient((c) => ({
                  ...c,
                  employmentStatus: v,
                  occupationId: isNonEmployed(v)
                    ? getCorrespondingOccupation(v)
                    : c.occupationId,
                }))
              }
              options={employmentStatuses.map((s) => ({
                label: s.title,
                value: s.key,
              }))}
            />
          }
        />
      )}

      {!isNonEmployed(client.employmentStatus) && (
        <OccupationSelector
          value={
            client.occupationId
              ? {
                  id: client.occupationId,
                  description: client.occupationName || '',
                }
              : null
          }
          onChange={(value) => {
            setClient((c) => ({
              ...c,
              occupationId: value?.id || undefined,
              occupationName: value?.description || undefined,
            }));
          }}
          occupationFilter={config?.occupationFilter}
        />
      )}

      {config?.annualIncomeVisible === 'visible' && (
        <>
          <FormRow
            label={<Label optional>Annual Income</Label>}
            input={
              <CoverAmountInput
                manualValid={isAnnualIncomeValid}
                dontValidate
                defaultValue={client.annualIncome}
                emptyDefaultValue
                onValueChange={(n) =>
                  setClient((c) => ({
                    ...c,
                    annualIncome: n,
                  }))
                }
              />
            }
          />
          {!isAnnualIncomeValid && client.annualIncome !== 0 && (
            <div style={{ color: 'red', marginLeft: 10 }}>
              Your Annual Income is not sufficient to receive your requested
              Income Protection Monthly Cover. Please decrease the Monthly Cover
              on the previous screen.
            </div>
          )}
        </>
      )}

      {config?.freeTextFieldVisible === 'visible' && (
        <>
          <FormRow
            label={
              <Label optional={config?.freeTextFieldRequired !== 'required'}>
                {config.freeTextFieldLabel}
              </Label>
            }
            input={
              <TextField
                sx={{ width: 300 }}
                error={
                  config.freeTextFieldRequired === 'required' &&
                  !client.freeTextField
                }
                value={client.freeTextField || ''}
                onChange={(e) =>
                  setClient((c) => ({ ...c, freeTextField: e.target.value }))
                }
              />
            }
          />
        </>
      )}

      {config?.customerConsentVisible === 'visible' && (
        <FormRow
          label={<Label>{config?.customerConsentLabel}</Label>}
          input={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              mt={0.25}
              mb={-2}
            >
              <Checkbox
                sx={{ ml: -1.5 }}
                checked={client.agreedToCustomerConsent}
                onChange={(e) =>
                  setClient((c) => ({
                    ...c,
                    agreedToCustomerConsent: e.target.checked,
                  }))
                }
              />

              <div
                style={{ width: consentWidth, marginTop: 7 }}
                dangerouslySetInnerHTML={{
                  __html: config?.customerConsentText || '',
                }}
              ></div>
            </Box>
          }
        />
      )}

      {config?.PDSAndTMDVisible === 'visible' && (
        <FormRow
          label={<Label>{config?.PDSAndTMDLabel}</Label>}
          input={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              mt={0.25}
              mb={-2}
            >
              <Checkbox
                sx={{ ml: -1.5 }}
                checked={client.agreedToPDSandTMD}
                onChange={(e) =>
                  setClient((c) => ({
                    ...c,
                    agreedToPDSandTMD: e.target.checked,
                  }))
                }
              />

              <div
                style={{ width: consentWidth, marginTop: 7 }}
                dangerouslySetInnerHTML={{
                  __html: config?.PDSAndTMDText || '',
                }}
              ></div>
            </Box>
          }
        />
      )}

      <NavButtons
        nextEnabled={
          clientInputValid(client, mainConfig) && isAnnualIncomeValid
        }
        label="Get Results"
      />
    </>
  );
};

export default YourDetails;
