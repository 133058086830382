export function treLifeBuyBackNames(
  key: 'L' | 'F' | 'N' | '1' | '3' | undefined
): string {
  if (key === undefined) return '';
  else
    return {
      L: 'Exclude if possible',
      F: 'Best available',
      N: 'Exclude',
      '1': '1 year',
      '3': '3 years',
    }[key];
}
