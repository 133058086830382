import { useBreakpoint } from 'src/hooks';
import { FunctionComponent, useState } from 'react';
import { Portfolio } from '../../models/quote-response.model';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  MenuItem,
  Typography,
} from '@mui/material';
import { DialogTitleWithCloseButton } from 'src/common';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const StrengthsAndLimitations: FunctionComponent<{
  portfolio: Portfolio;
  closeMenu: () => void;
}> = ({ portfolio, closeMenu }) => {
  const [showModal, setShowModal] = useState(false);
  const { xs } = useBreakpoint();

  return (
    <>
      <MenuItem
        onClick={() => {
          closeMenu();
          !showModal && setShowModal(true);
        }}
      >
        View Strengths/Limitations
      </MenuItem>

      <Dialog
        fullWidth
        fullScreen={xs}
        open={showModal}
        maxWidth="md"
        onClose={() => setShowModal(false)}
      >
        <DialogTitleWithCloseButton closeModal={() => setShowModal(false)}>
          Strengths and Limitations of {xs && <br />}
          <b>{portfolio.name}</b>
        </DialogTitleWithCloseButton>

        <Divider sx={{ m: 0 }} />

        <DialogContent>
          <Typography variant="h5" mb={1}>
            Strengths
          </Typography>
          {portfolio.topFeatures.map((f) => (
            <Box key={f.headingName} mb={2} display="flex" gap={1}>
              <CheckCircleIcon
                color="success"
                sx={{ mt: 0.2 }}
                fontSize="small"
              />{' '}
              <Box>
                <Typography variant="body1">{f.headingName}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {f.summaryText}
                </Typography>
              </Box>
            </Box>
          ))}

          <Typography variant="h5" my={1}>
            Limitations
          </Typography>
          {portfolio.bottomFeatures.map((f) => (
            <Box key={f.headingName} mb={2} display="flex" gap={1}>
              <CancelIcon color="error" sx={{ mt: 0.2 }} fontSize="small" />{' '}
              <Box>
                <Typography variant="body1">{f.headingName}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {f.summaryText}
                </Typography>
              </Box>
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StrengthsAndLimitations;
