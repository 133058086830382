import _ from 'lodash';
import { Config, TRSConfig } from './../config.model';
import { getTPD, TPD } from './tpd.model';

/** Trauma */
export interface TRS {
  sumInsured: number;
  structure: 'S' | 'L' | '70' | undefined;
  traumaReinstatement: 'X' | 'Y' | 'N' | undefined;
  premiumWaiver: 'N' | 'I' | 'Y' | undefined;
  babyCare: 'X' | 'I' | 'Y' | 'N' | undefined;
  priority: 'C' | 'B' | 'I' | undefined;
  linkedNeeds: { TPR: TPR }[];
  TPR?: TPR;
}

/** Trauma Extension with TPD */
export interface TPR extends TPD {}

export function getTRS(config: Config, allowTPR: boolean): TRS {
  return {
    sumInsured: config.quotes.TRS.sumInsured.default,
    structure: config.quotes.TRS.structure.default,
    traumaReinstatement: config.quotes.TRS.traumaReinstatement.default,
    premiumWaiver: config.quotes.TRS.premiumWaiver.default,
    babyCare: config.quotes.TRS.babyCare.default,
    priority: config.quotes.TRS.priority.default,
    linkedNeeds: [],
    TPR:
      config.covers.complexMode && allowTPR
        ? getTPR(getTPD(config.quotes.TPR))
        : undefined,
  };
}

export function getTPR(TPD: TPD): TPR {
  return {
    ...TPD,
  };
}

export function TRSValid(trs: TRS, config: TRSConfig) {
  return (
    trs.sumInsured > 0 &&
    _.toPairs(trs)
      .filter(([k]) => k !== 'TPR')
      .every(
        ([k, v]) =>
          _.get(config, k + '.visibility') === 'hidden' || v !== undefined
      )
  );
}
