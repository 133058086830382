import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { bordersStyle, headerStyle } from 'src/utils';

export const InclusionsBreakdownTable: FunctionComponent<{
  title: string;
  data: [string, any][];
}> = ({ data, title }) => {
  const dataSource = data.map(([f, v]) => ({ field: f, value: v }));

  return (
    <Table size="small">
      <TableHead>
        <TableRow sx={[bordersStyle, headerStyle]}>
          <TableCell>
            <Typography fontWeight="bold" variant="body2">
              {title}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {dataSource.map((row) => (
          <TableRow key={row.field}>
            <TableCell width="50%" sx={bordersStyle}>
              <Typography variant="body2">{row.field}</Typography>
            </TableCell>
            <TableCell sx={bordersStyle} width="50%">
              <Typography variant="body2">{row.value}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
