import { getTRM, TRM, TRMValid } from './trm.model';
import { getTPD, TPD, TPDValid } from './tpd.model';
import { getTRS, TRS, TRSValid } from './trs.model';
import { getINC, INC, INCValid } from './inc.model';
import { getNES, NES, NESValid } from './nes.model';
import { BUS, BUSValid, getBUS } from './bus.model';
import { CHT, CHTValid, getCHT } from './cht.model';
import { SelectedCoverTypes } from '../selected-cover-types.model';
import { Config } from '../config.model';

export type Need = TRM | TPD | TRS | INC | BUS | NES | CHT;
export type NeedString = 'TRM' | 'TPD' | 'TRS' | 'INC' | 'BUS' | 'NES' | 'CHT';

export interface NeedDict {
  TRM: TRM;
  TPD: TPD;
  TRS: TRS;
  INC: INC;
  BUS: BUS;
  NES: NES;
  CHT: CHT;
}

export function getNeedsDict(config: Config): NeedDict {
  return {
    TRM: getTRM(config),
    TPD: getTPD(config.quotes.TPD),
    TRS: getTRS(config, true),
    INC: getINC(config.quotes.INC),
    BUS: getBUS(config.quotes.BUS),
    NES: getNES(config.quotes.NES),
    CHT: getCHT(config.quotes.CHT),
  };
}

export function valid(
  selected: SelectedCoverTypes,
  needs: NeedDict,
  config: Config
): boolean {
  return (
    (!selected.TRM || TRMValid(needs.TRM, config.quotes.TRM)) &&
    (!selected.TPD || TPDValid(needs.TPD, config.quotes.TPD)) &&
    (!selected.TRS || TRSValid(needs.TRS, config.quotes.TRS)) &&
    (!selected.INC || INCValid(needs.INC, config.quotes.INC)) &&
    (!selected.BUS || BUSValid(needs.BUS, config.quotes.BUS)) &&
    (!selected.NES || NESValid(needs.NES, config.quotes.NES)) &&
    (!selected.CHT || CHTValid(needs.CHT, config.quotes.CHT))
  );
}
