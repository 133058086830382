import { AxiosRequestConfig } from 'axios';
import { Credentials } from '../../models/admin/credentials.model';
import { OmnilifeApiCredsModel } from './omnilife-api-creds.model';

export function getOmnilifeApiCreds(
  creds: Credentials | undefined,
  code: string
): AxiosRequestConfig {
  return {
    url: `/configs/${code}/creds`,
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
  };
}

export function setOmnilifeApiCreds(
  creds: Credentials | undefined,
  code: string,
  data: OmnilifeApiCredsModel | null
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: `/configs/${code}/creds`,
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
    data: data,
  };
}

export function restoreOmnilifeApiCreds(
  creds: Credentials | undefined,
  code: string
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: `/configs/${code}/creds/reset`,
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
  };
}

export function testOmnilifeApiCreds(
  creds: Credentials | undefined,
  code: string,
  data: OmnilifeApiCredsModel
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: `/configs/${code}/creds/test`,
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
    data: data,
  };
}
