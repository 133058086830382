import { FunctionComponent } from 'react';
import { occupationTypeNames } from '../../../models/dictionaries/occupationType.model';
import { ownerNames } from '../../../models/dictionaries/owner.model';
import { premiumWaiverNames } from '../../../models/dictionaries/premiumWaiver.model';
import { rolloverNames } from '../../../models/dictionaries/rollover.model';
import { structureNames } from '../../../models/dictionaries/structure.model';
import { TPD } from '../../../models/needs/tpd.model';
import { currencyFormat } from '../../../utils/common';
import { InclusionsBreakdownTable } from './InclusionsBreakdownTable';

export const TPSBreakdown: FunctionComponent<{ tpd: TPD }> = ({ tpd }) => {
  return (
    <div style={{ marginBottom: 30 }}>
      <InclusionsBreakdownTable
        title="Total and Permanent Disability"
        data={[
          ['Sum Insured', currencyFormat(tpd.sumInsured)],
          ['Ownership', ownerNames(tpd.owner)],
          ['Structure', structureNames(tpd.structure)],
          ['Occupation type', occupationTypeNames(tpd.occupationType)],
          ['Premium Waiver', premiumWaiverNames(tpd.premiumWaiver)],
          ['Pay by Rollover', rolloverNames(tpd.rollover)],
        ]}
      />
    </div>
  );
};
