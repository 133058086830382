import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { doubleTPDNames } from 'src/models/dictionaries/doubleTPD.model';
import { lifeBuyBackNames } from 'src/models/dictionaries/lifeBuyBack.model';
import CoverAmountInput from '../../common/CoverAmountInput';
import FormRow from '../../common/FormRow';
import InfoTooltip from '../../common/InfoTooltip';
import Label from '../../common/Label';
import { useConfigContext } from '../../configs/config.context';
import { occupationTypeNames } from '../../models/dictionaries/occupationType.model';
import { ownerNames } from '../../models/dictionaries/owner.model';
import { premiumWaiverNames } from '../../models/dictionaries/premiumWaiver.model';
import { rolloverNames } from '../../models/dictionaries/rollover.model';
import { structureNames } from '../../models/dictionaries/structure.model';
import { OptionSelectorWithNamer } from './OptionSelector';
import { useFormContext } from 'react-hook-form';
import { useTPDOptions } from 'src/hooks/useTPDOptions';

const TPEForm: FunctionComponent<{
  lifeInsuranceCalculatorUrl: string;
}> = ({ lifeInsuranceCalculatorUrl }) => {
  const config = useConfigContext().quotes.TPE;
  const { getValues, setValue } = useFormContext();
  const values = getValues();
  const { occupationTypeOptions, ownerOptions } = useTPDOptions(
    values.TRM.TPE,
    'TRM.TPE.occupationType',
    setValue
  );

  return (
    <div style={{ marginLeft: 24 }}>
      <div style={{ marginBottom: 10, marginTop: 25 }}>
        <b>TPD Extension</b>
      </div>

      <FormRow
        visibility={config.sumInsured.visibility}
        label={<Label>Cover</Label>}
        input={
          <Controller
            name="TRM.TPE.sumInsured"
            rules={{ required: true, min: 1 }}
            render={({ field: { onChange, value } }) => (
              <CoverAmountInput defaultValue={value} onValueChange={onChange} />
            )}
          />
        }
        info={
          <InfoTooltip title="Cover">
            When an illness or injury permanently prevents you from working in
            your occupation or any occupation, Total and Permanent Disability
            (TPD) Cover can provide a lump sum benefit.
            <br /> <br />
            The amount of TPD Cover needed will vary upon your individual
            circumstance. If you are unsure of how much TPD Cover you need, this{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={lifeInsuranceCalculatorUrl}
            >
              Life Insurance Calculator
            </a>{' '}
            may provide a helpful guide to you.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.owner.visibility}
        label={<Label>Ownership</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.TPE.owner"
            options={ownerOptions}
            namer={ownerNames}
          />
        }
        info={
          <InfoTooltip title="Ownership">
            <ul>
              {config.owner.choices.includes('O') && (
                <li>
                  Non-Super - The policy will be owned by the life insured or a
                  company. <br />
                </li>
              )}
              {config.owner.choices.includes('M') && (
                <li>
                  SMSF - The policy will be owned by a Self Managed Super Fund.
                  <br />
                </li>
              )}
              {config.owner.choices.includes('S') && (
                <li>
                  Super Fund - The policy will be owned by a Superannuation
                  Fund.
                  <br />
                </li>
              )}
              {config.owner.choices.includes('J') && (
                <li>
                  SuperLink - The policy will be a combination of Non-Super and
                  Super Fund.
                  <br />
                </li>
              )}
              {config.owner.choices.includes('M') &&
                config.owner.choices.includes('J') && (
                  <li>
                    When Ownership Super or SMSF are selected, Occupation type
                    must be Any. When Ownership SuperLink or SuperLink SMSF are
                    selected, the Occupation type must be Own.
                    <br />
                  </li>
                )}
            </ul>
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.rollover.visibility}
        label={<Label>Pay by Rollover</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.TPE.rollover"
            options={['N', 'I']}
            namer={rolloverNames}
          />
        }
        info={
          <InfoTooltip title="Pay by Rollover">
            Are you intending to pay your premiums through an eligible
            superannuation fund? <br />
            <br />
            {config.rollover.choices.includes('N') && (
              <>
                Exclude - Not paying premiums through a superannuation fund. No
                discount will apply. <br />
              </>
            )}
            {config.rollover.choices.includes('I') && (
              <>
                Include if possible - Apply the pay by rollover discount on all
                products that support it, also include products that don't
                support pay by rollover for a better comparison. <br />
              </>
            )}
            <br /> Note this option is only available on limited frequencies,
            please use Yearly for best results.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.structure.visibility}
        label={<Label>Structure</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.TPE.structure"
            options={['S', 'L', '70']}
            namer={structureNames}
          />
        }
        info={
          <InfoTooltip title="Structure">
            {config.structure.choices.includes('S') && (
              <>
                Stepped – Premiums will generally increase every year in line
                with the age of the insured. Premiums may vary due to sum
                insured changes including Consumer Price Index (CPI) movements
                or changes to the underlying premium rates set by the insurer.
                <br /> <br />
              </>
            )}
            {config.structure.choices.includes('L') && (
              <>
                Level to 65 – Premiums will not increase every year due to age
                until age 65. Premiums may vary due to sum insured changes
                including Consumer Price Index (CPI) movements or changes to the
                underlying premium rates set by the insurer. <br /> <br />
              </>
            )}
            {config.structure.choices.includes('70') && (
              <>
                Level to 70 – Premiums will not increase every year due to age
                until age 70. Premiums may vary due to sum insured changes
                including Consumer Price Index (CPI) movements or changes to the
                underlying premium rates set by the insurer.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.occupationType.visibility}
        label={<Label>Occupation Type</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.TPE.occupationType"
            options={occupationTypeOptions}
            namer={occupationTypeNames}
          />
        }
        info={
          <InfoTooltip title="Occupation Type">
            <ul>
              {config.occupationType.choices.includes('A') && (
                <li>
                  Any - A benefit is payable when it is not possible to perform
                  ANY employment activities. <br />
                </li>
              )}
              {config.occupationType.choices.includes('O') && (
                <li>
                  Own - A benefit is payable when it is not possible to perform
                  the normal duties of only your OWN current occupation. You may
                  still be able to work again in another occupation.
                  <br />
                </li>
              )}
              {config.occupationType.choices.includes('H') && (
                <li>
                  Homemaker - A homemaker definition may be available when
                  Homemaker is selected as the occupation.
                  <br />
                </li>
              )}
              {config.occupationType.choices.includes('D') && (
                <li>
                  ADL - A benefit is payable when it is not possible to perform
                  general Activities of Daily Living.
                  <br />
                </li>
              )}
              {config.occupationType.choices.includes('E') && (
                <li>
                  Best available - The best level of TPD definition available
                  for each product will be chosen. e.g. Own, Any, Homemaker,
                  ADL, are prioritised in that order.
                  <br />
                </li>
              )}
              {config.occupationType.choices.includes('O') &&
                config.occupationType.choices.includes('H') &&
                config.occupationType.choices.includes('D') && (
                  <li>
                    When Ownership Super or SMSF are selected, Occupation type
                    must be Any. When Ownership SuperLink or SuperLink SMSF are
                    selected, the Occupation type must be Own.
                    <br />
                  </li>
                )}
            </ul>
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.premiumWaiver.visibility}
        label={<Label>Premium Waiver</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.TPE.premiumWaiver"
            options={['N', 'I', 'Y']}
            namer={premiumWaiverNames}
          />
        }
        info={
          <InfoTooltip title="Premium Waiver">
            {config.premiumWaiver.choices.includes('N') && (
              <>
                Exclude - All products must not contain premium waiver. Any
                products that have it built-in will not be used. <br />
              </>
            )}
            {config.premiumWaiver.choices.includes('I') && (
              <>
                Include if possible - If premium waiver is an option on the
                product, then take the option. Products that do not support
                premium waiver are also used. <br />
              </>
            )}
            {config.premiumWaiver.choices.includes('Y') && (
              <>
                Include - All products must contain premium waiver. <br />
              </>
            )}
            <br /> Note that for best results, please set all of your Premium
            Waiver settings to the same selection.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.lifeBuyBack.visibility}
        label={<Label>Life Buy Back</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.TPE.lifeBuyBack"
            options={['L', 'F', 'N', '0', '1']}
            namer={lifeBuyBackNames}
          />
        }
        info={
          <InfoTooltip title="Life Buy Back">
            Life Buy back allows a choice of retaining life cover after a payout
            for TPD. <br /> <br />
            {config.lifeBuyBack.choices.includes('L') && (
              <>
                Exclude if possible - This will not select buyback unless
                buyback is built in to the product.
                <br />
              </>
            )}
            {config.lifeBuyBack.choices.includes('F') && (
              <>
                Best available - This will select the fastest buyback possible
                for the product.
                <br />
              </>
            )}
            {config.lifeBuyBack.choices.includes('N') && (
              <>
                Exclude - This will return products without buyback. If buyback
                is built in to the product, the product will be excluded.
                <br />
              </>
            )}
            {config.lifeBuyBack.choices.includes('0') && (
              <>
                Immediate - This will only select products with buyback of life
                cover available within a few months of the TPD event.
                <br />
              </>
            )}
            {config.lifeBuyBack.choices.includes('1') && (
              <>
                1 year - This will return products with buyback of life cover
                available 1 year after the TPD event. If buyback is not
                available or is provided faster than 1 year from TPD claim, the
                product will be excluded.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.doubleTPD.visibility}
        label={<Label>Double TPD</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.TPE.doubleTPD"
            options={['X', 'Y', 'N']}
            namer={doubleTPDNames}
          />
        }
        info={
          <InfoTooltip title="Double TPD">
            Double TPD restores your life cover after the TPD event and you will
            no longer have to pay premiums for the life of the policy. <br />
            <br />
            {config.doubleTPD.choices.includes('X') && (
              <>
                Exclude if possible - This will not select double TPD unless it
                is built in to the product.
                <br />
              </>
            )}
            {config.doubleTPD.choices.includes('Y') && (
              <>
                Include - This will select double TPD and will exclude any
                products that don't have it.
                <br />
              </>
            )}
            {config.doubleTPD.choices.includes('N') && (
              <>
                Exclude - This will return products without double TPD. If
                Double TPD is built in, the product will be excluded.
              </>
            )}
          </InfoTooltip>
        }
      />
    </div>
  );
};

export default TPEForm;
