import { Box, Button, Dialog, DialogContent, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { DialogTitleWithCloseButton } from 'src/common';
import { axiosAdmin } from '../../configs/axios.config';
import { adminCredsState } from '../../models/admin/credentials.model';
import { defaultConfig } from '../../models/config.model';
import { createConfigRequest } from '../../services/config.service';

const AddConfigButton: FunctionComponent<{ fetchConfigs: () => void }> = ({
  fetchConfigs,
}) => {
  const [creds] = useRecoilState(adminCredsState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const create = () => {
    setLoading(true);
    axiosAdmin(createConfigRequest(name, defaultConfig(), creds))
      .then(() => {
        setName('');
        setIsModalVisible(false);
        fetchConfigs();
      })
      .catch((e: AxiosError) => {
        toast.error(e.response?.data?.detail || 'Internal Error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button variant="contained" onClick={() => setIsModalVisible(true)}>
        Add
      </Button>

      <Dialog
        open={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          setLoading(false);
          setName('');
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitleWithCloseButton closeModal={() => setIsModalVisible(false)}>
          New Config
        </DialogTitleWithCloseButton>

        <DialogContent>
          <TextField
            sx={{ my: 2 }}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            error={name.length === 0}
          />

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="outlined" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={create}
              disabled={loading || name.length === 0}
            >
              Add
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddConfigButton;
