import _ from 'lodash';
import { FunctionComponent } from 'react';
import { babyCareNames } from '../../../models/dictionaries/babyCare.model';
import { occupationTypeNames } from '../../../models/dictionaries/occupationType.model';
import { ownerNames } from '../../../models/dictionaries/owner.model';
import { premiumWaiverNames } from '../../../models/dictionaries/premiumWaiver.model';
import { priorityNames } from '../../../models/dictionaries/priority.model';
import { rolloverNames } from '../../../models/dictionaries/rollover.model';
import { structureNames } from '../../../models/dictionaries/structure.model';
import { traumaReinstatementNames } from '../../../models/dictionaries/traumaReinstatement.model';
import { TPR, TRS } from '../../../models/needs/trs.model';
import { currencyFormat } from '../../../utils/common';
import { InclusionsBreakdownTable } from './InclusionsBreakdownTable';

export const TRSBreakdown: FunctionComponent<{ trs: TRS }> = ({ trs }) => {
  const tpr: TPR | undefined = (
    trs.linkedNeeds?.find((n) => _.has(n, 'TPR')) as any
  )?.TPR;

  return (
    <div style={{ marginBottom: 30 }}>
      <InclusionsBreakdownTable
        title="Trauma"
        data={[
          ['Sum Insured', currencyFormat(trs.sumInsured)],
          ['Structure', structureNames(trs.structure)],
          ['Reinstatement', traumaReinstatementNames(trs.traumaReinstatement)],
          ['Premium Waiver', premiumWaiverNames(trs.premiumWaiver)],
          ['Baby Care', babyCareNames(trs.babyCare)],
          ['Trauma Features', priorityNames(trs.priority)],
        ]}
      />

      {tpr && (
        <InclusionsBreakdownTable
          title="TPD Extension"
          data={[
            ['Sum Insured', currencyFormat(tpr.sumInsured)],
            ['Ownership', ownerNames(tpr.owner)],
            ['Structure', structureNames(tpr.structure)],
            ['Occupation type', occupationTypeNames(tpr.occupationType)],
            ['Premium Waiver', premiumWaiverNames(tpr.premiumWaiver)],
            ['Pay by Rollover', rolloverNames(tpr.rollover)],
          ]}
        />
      )}
    </div>
  );
};
