import { MenuItem, Select } from '@mui/material';
import _ from 'lodash';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from '../../../common/FormRow';
import InfoTooltip from '../../../common/InfoTooltip';
import Label from '../../../common/Label';
import { useConfigContext } from '../../../configs/config.context';
import { Child, getChild } from '../../../models/needs/cht.model';
import ChildForm from './ChildForm';

const CHTForm: FunctionComponent = () => {
  const config = useConfigContext().quotes.CHT;
  const { watch, setValue } = useFormContext();

  const children = watch('CHT.children');

  function changeChildrenNumber(v: number): void {
    let newArr = [];
    for (let i = 1; i <= v; i++) newArr.push(getChild(i, config));
    for (let i = 0; i < children.length && i < newArr.length; i++)
      newArr[i] = children[i];

    setValue('CHT.children', newArr);
  }

  return (
    <>
      <div style={{ marginBottom: 10, marginTop: 25 }}>
        <b>Child Trauma</b>
      </div>

      <FormRow
        label={<Label>Number of Children</Label>}
        input={
          <Select
            value={children.length}
            onChange={(e) => changeChildrenNumber(e.target.value)}
            size="small"
            sx={{ width: 200 }}
          >
            {_.range(1, 10).map((n) => (
              <MenuItem key={n} value={n}>
                {n}
              </MenuItem>
            ))}
          </Select>
        }
        info={
          <InfoTooltip title="Number of Children">
            This is the number of children to receive cover under child trauma.
          </InfoTooltip>
        }
      />

      {children.map((c: Child, index: number) => (
        <ChildForm key={c.number} index={index} childNumber={c.number} />
      ))}
    </>
  );
};

export default CHTForm;
