import { atom, useRecoilState } from 'recoil';

const stepState = atom<number>({
  key: 'step',
  default: 0,
});

const useStepState = () => useRecoilState(stepState);

export default useStepState;
