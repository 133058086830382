import { startCase } from 'lodash';
import { FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormRow from '../../common/FormRow';
import { CustomCard } from 'src/common';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';

import Button from '@mui/material/Button';
import {
  CampaignOptionsDropdown,
  SelectedCampaignOptions,
  SuppliersResponce,
} from 'src/models/campaign-options.model';
import CampaignOptionsSelector from './CampaignOptionsSelector';

const OutputSettingsForm: FunctionComponent<{
  supplierCampaignOptions: SuppliersResponce[];
}> = ({ supplierCampaignOptions }) => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const values = getValues();

  const [selectedCampaignOptions, setSelectedCampaignOptions] = useState<
    SelectedCampaignOptions[]
  >(
    values.outputSettings?.campaignOptions
      ? Object.keys(values.outputSettings?.campaignOptions).map(
          (c: string) => ({
            id: c,
            supplierCode: c,
            campaignCodes: Object.values(
              values.outputSettings.campaignOptions[
                c
              ] as CampaignOptionsDropdown
            ),
          })
        )
      : []
  );

  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>(
    values.outputSettings?.campaignOptions
      ? Object.keys(values.outputSettings?.campaignOptions).map(
          (c: string) => c
        )
      : []
  );

  const addCampaignOption = () => {
    setSelectedCampaignOptions([
      ...selectedCampaignOptions,
      {
        id: 'id' + new Date().getTime(),
        supplierCode: '',
        campaignCodes: [] as CampaignOptionsDropdown[],
      },
    ]);
  };

  const removeCampaignOption = (id: string, supplierCode: string) => {
    setSelectedCampaignOptions((prev) => prev.filter((c) => c.id !== id));
    setSelectedSuppliers(selectedSuppliers.filter((s) => s !== supplierCode));
  };

  const updateSuppliers = (prevSupplierCode: string, supplierCode: string) => {
    let updatedSuppliers = selectedSuppliers;
    if (prevSupplierCode !== '') {
      updatedSuppliers = selectedSuppliers.filter(
        (s) => s !== prevSupplierCode
      );
    }
    updatedSuppliers.push(supplierCode);
    setSelectedSuppliers([...updatedSuppliers, supplierCode]);
  };

  return (
    <>
      <CustomCard title="Settings">
        <FormRow
          label={<label>Scoring Mode:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <select {...register('outputSettings.scoreModeType')}>
                {['None', 'FeatureScore', 'AllScores'].map((o) => (
                  <option key={o} value={o}>
                    {startCase(o)}
                  </option>
                ))}
              </select>
            </div>
          }
        />

        <FormRow
          label={<label>Score Calculation:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <select {...register('outputSettings.scoreWeightingFeatureType')}>
                {['Essential', 'Balanced', 'Comprehensive'].map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>
            </div>
          }
        />

        <FormRow
          label={<label>Score Weighting:</label>}
          input={
            <div>
              <input
                type="number"
                {...register('outputSettings.priceWeighting', {
                  required: true,
                  valueAsNumber: true,
                  min: 0,
                  max: 100,
                })}
              />
              <div style={{ height: 20 }}>
                {errors?.outputSettings?.priceWeighting && (
                  <p style={{ color: 'red' }}>
                    Please provide a valid value from 0 to 100
                  </p>
                )}
              </div>
            </div>
          }
        />

        <Box display="flex" alignItems="center">
          <label style={{ width: 212 }}>Included Suppliers:</label>
          <Controller
            name="outputSettings.includedSuppliers"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                options={
                  supplierCampaignOptions.length > 0
                    ? supplierCampaignOptions.map((s) => s.code)
                    : ['Loading...']
                }
                fullWidth
                getOptionDisabled={() => {
                  return supplierCampaignOptions.length < 1;
                }}
                onChange={(_e, v) => onChange(v)}
                value={value}
                freeSolo
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
                disableClearable
              />
            )}
          />
        </Box>

        {selectedCampaignOptions.length > 0 && (
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            Campaign Options:
          </div>
        )}

        {selectedCampaignOptions.length > 0 &&
          selectedCampaignOptions.map((camp) => (
            <CampaignOptionsSelector
              key={camp.id}
              id={camp.id}
              includedSuppliers={values.outputSettings.includedSuppliers}
              supplierCampaignOptions={supplierCampaignOptions}
              removeCampaign={removeCampaignOption}
              updateSuppliers={updateSuppliers}
              hiddenSuppliers={selectedSuppliers}
              selectedSupplier={camp.supplierCode}
              selectedCampaignCodes={camp.campaignCodes}
            />
          ))}

        <Box display="flex" alignItems="center" mt={1}>
          <Button variant="contained" onClick={addCampaignOption}>
            + Add Campaign Option
          </Button>
        </Box>
      </CustomCard>

      <CustomCard title="Tags">
        <FormRow
          label={<label>User Id:</label>}
          input={
            <div>
              <input
                {...register('outputSettings.tagsUserId', { required: true })}
              />
              <div style={{ height: 20 }}>
                {errors?.outputSettings?.tagsUserId && (
                  <p style={{ color: 'red' }}>Required</p>
                )}
              </div>
            </div>
          }
        />
        <FormRow
          label={<label>Group Id:</label>}
          input={
            <div>
              <input
                {...register('outputSettings.tagsGroupId', { required: true })}
              />
              <div style={{ height: 20 }}>
                {errors?.outputSettings?.tagsGroupId && (
                  <p style={{ color: 'red' }}>Required</p>
                )}
              </div>
            </div>
          }
        />
      </CustomCard>
    </>
  );
};

export default OutputSettingsForm;
