import { FunctionComponent, ReactNode } from 'react';
import useStepState from './stepState';
import { Box, Button, Divider } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface NavButtonsProps {
  nextEnabled?: boolean;
  button?: ReactNode;
  label?: string;
}

const NavButtons: FunctionComponent<NavButtonsProps> = ({
  nextEnabled = true,
  button,
  label = 'Next',
}) => {
  const [step, setStep] = useStepState();

  return (
    <>
      <Divider />

      <Box display="flex" justifyContent="space-between">
        {step > 0 ? (
          <Button
            onClick={() => setStep((s) => s - 1)}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
          >
            Previous
          </Button>
        ) : (
          <div />
        )}
        {button ||
          (step < 3 && (
            <Button
              onClick={() => setStep((s) => s + 1)}
              disabled={!nextEnabled}
              endIcon={<ArrowForwardIcon />}
              variant="contained"
            >
              {label}
            </Button>
          ))}
      </Box>
    </>
  );
};

export default NavButtons;
