import {
  frequencies,
  Frequency,
  OccupationFilter,
} from './quote-response.model';
import { Moment } from 'moment';
import { Salutation } from './dictionaries/salutation.model';

export interface Config {
  quotes: {
    TRM: TRMConfig;
    TPE: TPEConfig;
    TRE: TREConfig;

    TPD: TPDConfig;

    TRS: TRSConfig;
    TPR: TPRConfig;

    INC: INCConfig;
    BUS: BUSConfig;
    NES: NESConfig;
    CHT: CHTConfig;
  };
  covers: Covers;
  outputSettings: OutputSettings;
  display: Display;
  yourDetails?: YourDetailsConfig;
  report?: ReportConfig;
  tracking?: Tracking;
  application?: ApplicationConfig;
}

export interface ApplicationConfig {
  enabled: boolean;
  email: string;
}

export interface Tracking {
  gtmEnabled: boolean;
  gtmId: string;
}

export interface ReportConfig {
  adviserEmail?: string | undefined;
  subReportType?: string | undefined;
  sendReportToClient?: boolean | undefined;
  addOmniLifeLink: boolean | undefined;
  sendReportToAdviser: boolean | undefined;
  clientEmailTemplate?: string;
}

export interface YourDetailsConfig {
  salutationVisible: Visibility | undefined;
  salutationRequired?: 'required' | 'optional';
  defaultSalutation: Salutation | undefined;
  employmentStatusVisible: Visibility | undefined;
  employmentStatusRequired?: 'required' | 'optional';
  defaultEmploymentStatus: string | undefined;
  defaultOccupationId: string | undefined;
  defaultOccupationName: string | undefined;
  annualIncomeVisible: Visibility | undefined;
  emailVisible?: Visibility;
  phoneVisible?: Visibility;

  useTestData: boolean | undefined;

  customerConsentVisible: Visibility | undefined;
  customerConsentLabel: string | undefined;
  customerConsentText: string | undefined;

  PDSAndTMDVisible: Visibility | undefined;
  PDSAndTMDLabel: string | undefined;
  PDSAndTMDText: string | undefined;

  postCodeVisible: Visibility | undefined;
  postCodeRequired: 'required' | 'optional' | undefined;

  freeTextFieldLabel: string | undefined;
  freeTextFieldVisible: Visibility | undefined;
  freeTextFieldRequired: Requirement | undefined;

  occupationFilter: OccupationFilter[] | undefined;
}

// Life
export interface TRMConfig {
  sumInsured: AmountConfig;
  owner: ChoiceConfig<'O' | 'M' | 'S' | undefined>;
  rollover: ChoiceConfig<'N' | 'I' | undefined>;
  structure: ChoiceConfig<'S' | 'L' | '70' | undefined>;
  premiumWaiver: ChoiceConfig<'N' | 'I' | 'Y' | undefined>;
}

// TPD Extension to Life
export interface TPEConfig extends TPDConfig {
  lifeBuyBack: ChoiceConfig<'L' | 'F' | 'N' | '0' | '1'>;
  doubleTPD: ChoiceConfig<'X' | 'Y' | 'N'>;
}

// Trauma extension to Life
export interface TREConfig extends TRSConfig {
  lifeBuyBack: ChoiceConfig<'L' | 'F' | 'N' | '1' | '3'>;
  doubleTrauma: ChoiceConfig<'X' | 'Y' | 'N'>;
}

// TPD Standalone
export interface TPDConfig {
  sumInsured: AmountConfig;
  owner: ChoiceConfig<'O' | 'M' | 'S' | 'J' | undefined>;
  rollover: ChoiceConfig<'N' | 'I' | undefined>;
  structure: ChoiceConfig<'S' | 'L' | '70' | undefined>;
  occupationType: ChoiceConfig<'A' | 'O' | 'H' | 'D' | 'E' | undefined>;
  premiumWaiver: ChoiceConfig<'N' | 'I' | 'Y' | undefined>;
}

// Trauma
export interface TRSConfig {
  sumInsured: AmountConfig;
  structure: ChoiceConfig<'S' | 'L' | '70' | undefined>;
  traumaReinstatement: ChoiceConfig<'X' | 'Y' | 'N' | undefined>;
  premiumWaiver: ChoiceConfig<'N' | 'I' | 'Y' | undefined>;
  babyCare: ChoiceConfig<'X' | 'I' | 'Y' | 'N' | undefined>;
  priority: ChoiceConfig<'C' | 'B' | 'I' | undefined>;
}

// TPD Extension to Trauma
export interface TPRConfig extends TPDConfig {}

// Income Protection
export interface INCConfig {
  monthlyBenefit: AmountConfig;
  superContributionOption: AmountConfig;
  owner: ChoiceConfig<'O' | 'M' | 'S' | 'J' | 'K' | undefined>;
  rollover: ChoiceConfig<'N' | 'I' | undefined>;
  structure: ChoiceConfig<'S' | 'L' | undefined>;
  agreedValue: ChoiceConfig<'X' | 'N' | undefined>;
  accidentBenefit: ChoiceConfig<'X' | 'I' | 'Y' | 'N' | undefined>;
  increaseClaimBenefit: ChoiceConfig<'X' | 'I' | 'Y' | 'N' | undefined>;
  waitingPeriod: ChoiceConfig<14 | 30 | 60 | 90 | 180 | 365 | 730 | undefined>;
  benefitPeriod: ChoiceConfig<1 | 2 | 5 | 65 | 70 | undefined>;
  priority: ChoiceConfig<'C' | 'B' | 'I' | undefined>;
  annualIncomeVisible: Visibility | undefined;
}

// Business Expenses
export interface BUSConfig {
  monthlyBenefit: AmountConfig;
  structure: ChoiceConfig<'S' | 'L' | undefined>;
  waitingPeriod: ChoiceConfig<14 | 30 | 60 | 90 | undefined>;
  benefitPeriod: ChoiceConfig<1 | undefined>;
}

// Needle Stick
export interface NESConfig {
  sumInsured: AmountConfig;
  structure: ChoiceConfig<'S' | 'L' | undefined>;
}

// Child Trauma
export interface CHTConfig {
  sumInsured: AmountConfig;
  dateOfBirth: ChoiceConfig<Moment | null>;
  age: AmountConfig;
  gender: ChoiceConfig<'M' | 'F' | undefined>;
}

export interface Covers {
  TRM: Visibility;
  TPD: Visibility;
  TRS: Visibility;
  INC: Visibility;
  BUS: Visibility;
  NES: Visibility;
  CHT: Visibility;
  generalAdviceDisclaimer?: string;
  complexMode?: boolean;
}

export interface OutputSettings {
  /** APL - Approved Product List - Included Suppliers */
  includedSuppliers: string[]; // Body

  /** Scoring Mode - Score Mode Type */
  scoreModeType: 'None' | 'FeatureScore' | 'AllScores'; // Body

  /** Score calculation - Score Weighting Type */
  scoreWeightingFeatureType: 'Essential' | 'Balanced' | 'Comprehensive'; // Body

  /** Score weighting - from 0 to 100 - Price Weighting */
  priceWeighting: number; // Body

  tagsUserId?: string;
  tagsGroupId?: string;

  frequency?: Frequency;

  campaignOptions?: {};
}

export interface Display {
  logo: Visibility;
  name: Visibility;
  premium: Visibility;
  featureScore: Visibility;
  combinedScore: Visibility;

  scoreFormat: 'Raw' | '5' | '15' | 'Star';
  frequencies: Frequency[];
  randomize: boolean;
  mainLogoUrl?: string;
  appColor?: string;
  secondaryAppColor?: string;
  premiumColor?: string;

  showBanner?: boolean;
  bookAppointmentUrl?: string;
  callUsTel?: string;

  actions: Visibility;
  actionPds: Visibility;
  actionStrengthAndLimitations: Visibility;
  actionInclustions: Visibility;
  actionTMD: Visibility;

  reportButton?: Visibility;

  footerNote2?: Visibility;

  lifeInsuranceCalculatorUrl?: string;
}

export interface ChoiceConfig<T> {
  visibility: Visibility;
  default: T;
  choices: T[];
}

export interface AmountConfig {
  visibility: Visibility;
  default: number;
}

export type Visibility = 'visible' | 'hidden';
export type Requirement = 'required' | 'optional';

export function defaultConfig(): Config {
  return {
    quotes: {
      TRM: {
        sumInsured: {
          visibility: 'visible',
          default: 500_000,
        },
        owner: {
          visibility: 'visible',
          default: 'O',
          choices: ['O', 'M', 'S'],
        },
        rollover: {
          visibility: 'visible',
          default: 'N',
          choices: ['N', 'I'],
        },
        structure: {
          visibility: 'visible',
          default: 'S',
          choices: ['S', 'L', '70'],
        },
        premiumWaiver: {
          visibility: 'visible',
          default: 'N',
          choices: ['N', 'I', 'Y'],
        },
      },
      TPE: {
        lifeBuyBack: {
          visibility: 'hidden',
          default: 'L',
          choices: ['L', 'F', 'N', '0', '1'],
        },
        doubleTPD: {
          visibility: 'hidden',
          default: 'X',
          choices: ['X', 'Y', 'N'],
        },
        sumInsured: {
          visibility: 'hidden',
          default: 0,
        },
        owner: {
          visibility: 'hidden',
          default: 'O',
          choices: ['O', 'M', 'S', 'J'],
        },
        rollover: {
          visibility: 'hidden',
          default: 'N',
          choices: ['N', 'I'],
        },
        structure: {
          visibility: 'hidden',
          default: 'S',
          choices: ['S', 'L', '70'],
        },
        occupationType: {
          visibility: 'hidden',
          default: 'A',
          choices: ['A', 'O', 'H', 'D', 'E'],
        },
        premiumWaiver: {
          visibility: 'hidden',
          default: 'N',
          choices: ['N', 'I', 'Y'],
        },
      },
      TRE: {
        lifeBuyBack: {
          visibility: 'hidden',
          default: 'L',
          choices: ['L', 'F', 'N', '1'],
        },
        doubleTrauma: {
          visibility: 'hidden',
          default: 'X',
          choices: ['X', 'Y', 'N'],
        },
        sumInsured: {
          visibility: 'hidden',
          default: 0,
        },
        structure: {
          visibility: 'hidden',
          default: 'S',
          choices: ['S', 'L', '70'],
        },
        traumaReinstatement: {
          visibility: 'hidden',
          default: 'X',
          choices: ['X', 'Y', 'N'],
        },
        premiumWaiver: {
          visibility: 'hidden',
          default: 'N',
          choices: ['N', 'I', 'Y'],
        },
        babyCare: {
          visibility: 'hidden',
          default: 'X',
          choices: ['X', 'I', 'Y', 'N'],
        },
        priority: {
          visibility: 'hidden',
          default: 'C',
          choices: ['C', 'B', 'I'],
        },
      },
      TPD: {
        sumInsured: {
          visibility: 'visible',
          default: 500_000,
        },
        owner: {
          visibility: 'visible',
          default: 'O',
          choices: ['O', 'M', 'S', 'J'],
        },
        rollover: {
          visibility: 'visible',
          default: 'N',
          choices: ['N', 'I'],
        },
        structure: {
          visibility: 'visible',
          default: 'S',
          choices: ['S', 'L', '70'],
        },
        occupationType: {
          visibility: 'visible',
          default: 'A',
          choices: ['A', 'O', 'H', 'D', 'E'],
        },
        premiumWaiver: {
          visibility: 'visible',
          default: 'N',
          choices: ['N', 'I', 'Y'],
        },
      },
      TRS: {
        sumInsured: {
          visibility: 'visible',
          default: 500_000,
        },
        structure: {
          visibility: 'visible',
          default: 'S',
          choices: ['S', 'L', '70'],
        },
        traumaReinstatement: {
          visibility: 'visible',
          default: 'X',
          choices: ['X', 'Y', 'N'],
        },
        premiumWaiver: {
          visibility: 'visible',
          default: 'N',
          choices: ['N', 'I', 'Y'],
        },
        babyCare: {
          visibility: 'visible',
          default: 'X',
          choices: ['X', 'I', 'Y', 'N'],
        },
        priority: {
          visibility: 'visible',
          default: 'C',
          choices: ['C', 'B', 'I'],
        },
      },
      TPR: {
        sumInsured: {
          visibility: 'hidden',
          default: 0,
        },
        owner: {
          visibility: 'hidden',
          default: 'O',
          choices: ['O', 'M', 'S', 'J'],
        },
        rollover: {
          visibility: 'hidden',
          default: 'N',
          choices: ['N', 'I'],
        },
        structure: {
          visibility: 'hidden',
          default: 'S',
          choices: ['S', 'L', '70'],
        },
        occupationType: {
          visibility: 'hidden',
          default: 'A',
          choices: ['A', 'O', 'H', 'D', 'E'],
        },
        premiumWaiver: {
          visibility: 'hidden',
          default: 'N',
          choices: ['N', 'I', 'Y'],
        },
      },
      INC: {
        monthlyBenefit: {
          visibility: 'visible',
          default: 500_000,
        },
        superContributionOption: {
          visibility: 'visible',
          default: 500_000,
        },
        owner: {
          visibility: 'visible',
          default: 'O',
          choices: ['O', 'M', 'S', 'J', 'K'],
        },
        rollover: {
          visibility: 'visible',
          default: 'N',
          choices: ['N', 'I'],
        },
        structure: {
          visibility: 'visible',
          default: 'S',
          choices: ['S', 'L'],
        },
        agreedValue: {
          visibility: 'hidden',
          default: 'X',
          choices: ['X', 'N'],
        },
        accidentBenefit: {
          visibility: 'visible',
          default: 'X',
          choices: ['X', 'I', 'Y', 'N'],
        },
        increaseClaimBenefit: {
          visibility: 'visible',
          default: 'X',
          choices: ['X', 'I', 'Y', 'N'],
        },
        waitingPeriod: {
          visibility: 'visible',
          default: 30,
          choices: [14, 30, 60, 90, 180, 365, 730],
        },
        benefitPeriod: {
          visibility: 'visible',
          default: 65,
          choices: [1, 2, 5, 65, 70],
        },
        priority: {
          visibility: 'visible',
          default: 'C',
          choices: ['C', 'B', 'I'],
        },
        annualIncomeVisible: 'hidden',
      },
      BUS: {
        monthlyBenefit: {
          visibility: 'visible',
          default: 1_000,
        },
        structure: {
          visibility: 'visible',
          default: 'S',
          choices: ['S', 'L'],
        },
        waitingPeriod: {
          visibility: 'visible',
          default: 30,
          choices: [14, 30, 60, 90],
        },
        benefitPeriod: {
          visibility: 'visible',
          default: 1,
          choices: [1],
        },
      },
      NES: {
        sumInsured: {
          visibility: 'visible',
          default: 1_000,
        },
        structure: {
          visibility: 'visible',
          default: 'S',
          choices: ['S', 'L'],
        },
      },
      CHT: {
        sumInsured: {
          visibility: 'visible',
          default: 1_000,
        },
        dateOfBirth: {
          visibility: 'visible',
          default: null,
          choices: [],
        },
        age: {
          visibility: 'visible',
          default: 5,
        },
        gender: {
          visibility: 'visible',
          default: 'M',
          choices: ['M', 'F'],
        },
      },
    },
    covers: {
      TRM: 'visible',
      TPD: 'visible',
      TRS: 'visible',
      INC: 'visible',
      BUS: 'visible',
      NES: 'visible',
      CHT: 'visible',
      generalAdviceDisclaimer: '',
    },
    outputSettings: {
      includedSuppliers: ['AIG', 'MLC', 'ZUR', 'ACC', 'ING'],
      scoreModeType: 'AllScores',
      scoreWeightingFeatureType: 'Balanced',
      priceWeighting: 50,
      campaignOptions: [],
    },
    display: {
      logo: 'visible',
      name: 'visible',
      premium: 'visible',
      featureScore: 'visible',
      combinedScore: 'visible',
      scoreFormat: 'Star',
      randomize: false,
      frequencies: frequencies,

      actions: 'hidden',
      actionPds: 'hidden',
      actionStrengthAndLimitations: 'hidden',
      actionInclustions: 'hidden',
      actionTMD: 'hidden',

      appColor: '#1976d2',
      secondaryAppColor: '#1565c0',
      premiumColor: '#1976d2',

      reportButton: 'visible',

      lifeInsuranceCalculatorUrl:
        'https://moneysmart.gov.au/how-life-insurance-works/life-insurance-calculator',
    },
    yourDetails: {
      salutationVisible: 'visible',
      salutationRequired: 'required',
      defaultSalutation: undefined,
      employmentStatusVisible: undefined,
      defaultEmploymentStatus: undefined,
      defaultOccupationId: undefined,
      defaultOccupationName: undefined,
      annualIncomeVisible: undefined,
      useTestData: false,

      customerConsentVisible: 'hidden',
      customerConsentLabel: '',
      customerConsentText: '',

      PDSAndTMDVisible: 'hidden',
      PDSAndTMDLabel: '',
      PDSAndTMDText: '',

      postCodeVisible: 'hidden',
      postCodeRequired: 'optional',

      freeTextFieldLabel: '',
      freeTextFieldVisible: 'hidden',
      freeTextFieldRequired: 'optional',

      occupationFilter: undefined,
    },
    report: {
      sendReportToAdviser: true,
      addOmniLifeLink: true,
    },
  };
}
