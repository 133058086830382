export function benefitPeriodNames(
  key: 1 | 2 | 5 | 55 | 60 | 65 | 70 | undefined
): string {
  if (key === undefined) return '';
  else
    return {
      1: '1 year',
      2: '2 years',
      5: '5 years',
      55: 'To age 55',
      60: 'To age 60',
      65: 'To age 65',
      70: 'To age 70',
    }[key];
}
