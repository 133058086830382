import { FunctionComponent, useState } from 'react';
import useAxios from 'axios-hooks';
import { ClientInput } from 'src/models/client-input.model';
import { getApplication } from 'src/features/application/api/getApplication';
import { Application } from 'src/features/application';
import { ApplicationView } from 'src/features/application/components/ApplicationView';
import { prepopulateApplication } from 'src/features/application/utils/prepopulateApplication';
import { Alert, Button, Dialog, Divider } from '@mui/material';
import { DialogTitleWithCloseButton, Loading } from 'src/common';
import { useBreakpoint } from 'src/hooks';

export const ApplyButton: FunctionComponent<{
  code: string;
  client: ClientInput;
  insurer: string;
  closeMenu: () => void;
}> = ({ code, client, insurer, closeMenu }) => {
  const [showModal, setShowModal] = useState(false);
  const { xs } = useBreakpoint();

  return (
    <>
      <Button
        size="small"
        onClick={() => {
          closeMenu();
          !showModal && setShowModal(true);
        }}
      >
        Apply
      </Button>

      <Dialog fullWidth fullScreen={xs} open={showModal} maxWidth="md">
        <DialogTitleWithCloseButton closeModal={() => setShowModal(false)}>
          Apply Form
        </DialogTitleWithCloseButton>

        <Divider sx={{ m: 0 }} />

        <ApplyModalContent code={code} client={client} insurer={insurer} />
      </Dialog>
    </>
  );
};

const ApplyModalContent: FunctionComponent<{
  code: string;
  client: ClientInput;
  insurer: string;
}> = ({ code, client, insurer }) => {
  const [{ data, loading }] = useAxios<Application>(
    getApplication(undefined, code)
  );

  if (loading) return <Loading />;

  if (!data || !data.pages || !data.pages.length)
    return <Alert severity="error">Apply Form Not Defined!</Alert>;

  return (
    <ApplicationView
      code={code}
      data={prepopulateApplication(data, client, insurer)}
    />
  );
};
