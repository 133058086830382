import { LoadingButton } from '@mui/lab';
import { Alert, Box, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { FunctionComponent, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { axiosAdmin } from '../configs/axios.config';
import {
  adminCredsState,
  Credentials,
} from '../models/admin/credentials.model';
import { authRequest } from '../services/auth.service';
import LoginIcon from '@mui/icons-material/Login';

const Login: FunctionComponent = () => {
  const setCreds = useSetRecoilState(adminCredsState);

  const [credentials, setCredentials] = useState<Credentials>({
    username: '',
    password: '',
  });

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const login = () => {
    setError(undefined);
    setLoading(true);
    axiosAdmin(authRequest(credentials))
      .then(() => setCreds(credentials))
      .catch((e: AxiosError) => {
        if (e.response?.status === 401 || e.response?.status === 403)
          setError('Incorrect Username or Password!');
        else setError('System Error! Please Contact System Administrator!');
        setLoading(false);
      });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <h2 style={{ textAlign: 'center' }}>Admin Panel</h2>

        <TextField
          InputLabelProps={{ shrink: true }}
          label="Username"
          name="username"
          required
          value={credentials.username}
          onChange={(e) =>
            setCredentials({ ...credentials, username: e.target.value })
          }
          error={!credentials.username}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          label="Password"
          name="password"
          type="password"
          required
          value={credentials.password}
          onChange={(e) =>
            setCredentials({ ...credentials, password: e.target.value })
          }
          error={!credentials.password}
        />

        <Box display="flex" justifyContent="flex-end">
          <LoadingButton
            variant="contained"
            startIcon={<LoginIcon />}
            loading={loading}
            onClick={login}
            disabled={!credentials.username || !credentials.password}
          >
            Log In
          </LoadingButton>
        </Box>

        {error && <Alert color="error">{error}</Alert>}
      </Box>
    </Box>
  );
};

export default Login;
