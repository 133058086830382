import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { FunctionComponent } from 'react';
import { Portfolio } from '../../../models/quote-response.model';
import { SelectedCoverTypes } from '../../../models/selected-cover-types.model';
import { InclusionsBreakdownTable } from './InclusionsBreakdownTable';

export const Occupation: FunctionComponent<{
  portfolio: Portfolio;
  selected: SelectedCoverTypes;
}> = ({ portfolio, selected }) => {
  const occupation = portfolio.occupation;
  const data: [string, string][] = _.compact([
    selected.TRM &&
      occupation.supportsTRM && ['Life Class', occupation.classTRM],

    selected.TRS &&
      occupation.supportsTRA && ['Trauma Class', occupation.classTRA],

    selected.TPD &&
      occupation.supportsTPDADL && ['TPD ADL Class', occupation.classTPDADL],
    selected.TPD &&
      occupation.supportsTPDAny && ['TPD Any Class', occupation.classTPDAny],
    selected.TPD &&
      occupation.supportsTPDOwn && ['TPD Own Class', occupation.classTPDOwn],

    selected.INC &&
      occupation.supportsINC && [
        'Income Protection Class',
        occupation.classINC,
      ],

    selected.BUS &&
      occupation.supportsBUS && [
        'Business Expenses Class',
        occupation.classBUS,
      ],
  ]);

  return (
    <Box mb={4}>
      <Typography variant="h6" fontWeight="bold">
        Occupation
      </Typography>
      <Typography variant="body2" mt={1} mb={2}>
        {occupation.description}
      </Typography>

      <InclusionsBreakdownTable title="Classes" data={data} />
    </Box>
  );
};
