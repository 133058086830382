import { AxiosRequestConfig } from 'axios';
import { Credentials } from '../models/admin/credentials.model';

export function authRequest(creds: Credentials): AxiosRequestConfig {
  return {
    url: '/auth',
    method: 'POST',
    auth: {
      username: creds.username,
      password: creds.password,
    },
  };
}
