import { FC } from 'react';
import { GenericAmountInput } from './GenericAmountInput';

const AgeInput: FC<{
  value?: number;
  onValueChange: (cover: number | undefined, valid: boolean) => void;
}> = ({ onValueChange, value }) => {
  const valid = !isNaN(Number(value));

  return (
    <GenericAmountInput
      error={!valid}
      prefix=""
      maxLength={2}
      value={value || 0}
      onChange={(v) => {
        const locCover: number | undefined = isNaN(Number(v))
          ? undefined
          : Number(v);
        onValueChange(locCover, valid);
      }}
    />
  );
};

export default AgeInput;
