import { AxiosRequestConfig } from 'axios';
import { Credentials } from 'src/models/admin/credentials.model';
import { Application } from '../types/application.type';

export function updateApplication(
  creds: Credentials | undefined,
  code: string,
  application: Application
): AxiosRequestConfig {
  return {
    method: 'PUT',
    url: `/configs/${code}/application`,
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
    data: application,
  };
}
