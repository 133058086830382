import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureAxios } from './configs/axios.config';
import { validateAppConfigs } from './configs/app.config';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './common/ErrorFallback';

validateAppConfigs();
configureAxios();

if (process.env.NODE_ENV === 'development')
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ErrorBoundary>,
    document.getElementById('root')
  );
else
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example - reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
