import { Box, Grid, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FunctionComponent } from 'react';
import { AmountInput } from 'src/common/AmountInput';
import { Tag } from '../types/application.type';

export const TagRender: FunctionComponent<{
  tags: Tag[];
  tag: Tag;
  setTag: (tag: Tag) => void;
}> = ({ tag, setTag, tags }) => {
  if (
    tag.visibilityId &&
    tag.visibilityValue !== tags.find((t) => t.id === tag.visibilityId)?.value
  )
    return null;

  if (tag.type === 'Text')
    return <div dangerouslySetInnerHTML={{ __html: tag.label }} />;

  return (
    <Grid container mb={1}>
      <Grid item xs={12} sm={6} pt={1}>
        <div dangerouslySetInnerHTML={{ __html: tag.label }} />
      </Grid>

      <Grid item xs={12} sm={6}>
        {tag.type === 'TextInput' && (
          <TextField
            value={tag.value}
            onChange={(e) => setTag({ ...tag, value: e.target.value })}
            style={{ width: '100%', marginBottom: 8 }}
            size="small"
          />
        )}

        {tag.type === 'Select' && (
          <Select
            style={{ width: '100%', marginBottom: 8 }}
            value={tag.value}
            onChange={(e) => setTag({ ...tag, value: e.target.value })}
            size="small"
            MenuProps={{
              autoFocus: false,
              disableAutoFocusItem: true,
              disableEnforceFocus: true,
              disableAutoFocus: true,
            }}
          >
            {tag.options.map((o) => (
              <MenuItem key={o} value={o}>
                {o}
              </MenuItem>
            ))}
          </Select>
        )}

        {tag.type === 'Number' && (
          <Box mb={1}>
            <AmountInput
              prefix=""
              disableGroupSeparators={true}
              value={tag.value}
              onChange={(v) => setTag({ ...tag, value: v?.toString() || '' })}
            />
          </Box>
        )}

        {tag.type === 'Date' && (
          <DatePicker
            value={tag.value ? moment(tag.value) : undefined}
            onChange={(newDate) =>
              setTag({ ...tag, value: newDate?.toString() || '' })
            }
            inputFormat="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField {...params} sx={{ width: 200 }} size="small" />
            )}
          />
        )}
      </Grid>
    </Grid>
  );
};
