import { NESConfig } from '../config.model';

/** Needle Stick */
export interface NES {
  sumInsured: number;
  structure: 'S' | 'L' | undefined;
}

export function getNES(config: NESConfig): NES {
  return {
    sumInsured: config.sumInsured.default,
    structure: config.structure.default,
  };
}

export function NESValid(nes: NES, config: NESConfig) {
  return (
    nes.sumInsured > 0 &&
    (config.structure.visibility === 'hidden' || nes.structure !== undefined)
  );
}
