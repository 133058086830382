import { FunctionComponent } from 'react';
import { CustomCard } from 'src/common';
import AmountConfigInput from '../../../common/AmountConfigInput';
import ChoiceConfigInput from '../../../common/ChoiceConfigInput';
import FormRow from '../../../common/FormRow';
import { useFormContext } from 'react-hook-form';

const INCConfigForm: FunctionComponent = () => {
  const { register, getValues } = useFormContext();
  const annualIncomeVisible = getValues().quotes.INC.annualIncomeVisible;

  return (
    <>
      <CustomCard title="INC">
        <FormRow
          label={<label>Amount:</label>}
          input={
            <AmountConfigInput
              name="quotes.INC.monthlyBenefit"
              required={true}
            />
          }
        />

        <FormRow
          label={<label>Annual Income: </label>}
          input={
            <select
              style={{ marginTop: 10, marginBottom: 16 }}
              {...register('quotes.INC.annualIncomeVisible')}
            >
              <option value="visible">Visible</option>
              <option
                selected={
                  annualIncomeVisible === undefined ||
                  annualIncomeVisible === 'hidden'
                }
                value="hidden"
              >
                Hidden
              </option>
            </select>
          }
        />

        <FormRow
          label={<label>Super Contribution:</label>}
          input={
            <AmountConfigInput
              name="quotes.INC.superContributionOption"
              required={false}
            />
          }
        />

        <FormRow
          label={<label>Ownership:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.owner"
              choices={['O', 'M', 'S', 'J', 'K']}
            />
          }
        />

        <FormRow
          label={<label>Rollover:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.rollover"
              choices={['N', 'I']}
            />
          }
        />

        <FormRow
          label={<label>Structure:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.structure"
              choices={['S', 'L']}
            />
          }
        />

        <FormRow
          label={<label>Agreed Value:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.agreedValue"
              choices={['X', 'N']}
            />
          }
        />

        <FormRow
          label={<label>Accident Benefit:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.accidentBenefit"
              choices={['X', 'I', 'Y', 'N']}
            />
          }
        />

        <FormRow
          label={<label>Increase Claim Benefit:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.increaseClaimBenefit"
              choices={['X', 'I', 'Y', 'N']}
            />
          }
        />

        <FormRow
          label={<label>Waiting Period:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.waitingPeriod"
              choices={[14, 30, 60, 90, 180, 365, 730]}
            />
          }
        />

        <FormRow
          label={<label>Benefit Period:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.benefitPeriod"
              choices={[1, 2, 5, 65, 70]}
            />
          }
        />

        <FormRow
          label={<label>Priority:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.INC.priority"
              choices={['C', 'B', 'I']}
            />
          }
        />
      </CustomCard>
    </>
  );
};

export default INCConfigForm;
