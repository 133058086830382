import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import CoverAmountInput from '../../common/CoverAmountInput';
import FormRow from '../../common/FormRow';
import InfoTooltip from '../../common/InfoTooltip';
import Label from '../../common/Label';
import { useConfigContext } from '../../configs/config.context';
import { ownerNames } from '../../models/dictionaries/owner.model';
import { premiumWaiverNames } from '../../models/dictionaries/premiumWaiver.model';
import { rolloverNames } from '../../models/dictionaries/rollover.model';
import { structureNames } from '../../models/dictionaries/structure.model';
import { OptionSelectorWithNamer } from './OptionSelector';

const TRMForm: FunctionComponent<{
  lifeInsuranceCalculatorUrl: string;
}> = ({ lifeInsuranceCalculatorUrl }) => {
  const config = useConfigContext().quotes.TRM;

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <b>Life Cover</b>
      </div>

      <FormRow
        visibility={config.sumInsured.visibility}
        label={<Label>Cover</Label>}
        input={
          <Controller
            name="TRM.sumInsured"
            rules={{ required: true, min: 1 }}
            render={({ field: { onChange, value } }) => (
              <CoverAmountInput defaultValue={value} onValueChange={onChange} />
            )}
          />
        }
        info={
          <InfoTooltip title="Cover">
            Protecting your future. In the event of death or terminal illness,
            Life Cover can provide a lump sum benefit.
            <br /> <br />
            The amount of Life Cover needed will vary upon your individual
            circumstance. If you are unsure of how much Life Cover you need,
            this{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={lifeInsuranceCalculatorUrl}
            >
              Life Insurance Calculator
            </a>{' '}
            may provide a helpful guide to you.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.owner.visibility}
        label={<Label>Ownership</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.owner"
            options={['O', 'M', 'S']}
            namer={ownerNames}
          />
        }
        info={
          <InfoTooltip title="Ownership">
            {config.owner.choices.includes('O') && (
              <>
                Non-Super - The policy will be owned by the life insured or a
                company. <br />
              </>
            )}
            {config.owner.choices.includes('M') && (
              <>
                SMSF - The policy will be owned by a Self Managed Super Fund.
                <br />
              </>
            )}
            {config.owner.choices.includes('S') && (
              <>
                Super Fund - The policy will be owned by a Superannuation Fund.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.rollover.visibility}
        label={<Label>Pay by Rollover</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.rollover"
            options={['N', 'I']}
            namer={rolloverNames}
          />
        }
        info={
          <InfoTooltip title="Pay by Rollover">
            Are you intending to pay your premiums through an eligible
            superannuation fund? <br /> <br />
            {config.rollover.choices.includes('N') && (
              <>
                Exclude - Not paying premiums through a superannuation fund. No
                discount will apply. <br />
              </>
            )}
            {config.rollover.choices.includes('I') && (
              <>
                Include if possible - Apply the pay by rollover discount on all
                products that support it, also include products that don't
                support pay by rollover for a better comparison.
                <br />
              </>
            )}
            <br /> Note this option is only available on limited frequencies,
            please use Yearly for best results.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.structure.visibility}
        label={<Label>Structure</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.structure"
            options={['S', 'L', '70']}
            namer={structureNames}
          />
        }
        info={
          <InfoTooltip title="Structure">
            {config.structure.choices.includes('S') && (
              <>
                Stepped – Premiums will generally increase every year in line
                with the age of the insured. Premiums may vary due to sum
                insured changes including Consumer Price Index (CPI) movements
                or changes to the underlying premium rates set by the insurer.
                <br /> <br />
              </>
            )}
            {config.structure.choices.includes('L') && (
              <>
                Level to 65 – Premiums will not increase every year due to age
                until age 65. Premiums may vary due to sum insured changes
                including Consumer Price Index (CPI) movements or changes to the
                underlying premium rates set by the insurer. <br /> <br />
              </>
            )}
            {config.structure.choices.includes('70') && (
              <>
                Level to 70 – Premiums will not increase every year due to age
                until age 70. Premiums may vary due to sum insured changes
                including Consumer Price Index (CPI) movements or changes to the
                underlying premium rates set by the insurer.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.premiumWaiver.visibility}
        label={<Label>Premium Waiver</Label>}
        input={
          <OptionSelectorWithNamer
            name="TRM.premiumWaiver"
            options={['N', 'I', 'Y']}
            namer={premiumWaiverNames}
          />
        }
        info={
          <InfoTooltip title="Premium Waiver">
            {config.premiumWaiver.choices.includes('N') && (
              <>
                Exclude - All products must not contain premium waiver. Any
                products that have it built-in will not be used. <br />
              </>
            )}
            {config.premiumWaiver.choices.includes('I') && (
              <>
                Include if possible - If premium waiver is an option on the
                product, then take the option. Products that do not support
                premium waiver are also used. <br />
              </>
            )}
            {config.premiumWaiver.choices.includes('Y') && (
              <>
                Include - All products must contain premium waiver. <br />
              </>
            )}
            <br /> Note that for best results, please set all of your Premium
            Waiver settings to the same selection.
          </InfoTooltip>
        }
      />
    </>
  );
};

export default TRMForm;
