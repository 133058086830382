import produce from 'immer';
import { FunctionComponent } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Tag } from '../types/application.type';
import { TagViewEdit } from './TagViewEdit';

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  margin: `0 0 ${8}px 0`,
  background: isDragging ? '#d6eaf8' : 'white',
  ...draggableStyle,
});

export const TagList: FunctionComponent<{
  setTag: (id: string) => (tag: Tag) => void;
  removeTag: (id: string) => () => void;
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
}> = ({ tags, setTag, removeTag, setTags }) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    setTags(
      produce(tags, (draft) => {
        const [removed] = draft.splice(startIndex, 1);
        draft.splice(endIndex, 0, removed);
      })
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              padding: 10,
              paddingBottom: 1,
              backgroundColor: '#ebecf0',
            }}
          >
            {tags.map((e, index) => (
              <Draggable key={e.id} draggableId={e.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <TagViewEdit
                      tags={tags}
                      tag={e}
                      setTag={setTag(e.id)}
                      removeTag={removeTag(e.id)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {tags.length === 0 && <p>No Tags Added.</p>}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
