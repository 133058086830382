import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { OmnilifeApiCredsModel } from './omnilife-api-creds.model';
import { useRecoilState } from 'recoil';
import { adminCredsState } from '../../models/admin/credentials.model';
import { axiosAdmin } from '../../configs/axios.config';
import { getOmnilifeApiCreds } from './omnilife-api-creds.service';
import { AxiosError } from 'axios';
import { OmnilifeApiCredsForm } from './OmnilifeApiCredsForm';
import { useParams } from 'react-router';
import { Loading } from 'src/common';

const OmnilifeApiCreds: FunctionComponent = () => {
  const { code } = useParams<{ code: string }>();
  const [appSettings, setAppSettings] = useState<OmnilifeApiCredsModel>();
  const [creds] = useRecoilState(adminCredsState);

  const fetchAppSettings = useCallback(() => {
    setAppSettings(undefined);
    axiosAdmin(getOmnilifeApiCreds(creds, code))
      .then((d) => {
        setAppSettings(d.data || { url: '', username: '', password: '' });
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 404)
          setAppSettings({ url: '', username: '', password: '' });
      });
  }, [creds, code]);

  useEffect(() => {
    fetchAppSettings();
  }, [fetchAppSettings]);

  if (!appSettings) return <Loading />;

  return (
    <OmnilifeApiCredsForm
      code={code}
      defaultValues={appSettings}
      fetchAppSettings={fetchAppSettings}
    />
  );
};

export default OmnilifeApiCreds;
