import _ from 'lodash';
import { INCConfig, Visibility } from './../config.model';

/** Income Protection */
export interface INC {
  monthlyBenefit: number;
  superContributionOption: number;
  owner: 'O' | 'M' | 'S' | 'J' | 'K' | undefined;
  rollover: 'N' | 'I' | undefined;
  structure: 'S' | 'L' | undefined;
  agreedValue: 'X' | 'N' | undefined;
  accidentBenefit: 'X' | 'I' | 'Y' | 'N' | undefined;
  increaseClaimBenefit: 'X' | 'I' | 'Y' | 'N' | undefined;
  waitingPeriod: 14 | 30 | 60 | 90 | 180 | 365 | 730 | undefined;
  benefitPeriod: 1 | 2 | 5 | 65 | 70 | undefined;
  priority: 'C' | 'B' | 'I' | undefined;
  annualIncomeVisible: Visibility | undefined;
}

export function getINC(config: INCConfig): INC {
  return {
    monthlyBenefit: config.monthlyBenefit.default,
    superContributionOption: config.superContributionOption?.default,
    owner: config.owner.default,
    rollover: config.rollover.default,
    structure: config.structure.default,
    agreedValue: config.agreedValue.default,
    accidentBenefit: config.accidentBenefit.default,
    increaseClaimBenefit: config.increaseClaimBenefit.default,
    waitingPeriod: config.waitingPeriod.default,
    benefitPeriod: config.benefitPeriod.default,
    priority: config.priority.default,
    annualIncomeVisible: config.annualIncomeVisible,
  };
}

export function INCValid(inc: INC, config: INCConfig) {
  return (
    inc.monthlyBenefit > 0 &&
    _.toPairs(inc).every(([k, v]) => {
      if (_.get(config, k + '.visibility') === undefined) return true; //this works in case new fields are added to the model and old configs don't have them
      return _.get(config, k + '.visibility') === 'hidden' || v !== undefined;
    })
  );
}
