import { Box } from '@mui/material';
import { FC } from 'react';
import { Visibility } from '../models/config.model';

const FormRow: FC<{
  label: React.ReactNode;
  input: React.ReactNode;
  info?: React.ReactNode;
  sx?: React.CSSProperties;
  visibility?: Visibility;
  comment?: string;
}> = ({ label, input, info, visibility = 'visible', sx, comment }) =>
  visibility === 'visible' ? (
    <Box sx={sx} mb={2} display="flex" alignItems="start" flexWrap="wrap">
      <Box width={180} mt={1}>
        {label}
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <Box>{input}</Box>
        <Box>{info}</Box>
      </Box>
      {comment && <span style={{ fontSize: 12, width: 500 }}>{comment}</span>}
    </Box>
  ) : null;

export default FormRow;
