import { Button, ButtonGroup, Menu, MenuItem, Paper } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { FC, FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ChangeCodeMenuItem from './Config/ChangeCodeMenuItem';
import CloneConfigMenuItem from './Config/CloneConfigMenuItem';
import DeleteConfigMenuItem from './Config/DeleteConfigMenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ConfigsTable: FunctionComponent<{
  configs: { code: string }[];
  loading: boolean;
  fetchConfigs: () => void;
}> = ({ configs, loading, fetchConfigs }) => {
  const url = window.origin;

  const rows: GridRowsProp = configs.map((c) => ({
    id: c.code,
    code: c.code,
    actions: c.code,
  }));

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Code',
      align: 'center',
      headerAlign: 'center',
      flex: 2,
      hideable: false,
    },
    {
      flex: 1,
      field: 'actions',
      headerName: 'Actions',
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      sortable: false,
      hideable: false,
      renderCell: ({ value: code }) => (
        <ConfigActions code={code} fetchConfigs={fetchConfigs} url={url} />
      ),
    },
  ];

  return (
    <Paper>
      <DataGrid
        loading={loading}
        columns={columns}
        rows={rows}
        autoHeight
        hideFooter
      />
    </Paper>
  );
};

export default ConfigsTable;

const ConfigActions: FC<{
  code: string;
  fetchConfigs: () => void;
  url: string;
}> = ({ code, fetchConfigs, url }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const closeMenu = () => setAnchorEl(null);
  const history = useHistory();

  return (
    <ButtonGroup variant="contained">
      <Button onClick={() => history.push('/config/' + code)}>Edit</Button>

      <Button onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
        <MoreHorizIcon />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${url}?code=${code}`}
          style={{ color: 'rgba(0, 0, 0, 0.87)' }}
          onClick={closeMenu}
        >
          <MenuItem>View</MenuItem>
        </a>

        <CloneConfigMenuItem
          code={code}
          fetchConfigs={fetchConfigs}
          closeMenu={closeMenu}
        />

        <ChangeCodeMenuItem
          code={code}
          fetchConfigs={fetchConfigs}
          closeMenu={closeMenu}
        />

        <MenuItem onClick={() => history.push(`/config/${code}/application`)}>
          Apply Form
        </MenuItem>

        <MenuItem onClick={() => history.push(`/config/${code}/creds`)}>
          Api Creds
        </MenuItem>

        <DeleteConfigMenuItem
          code={code}
          fetchConfigs={fetchConfigs}
          closeMenu={closeMenu}
        />
      </Menu>
    </ButtonGroup>
  );
};
