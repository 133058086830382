export function occupationTypeNames(
  key: 'A' | 'O' | 'H' | 'D' | 'E' | undefined
): string {
  if (key === undefined) return '';
  else
    return {
      A: 'Any',
      O: 'Own',
      H: 'Homemaker',
      D: 'ADL',
      E: 'Best available',
    }[key];
}
