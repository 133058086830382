import { Box } from '@mui/material';
import { get } from 'lodash';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';

const AmountConfigInput: FunctionComponent<{
  name: string;
  required: boolean;
}> = ({ name, required }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Box display="flex">
      <div style={{ marginBottom: 10 }}>
        <select {...register(name + '.visibility')}>
          <option value="visible">Visible</option>
          <option value="hidden">Hidden</option>
        </select>
      </div>

      <div style={{ marginLeft: 30, marginBottom: 10 }}>
        <label>Default </label>
        <input
          type="number"
          {...register(name + '.default', {
            required,
            valueAsNumber: true,
            min: 0,
            max: 10_000_000_000,
          })}
        />
        <div style={{ height: 10 }}>
          {get(errors, name + '.default') && (
            <p style={{ color: 'red' }}>Please provide a valid value</p>
          )}
        </div>
      </div>
    </Box>
  );
};

export default AmountConfigInput;
