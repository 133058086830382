import { FunctionComponent } from 'react';
import { CustomCard } from 'src/common';
import AmountConfigInput from '../../../common/AmountConfigInput';
import ChoiceConfigInput from '../../../common/ChoiceConfigInput';
import FormRow from '../../../common/FormRow';

const TPDConfigForm: FunctionComponent = () => {
  return (
    <>
      <CustomCard title="TPS">
        <FormRow
          label={<label>Amount:</label>}
          input={
            <AmountConfigInput name="quotes.TPD.sumInsured" required={true} />
          }
        />

        <FormRow
          label={<label>Ownership:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPD.owner"
              choices={['O', 'M', 'S', 'J']}
            />
          }
        />

        <FormRow
          label={<label>Rollover:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPD.rollover"
              choices={['N', 'I']}
            />
          }
        />

        <FormRow
          label={<label>Structure:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPD.structure"
              choices={['S', 'L', '70']}
            />
          }
        />

        <FormRow
          label={<label>Occupation Type:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPD.occupationType"
              choices={['A', 'O', 'H', 'D', 'E']}
            />
          }
        />

        <FormRow
          label={<label>Premium Waiver:</label>}
          input={
            <ChoiceConfigInput
              name="quotes.TPD.premiumWaiver"
              choices={['N', 'I', 'Y']}
            />
          }
        />
      </CustomCard>
    </>
  );
};

export default TPDConfigForm;
