import _ from 'lodash';
import { BUSConfig } from '../config.model';

/** Business Expenses */
export interface BUS {
  monthlyBenefit: number;
  structure: 'S' | 'L' | undefined;
  waitingPeriod: 14 | 30 | 60 | 90 | undefined;
  benefitPeriod: 1 | undefined;
}

export function getBUS(config: BUSConfig): BUS {
  return {
    monthlyBenefit: config.monthlyBenefit.default,
    structure: config.structure.default,
    waitingPeriod: config.waitingPeriod.default,
    benefitPeriod: config.benefitPeriod.default,
  };
}

export function BUSValid(bus: BUS, config: BUSConfig) {
  return (
    bus.monthlyBenefit > 0 &&
    _.toPairs(bus).every(
      ([k, v]) =>
        _.get(config, k + '.visibility') === 'hidden' || v !== undefined
    )
  );
}
