export function lifeBuyBackNames(
  key: 'L' | 'F' | 'N' | '0' | '1' | '3' | undefined
): string {
  if (key === undefined) return '';
  else
    return {
      L: 'Exclude if Possible',
      F: 'Best available',
      N: 'Exclude',
      '0': 'Immediate',
      '1': '1 year',
      '3': '3 years',
    }[key];
}
