import _ from 'lodash';
import { FunctionComponent, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import {
  LinkedFieldName,
  linkedFieldNames,
  Tag,
  TagType,
  tagTypes,
} from '../types/application.type';
import { TagId } from './TagId';
import { Autocomplete, Box, Button, Chip, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';

export const TagEdit: FunctionComponent<{
  tags: Tag[];
  tag: Tag;
  setTag: (tag: Tag) => void;
  setEditing: (editing: boolean) => void;
  removeTag: () => void;
}> = ({ tag, setTag, setEditing, removeTag, tags }) => {
  const ref = useRef<ReactQuill>(null);
  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <TagId id={tag.id} />

        <Box>
          <Button
            onClick={removeTag}
            startIcon={<DeleteIcon />}
            variant="contained"
            color="error"
            size="small"
            sx={{ mr: 4 }}
          >
            Delete
          </Button>
          <Button
            onClick={() => setEditing(false)}
            startIcon={<ClearIcon />}
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </Box>
      </Box>

      <Box my={2} display="flex" alignItems="center">
        <div style={{ width: 100 }}>
          <b>Type</b>
        </div>
        <select
          value={tag.type}
          onChange={(e) =>
            setTag({
              ...tag,
              type: e.target.value as TagType,
              linkedFieldName: undefined,
            })
          }
        >
          {tagTypes.map((tt) => (
            <option key={tt} value={tt}>
              {_.startCase(tt)}
            </option>
          ))}
        </select>
      </Box>

      <Box mb={2} display="flex" alignItems="center">
        <div style={{ width: 120 }}>
          <b>Label</b>
        </div>
        <ReactQuill
          style={{ width: '100%' }}
          value={tag.label}
          onChange={(v) => setTag({ ...tag, label: v })}
          ref={ref}
        />
      </Box>

      {tag.type === 'Select' && (
        <Box mb={2} display="flex" alignItems="center">
          <div style={{ width: 120 }}>
            <b>Options</b>
          </div>
          <Autocomplete
            multiple
            options={[]}
            fullWidth
            onChange={(_e, v) => setTag({ ...tag, options: v })}
            value={tag.options}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  size="small"
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" />
            )}
            disableClearable
          />
        </Box>
      )}

      <Box mb={2} display="flex" alignItems="center">
        <div style={{ width: 100 }}>
          <b>Visibile</b>
        </div>

        <select
          value={tag.visibilityId}
          onChange={(e) =>
            setTag({
              ...tag,
              visibilityId: e.target.value,
              visibilityValue: undefined,
            })
          }
        >
          <option value={''}>Always</option>
          {tags
            .filter((t) => t.id !== tag.id)
            .filter((t) => t.type === 'Select')
            .map((t) => (
              <option key={t.id} value={t.id}>
                When {t.id.split('-')[0].toUpperCase()} is
              </option>
            ))}
        </select>

        {tag.visibilityId && (
          <select
            style={{ marginLeft: 5 }}
            value={tag.visibilityValue}
            onChange={(e) =>
              setTag({ ...tag, visibilityValue: e.target.value })
            }
          >
            <option value={undefined}></option>
            {tags
              .find((t) => t.id === tag.visibilityId)
              ?.options.map((o) => (
                <option key={o} value={o}>
                  {o}
                </option>
              ))}
          </select>
        )}
      </Box>

      {['TextInput', 'Date'].includes(tag.type) && (
        <Box mb={2} display="flex" alignItems="center">
          <div style={{ width: 100 }}>
            <b>Prepopulation</b>
          </div>

          <select
            value={tag.linkedFieldName}
            onChange={(e) =>
              setTag({
                ...tag,
                linkedFieldName: e.target.value as LinkedFieldName,
              })
            }
          >
            <option value={''}>Off</option>
            {linkedFieldNames.map((o) => (
              <option key={o} value={o}>
                From {_.startCase(o)}
              </option>
            ))}
          </select>
        </Box>
      )}
    </>
  );
};
