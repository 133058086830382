import { FunctionComponent } from 'react';
import Stepper from './steps/Stepper';
import { useConfigContext } from './configs/config.context';
import Banner from './common/Banner';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const AppLayout: FunctionComponent<{}> = () => {
  const config = useConfigContext();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box>
      <Box
        style={{
          background: 'whitesmoke',
          textAlign: 'center',
          height: '100px',
        }}
      >
        {config.display.mainLogoUrl && (
          <img height={100} alt="logo" src={config.display.mainLogoUrl} />
        )}
      </Box>

      <Box
        style={{
          background: 'white',
          minHeight: 'calc(100vh - 290px)',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <Stepper />
        <Banner />
      </Box>

      <Box
        style={{
          background: 'whitesmoke',
          textAlign: 'center',
          //height: '150px',
          padding: '24px 50px',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ marginTop: md ? 25 : -12 }}>
          <p>
            This portal, the premium estimate and comparison report have been
            prepared and developed by{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.omnium.com.au"
            >
              Omnium
            </a>
          </p>
          {config.display.footerNote2 === 'visible' && (
            <p>
              Advisors, create your own Customised LiveQuotes Tool{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.omnium.com.au/livequotes/"
              >
                here
              </a>
            </p>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default AppLayout;
