import { atom } from 'recoil';

export interface Credentials {
  username: string;
  password: string;
}

export const adminCredsState = atom<Credentials | undefined>({
  key: 'admin_creds',
  default: undefined,
});
