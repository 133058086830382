import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomCard } from 'src/common';
import FormRow from '../../common/FormRow';

export const UserTrackingForm: FunctionComponent<{}> = () => {
  const { register, watch } = useFormContext();

  const gtmEnabled = watch('tracking.gtmEnabled');

  return (
    <CustomCard title="Google Tag Manager">
      <FormRow
        label={<label>GTM Enabled:</label>}
        input={<input type="checkbox" {...register('tracking.gtmEnabled')} />}
      />

      {gtmEnabled && (
        <FormRow
          label={<label>GTM Id:</label>}
          input={<input {...register('tracking.gtmId')} />}
        />
      )}
    </CustomCard>
  );
};
