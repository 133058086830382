import {
  Dialog,
  DialogContent,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { DialogTitleWithCloseButton } from 'src/common';
import { useBreakpoint } from 'src/hooks';
import { Frequency, Portfolio } from '../../models/quote-response.model';
import PremiumBreakdown from './PremiumBreakdown';
import { useConfigContext } from 'src/configs/config.context';

const Premium: FunctionComponent<{ portfolio: Portfolio; freq: Frequency }> = ({
  portfolio,
  freq,
}) => {
  const config = useConfigContext();
  const [showModal, setShowModal] = useState(false);
  const total = Math.max(
    portfolio.premiumTotal.premium[freq],
    portfolio.premiumTotal.minimum[freq]
  );
  const { xs } = useBreakpoint();

  return (
    <>
      <Link onClick={() => setShowModal(true)} underline="none">
        <Typography
          variant="body2"
          sx={{
            color: config.display.premiumColor
              ? config.display.premiumColor
              : '#1976D2',
          }}
        >
          ${total.toFixed(2)}
        </Typography>
      </Link>

      <Dialog
        fullWidth
        fullScreen={xs}
        open={showModal}
        maxWidth="sm"
        onClose={() => setShowModal(false)}
      >
        <DialogTitleWithCloseButton closeModal={() => setShowModal(false)}>
          Quote Details for {portfolio.supplier.name}
        </DialogTitleWithCloseButton>

        <Divider sx={{ m: 0 }} />

        <DialogContent>
          <PremiumBreakdown portfolio={portfolio} freq={freq} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Premium;
