import { FunctionComponent, ReactNode } from 'react';
import useScrollToTop from '../../hooks/useScrollToTop';
import { SelectedCoverTypes } from '../../models/selected-cover-types.model';
import NavButtons from '../NavButtons';
import { useConfigContext } from '../../configs/config.context';
import { NeedString } from '../../models/needs/need.model';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useGTMEvent } from '../../hooks/gtmHooks';
import { Checkbox, Box, Divider } from '@mui/material';

export interface YourCoverProps {
  coverTypesForm: UseFormReturn<SelectedCoverTypes, object>;
}

const YourCover: FunctionComponent<YourCoverProps> = ({
  coverTypesForm: {
    control,
    formState: { isValid },
  },
}) => {
  useGTMEvent('LISTPage1Cover', { page: 'Your Cover' });

  useScrollToTop();
  const config = useConfigContext();

  const checkboxes: {
    key: NeedString;
    node: ReactNode;
    subNodes?: {
      key: 'TPE' | 'TRE' | 'TPR';
      node: ReactNode;
    }[];
  }[] = [
    {
      key: 'TRM',
      node: (
        <>
          <b>Life Cover</b>
          <p>
            Protecting your future. In the event of death or terminal illness,
            Life Cover can provide a lump sum benefit.
          </p>
        </>
      ),
      subNodes: [
        {
          key: 'TPE',
          node: (
            <>
              <b>TPD Extension</b>
              <p>
                When an illness or injury permanently prevents you from working
                in your occupation or any occupation, Total and Permanent
                Disability Cover can provide a lump sum benefit.
              </p>
            </>
          ),
        },
        {
          key: 'TRE',
          node: (
            <>
              <b>Trauma Extension</b>
              <p>
                If you suffer from one of the listed major health events, Trauma
                Cover can provide you with the financial resources to make
                lifestyle or career adjustments to help you recover. Trauma can
                provide a lump sum benefit.
              </p>
            </>
          ),
        },
      ],
    },
    {
      key: 'TPD',
      node: (
        <>
          <b>Total and Permanent Disability Cover</b>
          <p>
            When an illness or injury permanently prevents you from working in
            your occupation or any occupation, Total and Permanent Disability
            Cover can provide a lump sum benefit.
          </p>
        </>
      ),
    },
    {
      key: 'TRS',
      node: (
        <>
          <b>Trauma Cover</b>
          <p>
            If you suffer from one of the listed major health events, Trauma
            Cover can provide you with the financial resources to make lifestyle
            or career adjustments to help you recover. Trauma can provide a lump
            sum benefit.
          </p>
        </>
      ),
      subNodes: [
        {
          key: 'TPR',
          node: (
            <>
              <b>TPD Extension</b>
              <p>
                When an illness or injury permanently prevents you from working
                in your occupation or any occupation, Total and Permanent
                Disability Cover can provide a lump sum benefit.
              </p>
            </>
          ),
        },
      ],
    },
    {
      key: 'INC',
      node: (
        <>
          <b>Income Protection Cover</b>
          <p>
            When illness or injury prevents you from working in your occupation
            or any occupation, Income Protection Cover supports you by paying a
            monthly benefit.
          </p>
        </>
      ),
    },
    {
      key: 'BUS',
      node: (
        <>
          <b>Business Expenses</b>
          <p>Up to 12 months income to a business on sickness or accident.</p>
        </>
      ),
    },
    {
      key: 'NES',
      node: (
        <>
          <b>Needle Stick</b>
          <p>Up to 12 months income to a needlestick related accident.</p>
        </>
      ),
    },
    {
      key: 'CHT',
      node: (
        <>
          <b>Child Trauma</b>
          <p>
            Protect your children with a Trauma policy, must be bundled together
            with some adult cover.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      {checkboxes.map(
        (c) =>
          config.covers[c.key] === 'visible' && (
            <div key={c.key}>
              <Controller
                control={control}
                name={c.key}
                render={({ field: { value, onChange } }) => (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                    >
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        sx={{ mt: -1 }}
                      />
                      <Box>{c.node}</Box>
                    </Box>

                    {value &&
                      config.covers.complexMode &&
                      c.subNodes?.map((s) => (
                        <Controller
                          key={s.key}
                          control={control}
                          name={s.key}
                          render={({
                            field: { value: svalue, onChange: sonChange },
                          }) => (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                              ml={3.5}
                            >
                              <Checkbox
                                checked={svalue}
                                onChange={sonChange}
                                sx={{ mt: -1 }}
                              />
                              <Box>{s.node}</Box>
                            </Box>
                          )}
                        />
                      ))}
                  </>
                )}
              />
            </div>
          )
      )}

      {config.covers.generalAdviceDisclaimer && (
        <>
          <Divider />

          <div
            dangerouslySetInnerHTML={{
              __html: config?.covers?.generalAdviceDisclaimer || '',
            }}
          />
        </>
      )}

      <NavButtons nextEnabled={isValid} />
    </>
  );
};

export default YourCover;
