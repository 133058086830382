import { AxiosRequestConfig } from 'axios';
import { Credentials } from 'src/models/admin/credentials.model';

export function getApplication(
  creds: Credentials | undefined,
  code: string
): AxiosRequestConfig {
  return {
    url: `/configs/${code}/application`,
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
  };
}
