import _ from 'lodash';

export function validateAppConfigs() {
  if (!process.env.REACT_APP_API_URL)
    throw new Error('API_URL config was not provided!');
  if (!process.env.REACT_APP_API_USERNAME)
    throw new Error('API_USERNAME config was not provided!');
  if (!process.env.REACT_APP_API_PASSWORD)
    throw new Error('API_PASSWORD config was not provided!');
}

export const API_URL: string = process.env.REACT_APP_API_URL || '';
export const API_USERNAME: string = process.env.REACT_APP_API_USERNAME || '';
export const API_PASSWORD: string = process.env.REACT_APP_API_PASSWORD || '';
export const RN: number = _.random(0, 1, true);
