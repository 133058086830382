import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { Link, MemoryRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ApplicationEdit } from 'src/features/application';
import { adminCredsState } from '../models/admin/credentials.model';
import OmnilifeApiCreds from './AppSettings/OmnilifeApiCreds';
import Config from './Config';
import Configs from './Configs';
import Login from './Login';
import SettingsIcon from '@mui/icons-material/Settings';

const AdminLayout: FunctionComponent = () => {
  const [creds] = useRecoilState(adminCredsState);

  return creds ? (
    <Box display="flex">
      <MemoryRouter>
        <Box
          minWidth={200}
          borderRight="1px Solid rgba(0, 0, 0, 0.12)"
          minHeight="100vh"
        >
          <h3
            style={{
              textAlign: 'center',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            Admin Panel
          </h3>
          <Divider sx={{ m: 0 }} />
          <List>
            <ListItemButton component={Link} to="/configs">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>Configs</ListItemText>
            </ListItemButton>
          </List>
        </Box>

        <Box flex={1} padding={3} sx={{ backgroundColor: '#f0f2f5' }}>
          <Switch>
            <Route path="/configs">
              <Configs />
            </Route>
            <Route path="/config/:code/creds">
              <OmnilifeApiCreds />
            </Route>
            <Route path="/config/:code/application">
              <ApplicationEdit />
            </Route>
            <Route path="/config/:code">
              <Config />
            </Route>
            <Route path="/">
              <Redirect to="/configs" />
            </Route>
          </Switch>
        </Box>
      </MemoryRouter>
    </Box>
  ) : (
    <Login />
  );
};

export default AdminLayout;
