import { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const CustomSelect: FC<{
  value: any;
  onChange: (value: any) => void;
  options: { label: string; value: any }[] | string[];
  width?: number;
  error?: boolean;
  label?: string;
  disabled?: boolean;
}> = ({ value, onChange, options, error, label, disabled, width = 200 }) => (
  <FormControl disabled={disabled} size="small" error={error}>
    {label && <InputLabel id="label">{label}</InputLabel>}
    <Select
      labelId="label"
      label={label}
      value={value == null ? '' : value}
      onChange={(e) => onChange(e.target.value)}
      sx={{ width }}
    >
      {options.map((o) => {
        const { label, value } =
          typeof o === 'string' ? { label: o, value: o } : o;

        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
);
