import { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import useAxios from 'axios-hooks';
import { RecoilRoot } from 'recoil';
import AdminLayout from './admin/AdminLayout';
import AppLayout from './AppLayout';
import { Loading } from 'src/common';
import { ConfigContext } from './configs/config.context';
import { useGTMInit } from './hooks/gtmHooks';
import { Config, defaultConfig } from './models/config.model';
import { configRequest } from './services/config.service';

// Date Picker
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/en-au';
import { Toaster } from 'react-hot-toast';

moment.locale('en-au');

// Theme
const theme = createTheme({
  components: {
    MuiDivider: {
      defaultProps: {
        sx: { my: 3 },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        disableElevation: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: { justifyContent: 'center' },
      },
    },
  },
});

export default function App() {
  // Config Fetching
  const code = new URLSearchParams(window.location.search).get('code');
  const path = window.location.pathname;
  const [{ data, loading, error }] = useAxios<Config>(configRequest(code));

  useEffect(() => {
    const primaryColor = data?.display.appColor;
    theme.palette.primary.main = primaryColor
      ? primaryColor
      : theme.palette.primary.main;

    const secondaryColor = data?.display.secondaryAppColor;
    theme.palette.primary.dark = secondaryColor
      ? secondaryColor
      : theme.palette.primary.dark;
  }, [data]);

  // Google Tag Manager Conditional Initialization
  useGTMInit(data?.tracking);

  if (path === '/admin')
    return (
      <RecoilRoot>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AdminLayout />
        </LocalizationProvider>
        <Toaster
          position="top-center"
          toastOptions={{ style: { borderRadius: 4 } }}
        />
      </RecoilRoot>
    );

  if (loading) return <Loading />;

  if (error) throw error;

  console.log('Config: ', data);

  return (
    <RecoilRoot>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <ConfigContext.Provider value={data || defaultConfig()}>
            <AppLayout />
          </ConfigContext.Provider>
        </ThemeProvider>
      </LocalizationProvider>
      <Toaster
        position="top-center"
        toastOptions={{ style: { borderRadius: 4 } }}
      />
    </RecoilRoot>
  );
}
