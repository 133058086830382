import {
  Box,
  Button,
  Dialog,
  DialogContent,
  MenuItem,
  TextField,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { DialogTitleWithCloseButton } from 'src/common';
import { axiosAdmin } from '../../configs/axios.config';
import { adminCredsState } from '../../models/admin/credentials.model';
import { changeConfigCodeRequest } from '../../services/config.service';

const ChangeCodeMenuItem: FunctionComponent<{
  code: string;
  fetchConfigs: () => void;
  closeMenu: () => void;
}> = ({ code, fetchConfigs, closeMenu }) => {
  const [creds] = useRecoilState(adminCredsState);
  const [loading, setLoading] = useState(false);
  const [newCode, setNewCode] = useState(code);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const changeConfigCode = () => {
    setLoading(true);
    axiosAdmin(changeConfigCodeRequest(code, newCode, creds))
      .then(() => {
        setNewCode('');
        setIsModalVisible(false);
        fetchConfigs();
      })
      .catch((e: AxiosError) => {
        toast.error(e.response?.data?.detail || 'Internal Error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          setIsModalVisible(true);
          closeMenu();
        }}
      >
        Change Code
      </MenuItem>

      <Dialog
        open={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          setLoading(false);
          setNewCode('');
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitleWithCloseButton closeModal={() => setIsModalVisible(false)}>
          Change Config Code
        </DialogTitleWithCloseButton>

        <DialogContent>
          <TextField
            sx={{ my: 2 }}
            fullWidth
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
            label="New Code"
            error={newCode.length === 0 || newCode === code}
          />

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="outlined" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={changeConfigCode}
              disabled={loading || newCode.length === 0 || newCode === code}
            >
              Change
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangeCodeMenuItem;
