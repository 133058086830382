import { atom, useRecoilState } from 'recoil';

const portfolioCodesState = atom<string[]>({
  key: 'portfolioCodesState',
  default: [],
});

const usePorfolioCodesState = () => useRecoilState(portfolioCodesState);

export default usePorfolioCodesState;
