import { Credentials } from './../models/admin/credentials.model';
import { AxiosRequestConfig } from 'axios';
import { Config } from '../models/config.model';

export function configRequest(code: string | null): AxiosRequestConfig {
  if (code === null) code = 'all';
  return {
    url: '/configs/' + code,
  };
}

export function configsRequest(
  creds: Credentials | undefined
): AxiosRequestConfig {
  return {
    url: '/configs',
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
  };
}

export function updateConfigRequest(
  code: string,
  data: Config,
  creds: Credentials | undefined
): AxiosRequestConfig {
  return {
    url: '/configs/' + code,
    method: 'PUT',
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
    data: data,
  };
}

export function createConfigRequest(
  code: string,
  data: Config,
  creds: Credentials | undefined
): AxiosRequestConfig {
  return {
    url: '/configs/' + code,
    method: 'POST',
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
    data: data,
  };
}

export function cloneConfigRequest(
  code: string,
  creds: Credentials | undefined
): AxiosRequestConfig {
  return {
    url: '/configs/clone/' + code,
    method: 'POST',
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
  };
}

export function changeConfigCodeRequest(
  code: string,
  newCode: string,
  creds: Credentials | undefined
): AxiosRequestConfig {
  return {
    url: `/configs/change/${code}/${newCode}`,
    method: 'POST',
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
  };
}

export function deleteConfigRequest(
  code: string,
  creds: Credentials | undefined
): AxiosRequestConfig {
  return {
    url: '/configs/' + code,
    method: 'DELETE',
    auth: {
      username: creds?.username || '',
      password: creds?.password || '',
    },
  };
}
