import axios from 'axios';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useConfigContext } from 'src/configs/config.context';
import { useCodeContext } from 'src/hooks/useCodeContext';
import { ClientInput } from 'src/models/client-input.model';
import { NeedDict } from 'src/models/needs/need.model';
import {
  createQuoteRequest,
  QuoteRequest,
} from 'src/models/quote-request.model';
import {
  Frequency,
  Portfolio,
  QuoteResponse,
} from 'src/models/quote-response.model';
import { SelectedCoverTypes } from 'src/models/selected-cover-types.model';
import { quotesRequest } from 'src/services/omnilife.service';
import { useFrequencyState } from '../FrequencySelector';
import usePorfolioCodesState from '../stores/portfolioCodesState';

const getPortfolios = (
  data: QuoteResponse | undefined,
  freq: Frequency
): Portfolio[] =>
  _.orderBy(
    data?.clients[0]?.portfolios
      .filter((p) => p.premiumTotal.premium[freq] > 0)
      .filter((p) => p.allNeedsMet) || [],
    ['premiumTotal.premium.' + freq],
    ['asc']
  );

export const useQuoteRequest = (
  coverTypes: SelectedCoverTypes,
  needs: NeedDict,
  client: ClientInput
) => {
  const code = useCodeContext();
  const config = useConfigContext();
  const [freq] = useFrequencyState();
  const [, setPorfolioCodes] = usePorfolioCodesState();

  const [quoteRequest, setQuoteRequest] = useState<QuoteRequest>();
  const [portfolios, setPorfolios] = useState<Portfolio[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const qRequest = createQuoteRequest(client, coverTypes, needs, config);
    setQuoteRequest(qRequest);
    axios(quotesRequest(qRequest, code)).then((res) => {
      const _portfolios = getPortfolios(res.data, freq);

      setPorfolios(_portfolios);
      setPorfolioCodes(_portfolios.map((p) => p.code));
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    portfolios,
    quoteRequest,
  };
};
