import _ from 'lodash';
import { FunctionComponent } from 'react';
import {
  Frequency,
  FrequencyName,
  Portfolio,
} from '../../models/quote-response.model';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { bordersStyle, headerStyle } from 'src/utils';

interface PremiumBreakdownProps {
  portfolio: Portfolio;
  freq: Frequency;
}

interface BreakDownLine {
  code?: string;
  name: string;
  amount: number;
  bold: boolean;
}

function getBreakDown({
  portfolio,
  freq,
}: PremiumBreakdownProps): BreakDownLine[] {
  const order = ['TRM', 'TPD', 'TRA', 'INC', 'BUS', 'NES', 'CHT', 'FEE'];

  const premiums: BreakDownLine[] = _.flatten(
    portfolio.premiumTotal.breakdown?.map(
      (b) =>
        b.breakdown?.map((bb) => ({
          code: bb.code,
          name: bb.description,
          amount: bb.premium[freq],
          bold: false,
        })) || []
    )
  );

  const orderedPremiums = order
    .map((o) => premiums.find((p) => p.code === o))
    .filter((p) => p !== undefined) as BreakDownLine[];

  const summary: BreakDownLine[] = [
    {
      name: 'Subtotal',
      amount: portfolio.premiumTotal.premium[freq],
      bold: true,
    },
    {
      name: 'Including Stamp Duty',
      amount: portfolio.premiumTotal.stampDuty[freq],
      bold: false,
    },
    {
      name: 'Total',
      amount: Math.max(
        portfolio.premiumTotal.premium[freq],
        portfolio.premiumTotal.minimum[freq]
      ),
      bold: true,
    },
  ];

  if (
    portfolio.premiumTotal.premium[freq] < portfolio.premiumTotal.minimum[freq]
  )
    summary.splice(summary.length - 1, 0, {
      name: 'Minimum premium',
      amount: portfolio.premiumTotal.minimum[freq],
      bold: false,
    });

  return _.concat(orderedPremiums, summary);
}

const PremiumBreakdown: FunctionComponent<PremiumBreakdownProps> = (props) => {
  const data = getBreakDown(props);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={[bordersStyle, headerStyle]}>
            <Typography fontWeight="bold" variant="body2">
              Covered Needs
            </Typography>
          </TableCell>
          <TableCell sx={[bordersStyle, headerStyle, { textAlign: 'right' }]}>
            <Typography fontWeight="bold" variant="body2">
              {FrequencyName[props.freq]} Premium
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map((row) => (
          <TableRow key={row.name} sx={row.bold ? headerStyle : null}>
            <TableCell
              width="50%"
              sx={[bordersStyle, row.bold ? headerStyle : null]}
            >
              <Typography
                variant="body2"
                fontWeight={row.bold ? 'bold' : 'normal'}
              >
                {row.name}
              </Typography>
            </TableCell>
            <TableCell sx={[bordersStyle, { textAlign: 'right' }]} width="50%">
              <Typography
                variant="body2"
                fontWeight={row.bold ? 'bold' : 'normal'}
              >
                ${row.amount.toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PremiumBreakdown;
