import { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { axiosAdmin } from '../../configs/axios.config';
import { adminCredsState } from '../../models/admin/credentials.model';
import { Config } from '../../models/config.model';
import { updateConfigRequest } from '../../services/config.service';
import { YourCoversConfigForm } from './YourCoversConfigForm';
import DisplayOptionsForm from './DisplayOptionsForm';
import OutputSettingsForm from './OutputSettingsForm';
import CoverOptions from './CoverOptions/CoverOptions';
import YourDetailsConfigFrom from './YourDetailsConfigForm';
import ReportConfigForm from './ReportConfigForm';
import { UserTrackingForm } from './UserTrackingForm';
import { ApplyFormForm } from './ApplyFormForm';
import { toast } from 'react-hot-toast';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Breadcrumbs, Link, Tab, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import { suppliersRequest } from 'src/services/omnilife.service';
import { SuppliersResponce } from 'src/models/campaign-options.model';

const width = 100;

const ConfigForm: FunctionComponent<{ code: string; initial: Config }> = ({
  code,
  initial,
}) => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [creds] = useRecoilState(adminCredsState);
  const form = useForm<any>({ defaultValues: initial, mode: 'onChange' });
  const [supplierCampaignOptions, setSupplierCampaignOptions] = useState<
    SuppliersResponce[]
  >([]);

  const [tab, setTab] = useState('0');

  const onSubmit = (data: any) => {
    setSubmitting(true);
    axiosAdmin(updateConfigRequest(code, data, creds)).finally(() => {
      setSubmitting(false);
      toast.success('Config Changes Saved');
    });
  };

  useEffect(() => {
    axios(suppliersRequest(code)).then((response) => {
      setSupplierCampaignOptions(
        response.data.map((c: SuppliersResponce) => ({
          code: c.code,
          campaignOptions: c.campaignOptions,
        }))
      );
    });
  }, [code]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <TabContext value={tab}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={1}
            borderColor="divider"
            mt={-3}
          >
            <Breadcrumbs>
              <Link
                underline="hover"
                color="inherit"
                onClick={() => history.push('/configs')}
              >
                Configs
              </Link>
              <Typography color="text.primary">{code}</Typography>
            </Breadcrumbs>

            <TabList onChange={(_e, v) => setTab(v)} variant="fullWidth">
              <Tab sx={{ width }} label="Your Covers" value="0" />
              <Tab sx={{ width }} label="Cover Options" value="1" />
              <Tab sx={{ width }} label="Your Details Options" value="2" />
              <Tab sx={{ width }} label="Display Options" value="3" />
              <Tab sx={{ width }} label="Output Settings" value="4" />
              <Tab sx={{ width }} label="Report Settings" value="5" />
              <Tab sx={{ width }} label="User Tracking" value="6" />
              <Tab sx={{ width }} label="Apply Form" value="7" />
            </TabList>

            <LoadingButton
              type="submit"
              size="small"
              variant="contained"
              loading={submitting}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              disabled={!form.formState.isValid}
            >
              Save
            </LoadingButton>
          </Box>

          <Box sx={{ maxHeight: 'calc(100vh - 110px)', overflowY: 'auto' }}>
            <TabPanel value="0">
              <YourCoversConfigForm />
            </TabPanel>

            <TabPanel value="1">
              <CoverOptions />
            </TabPanel>

            <TabPanel value="2">
              <YourDetailsConfigFrom />
            </TabPanel>

            <TabPanel value="3">
              <DisplayOptionsForm />
            </TabPanel>

            <TabPanel value="4">
              <OutputSettingsForm
                supplierCampaignOptions={supplierCampaignOptions}
              />
            </TabPanel>

            <TabPanel value="5">
              <ReportConfigForm />
            </TabPanel>

            <TabPanel value="6">
              <UserTrackingForm />
            </TabPanel>

            <TabPanel value="7">
              <ApplyFormForm code={code} />
            </TabPanel>
          </Box>
        </TabContext>
      </form>
    </FormProvider>
  );
};

export default ConfigForm;
