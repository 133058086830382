import axios from 'axios';
import { API_URL, API_PASSWORD, API_USERNAME } from './app.config';

export function configureAxios() {
  axios.defaults.baseURL = API_URL;

  axios.interceptors.request.use((req) => {
    if (req.url?.includes('quote')) console.log('Request: ', req.data);

    req.auth = {
      username: API_USERNAME,
      password: API_PASSWORD,
    };
    return req;
  });

  axios.interceptors.response.use((res) => {
    if (res.data.requestId) console.log('Response: ', res.data);
    return res;
  });
}

export const axiosAdmin = axios.create({
  baseURL: API_URL,
});
