export interface SelectedCoverTypes {
  TRM: boolean;
  TPE?: boolean;
  TRE?: boolean;
  TPD: boolean;
  TRS: boolean;
  TPR?: boolean;
  INC: boolean;
  BUS: boolean;
  NES: boolean;
  CHT: boolean;
}

export function getSelectedCoverTypes(): SelectedCoverTypes {
  const defValue = false;
  return {
    TRM: defValue,
    TPE: defValue,
    TRE: defValue,
    TPD: defValue,
    TRS: defValue,
    TPR: defValue,
    INC: defValue,
    BUS: defValue,
    NES: defValue,
    CHT: defValue,
  };
}

export function anySelected(ct: SelectedCoverTypes): boolean {
  return ct.TRM || ct.TPD || ct.TRS || ct.INC || ct.BUS || ct.NES || ct.CHT;
}
