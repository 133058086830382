import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useConfigContext } from '../configs/config.context';
import { Tracking } from '../models/config.model';

export const useGTMInit = (tracking: Tracking | undefined) => {
  useEffect(() => {
    if (tracking?.gtmEnabled && tracking?.gtmId)
      TagManager.initialize({ gtmId: tracking.gtmId });
  }, [tracking]);
};

export const useGTMEvent = (event: string, params: Record<string, string>) => {
  const tracking: Tracking | undefined = useConfigContext().tracking;

  useEffect(() => {
    if (tracking?.gtmEnabled)
      TagManager.dataLayer({
        dataLayer: {
          event: event,
          ...params,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracking?.gtmEnabled, event, JSON.stringify(params)]);
};
