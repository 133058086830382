import _ from 'lodash';
import { TPDConfig } from '../config.model';

/** Total and Permanent Disability */
export interface TPD {
  sumInsured: number;
  owner: 'O' | 'M' | 'S' | 'J' | undefined;
  rollover: 'N' | 'I' | undefined;
  structure: 'S' | 'L' | '70' | undefined;
  occupationType: 'A' | 'O' | 'H' | 'D' | 'E' | undefined;
  premiumWaiver: 'N' | 'I' | 'Y' | undefined;
}

export function getTPD(config: TPDConfig): TPD {
  return {
    sumInsured: config.sumInsured.default,
    owner: config.owner.default,
    rollover: config.rollover.default,
    structure: config.structure.default,
    occupationType: config.occupationType.default,
    premiumWaiver: config.premiumWaiver.default,
  };
}

export function TPDValid(tpd: TPD, config: TPDConfig) {
  return (
    tpd.sumInsured > 0 &&
    _.toPairs(tpd).every(
      ([k, v]) =>
        _.get(config, k + '.visibility') === 'hidden' || v !== undefined
    )
  );
}
