import { FunctionComponent } from 'react';
import { accidentBenefitNames } from '../../../models/dictionaries/accidentBenefit.model';
import { agreedValueNames } from '../../../models/dictionaries/agreedValue.model';
import { benefitPeriodNames } from '../../../models/dictionaries/benefitPeriod.model';
import { increaseClaimBenefitNames } from '../../../models/dictionaries/increaseClaimBenefit.model';
import { ownerNames } from '../../../models/dictionaries/owner.model';
import { priorityNames } from '../../../models/dictionaries/priority.model';
import { rolloverNames } from '../../../models/dictionaries/rollover.model';
import { structureNames } from '../../../models/dictionaries/structure.model';
import { waitingPeriodNames } from '../../../models/dictionaries/waitingPeriod.model';
import { INC } from '../../../models/needs/inc.model';
import { currencyFormat } from '../../../utils/common';
import { InclusionsBreakdownTable } from './InclusionsBreakdownTable';

export const INCBreakdown: FunctionComponent<{ inc: INC }> = ({ inc }) => {
  return (
    <div style={{ marginBottom: 30 }}>
      <InclusionsBreakdownTable
        title="Income Protection"
        data={[
          ['Monthly Cover', currencyFormat(inc.monthlyBenefit)],
          ['Super Contribution', currencyFormat(inc.superContributionOption)],
          ['Ownership', ownerNames(inc.owner)],
          ['Pay by Rollover', rolloverNames(inc.rollover)],
          ['Structure', structureNames(inc.structure)],
          ['Agreed Value', agreedValueNames(inc.agreedValue)],
          ['Accident Benefit', accidentBenefitNames(inc.accidentBenefit)],
          [
            'Increase Claim Benefit',
            increaseClaimBenefitNames(inc.increaseClaimBenefit),
          ],
          ['Waiting Period', waitingPeriodNames(inc.waitingPeriod)],
          ['Benefit Period', benefitPeriodNames(inc.benefitPeriod)],
          ['Priority', priorityNames(inc.priority)],
        ]}
      />
    </div>
  );
};
