import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import CoverAmountInput from '../../common/CoverAmountInput';
import FormRow from '../../common/FormRow';
import InfoTooltip from '../../common/InfoTooltip';
import Label from '../../common/Label';
import { useConfigContext } from '../../configs/config.context';
import { benefitPeriodNames } from '../../models/dictionaries/benefitPeriod.model';
import { structureNames } from '../../models/dictionaries/structure.model';
import { waitingPeriodNames } from '../../models/dictionaries/waitingPeriod.model';
import { OptionSelectorWithNamer } from './OptionSelector';

const TRSForm: FunctionComponent = () => {
  const config = useConfigContext().quotes.BUS;

  return (
    <>
      <div style={{ marginBottom: 10, marginTop: 25 }}>
        <b>Business Expenses</b>
      </div>

      <FormRow
        visibility={config.monthlyBenefit.visibility}
        label={<Label>Monthly Benefit</Label>}
        input={
          <Controller
            name="BUS.monthlyBenefit"
            rules={{ required: true, min: 1 }}
            render={({ field: { onChange, value } }) => (
              <CoverAmountInput
                defaultValue={value}
                onValueChange={onChange}
                maxLength={5}
              />
            )}
          />
        }
        info={
          <InfoTooltip title="Monthly Benefit">
            Your preferred amount of cover. Note that some products may apply
            fixed increments of cover, and in the event your preferred amount
            does not meet one of those increments, we will use the next closest,
            higher increment to ensure your preferred amount is always insured.
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.structure.visibility}
        label={<Label>Structure</Label>}
        input={
          <OptionSelectorWithNamer
            name="BUS.structure"
            options={['S', 'L']}
            namer={structureNames}
          />
        }
        info={
          <InfoTooltip title="Structure">
            {config.structure.choices.includes('S') && (
              <>
                Stepped – Premiums will generally increase every year in line
                with the age of the insured. Premiums may vary due to sum
                insured changes including Consumer Price Index (CPI) movements
                or changes to the underlying premium rates set by the insurer.
                <br /> <br />
              </>
            )}
            {config.structure.choices.includes('L') && (
              <>
                Level – Premiums will not increase every year due to age until
                age 65. Premiums may vary due to sum insured changes including
                Consumer Price Index (CPI) movements or changes to the
                underlying premium rates set by the insurer.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.waitingPeriod.visibility}
        label={<Label>Waiting Period</Label>}
        input={
          <OptionSelectorWithNamer
            name="BUS.waitingPeriod"
            options={[14, 30, 60, 90]}
            namer={waitingPeriodNames}
          />
        }
        info={
          <InfoTooltip title="Waiting Period">
            {config.waitingPeriod.choices.includes(14) && (
              <>
                14 days - Only products with a waiting period of 14 days or 2
                weeks after disability can be used. <br />
              </>
            )}
            {config.waitingPeriod.choices.includes(30) && (
              <>
                30 days - Only products with a waiting period of 30 days or 4
                weeks after disability can be used. <br />
              </>
            )}
            {config.waitingPeriod.choices.includes(60) && (
              <>
                60 days - Only products with a waiting period of 60 days or 8
                weeks after disability can be used. <br />
              </>
            )}
            {config.waitingPeriod.choices.includes(90) && (
              <>
                90 days - Only products with a waiting period of 90 days or 12
                weeks after disability can be used.
              </>
            )}
          </InfoTooltip>
        }
      />

      <FormRow
        visibility={config.benefitPeriod.visibility}
        label={<Label>Benefit Period</Label>}
        input={
          <OptionSelectorWithNamer
            name="BUS.benefitPeriod"
            options={[1]}
            namer={benefitPeriodNames}
          />
        }
        info={
          <InfoTooltip title="Benefit Period">
            {config.benefitPeriod.choices.includes(1) && (
              <>
                1 year - Receive your monthly benefit for up to 1 year while you
                are unable to work.
              </>
            )}
          </InfoTooltip>
        }
      />
    </>
  );
};

export default TRSForm;
