import { AxiosRequestConfig } from 'axios';
import { ClientInput } from 'src/models/client-input.model';
import { QuoteRequest } from 'src/models/quote-request.model';
import { Frequency } from 'src/models/quote-response.model';
import { getReportRequest } from '../types/report.types';

export function reportRequest(
  quoteRequest: QuoteRequest,
  portfolioCodes: string[],
  client: ClientInput,
  code: string | null,
  freq: Frequency,
  subReportType: string
): AxiosRequestConfig {
  const portfolioCodesString = portfolioCodes.join(',');

  return {
    method: 'POST',
    url: '/omnilife/report/' + portfolioCodesString,
    data: getReportRequest(quoteRequest, client, freq, subReportType),
    params: {
      code: code || 'all',
      firstName: client.firstName,
      lastName: client.lastName,
      clientEmail: client.email,
    },
    responseType: 'blob',
  };
}
