import { FC } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

export const CustomRadio: FC<{
  value: any;
  onChange: (value: any) => void;
  options: { label: string; value: any }[] | string[];
  error?: boolean;
}> = ({ value, onChange, options, error }) => (
  <RadioGroup
    value={value || ''}
    onChange={(e) => onChange(e.target.value)}
    row
    sx={{ width: 200 }}
  >
    {options.map((option) => {
      const { label, value } =
        typeof option === 'string' ? { label: option, value: option } : option;

      return (
        <FormControlLabel
          key={value}
          value={value}
          label={label}
          control={
            <Radio color="primary" sx={{ color: error ? 'red' : undefined }} />
          }
        />
      );
    })}
  </RadioGroup>
);
