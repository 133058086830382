import { Typography } from '@mui/material';
import { Fragment, FunctionComponent } from 'react';
import { Product, ResolvedNeed } from '../../../models/quote-response.model';
import { BUSBreakdown } from './BUSBreakdown';
import { CHTBreakdown } from './CHTBreakdown';
import { INCBreakdown } from './INCBreakdown';
import { TPSBreakdown } from './TPSBreakdown';
import { TRMBreakdown } from './TRMBreakdown';
import { TRSBreakdown } from './TRSBreakdown';

const InclusionsBreakdown: FunctionComponent<{ products: Product[] }> = ({
  products,
}) => {
  return (
    <>
      {products.map((product) => (
        <Fragment key={product.code}>
          <Typography variant="h6" fontWeight="bold" mb={2}>
            {product.name}
          </Typography>

          {product.resolvedNeeds.map((need) => (
            <NeedBreakdown key={JSON.stringify(need)} need={need} />
          ))}
        </Fragment>
      ))}
    </>
  );
};
export default InclusionsBreakdown;

const NeedBreakdown: FunctionComponent<{ need: ResolvedNeed }> = ({ need }) => {
  if (need.TRM) return <TRMBreakdown trm={need.TRM} />;
  if (need.TPS) return <TPSBreakdown tpd={need.TPS} />;
  if (need.TRS) return <TRSBreakdown trs={need.TRS} />;
  if (need.INC) return <INCBreakdown inc={need.INC} />;
  if (need.BUS) return <BUSBreakdown bus={need.BUS} />;
  if (need.CHT) return <CHTBreakdown cht={need.CHT} />;

  return <></>;
};
