import { Button } from '@mui/material';
import produce from 'immer';
import { FunctionComponent, useState } from 'react';
import { CustomCard } from 'src/common';
import { Application, createPage, Tag } from '../types/application.type';
import { PageEdit } from './PageEdit';

export const PageList: FunctionComponent<{
  application: Application;
  setApplication: (application: Application) => void;
}> = ({ application, setApplication }) => {
  const [editPage, setEditPage] = useState<number | undefined>(undefined);

  const addPage = () => {
    setApplication(
      produce(application, (draft) => {
        draft.pages.push(createPage());
      })
    );
  };

  const removePage = (pageIndex: number) => () => {
    setApplication(
      produce(application, (draft) => {
        draft.pages.splice(pageIndex, 1);
      })
    );
  };

  const setPageName =
    (pageIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setApplication(
        produce(application, (draft) => {
          draft.pages[pageIndex].name = e.target.value;
        })
      );
    };

  const setTags = (pageIndex: number) => (tags: Tag[]) => {
    setApplication(
      produce(application, (draft) => {
        draft.pages[pageIndex].tags = tags;
      })
    );
  };

  return (
    <>
      {editPage === undefined ? (
        <CustomCard title="Application Pages">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {application.pages.map((p, index) => (
                <tr key={p.id} style={{ paddingBottom: 1 }}>
                  <td>
                    <input
                      style={{ width: 300, margin: 10 }}
                      value={p.name}
                      onChange={setPageName(index)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="contained"
                      onClick={() => setEditPage(index)}
                      sx={{ mr: 2 }}
                      size="small"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={removePage(index)}
                      size="small"
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button
            variant="contained"
            onClick={addPage}
            sx={{ mt: 2 }}
            size="small"
          >
            Add
          </Button>
        </CustomCard>
      ) : (
        <PageEdit
          page={application.pages[editPage].name}
          exit={() => setEditPage(undefined)}
          tags={application.pages[editPage].tags}
          setTags={setTags(editPage)}
        />
      )}
    </>
  );
};
