import { FunctionComponent } from 'react';
import { benefitPeriodNames } from '../../../models/dictionaries/benefitPeriod.model';
import { structureNames } from '../../../models/dictionaries/structure.model';
import { waitingPeriodNames } from '../../../models/dictionaries/waitingPeriod.model';
import { BUS } from '../../../models/needs/bus.model';
import { currencyFormat } from '../../../utils/common';
import { InclusionsBreakdownTable } from './InclusionsBreakdownTable';

export const BUSBreakdown: FunctionComponent<{ bus: BUS }> = ({ bus }) => {
  return (
    <div style={{ marginBottom: 30 }}>
      <InclusionsBreakdownTable
        title="Business Expenses"
        data={[
          ['Monthly Benefit', currencyFormat(bus.monthlyBenefit)],
          ['Structure', structureNames(bus.structure)],
          ['Waiting Period', waitingPeriodNames(bus.waitingPeriod)],
          ['Benefit Period', benefitPeriodNames(bus.benefitPeriod)],
        ]}
      />
    </div>
  );
};
