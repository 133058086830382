import { Box } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { CustomCard } from 'src/common';
import FormRow from '../../common/FormRow';

export const YourCoversConfigForm: FunctionComponent = () => {
  const { trigger, register, watch } = useFormContext();
  const covers = ['TRM', 'TPD', 'TRS', 'INC', 'BUS', 'NES', 'CHT'];
  const complexModeEnabled = watch('covers.complexMode');

  useEffect(() => {
    trigger();
  }, [complexModeEnabled, trigger]);

  return (
    <>
      <CustomCard title="Complex Mode">
        <FormRow
          label={<label>Enable Complex Mode: </label>}
          input={
            <Box mt={1.2}>
              <input type="checkbox" {...register('covers.complexMode')} />
            </Box>
          }
        />
      </CustomCard>

      <CustomCard title="Covers Visibility">
        {covers.map((c) => (
          <FormRow
            key={c}
            label={<label>{c}: </label>}
            input={
              <Box mt={1}>
                <select {...register('covers.' + c)}>
                  <option value="visible">Visible</option>
                  <option value="hidden">Hidden</option>
                </select>
              </Box>
            }
          />
        ))}
      </CustomCard>

      <CustomCard title="General Advice Disclaimer">
        <FormRow
          label={<label>General Advice Disclaimer:</label>}
          input={
            <div style={{ marginBottom: 20 }}>
              <Controller
                name="covers.generalAdviceDisclaimer"
                render={({ field: { onChange, value } }) => (
                  <ReactQuill
                    onChange={onChange}
                    value={value || ''}
                    style={{ width: 500, height: 200 }}
                    theme="snow"
                  />
                )}
              />
            </div>
          }
        />
      </CustomCard>
    </>
  );
};
